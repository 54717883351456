@import "../base/variable";
@import "../base/mixin";
@import "../base/keyframes";

/* ==========================================================
 html5 elements
========================================================== */
article, aside, figure, figcaption, footer, header, nav, section, main {
  display: block;
  overflow-wrap: break-word;
}

/* ==========================================================
 reseting
========================================================== */
body, div, pre, p, blockquote, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, form, fieldset, th, td, button, figure, figcaption {
  margin: 0;
  padding: 0;
}

input, textarea,
select {
  margin: 0;
  font-size: 16px;
}


button {
  padding: 0;
  border: none;
  overflow: visible;
  background: none;
  vertical-align: top;
  font: inherit;
  color: inherit;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
}

label {
  cursor: pointer;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%;
}

fieldset, img {
  border: 0;
}

img {
  height: auto;
  vertical-align: top;
}

address, caption, code, em, th {
  font-style: normal;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6, strong, em {
  font-size: 100%;
  font-weight: inherit;
}
h1, h2, h3, h4, h5, h6 {
  line-height: 1.5;
}

a, input { /* outline: none; -webkit-tap-highlight-color:rgba(0,0,0,0);*/}

// 電話番号
// [href^="tel"] {
//   @include media(tab) {
//     pointer-events: none;
//     cursor: auto;
//   }
// }

sup, sub {
  font-size: 63%;
}

sup {
  font-size: 75.5%;
  vertical-align: top;
}

sub {
  font-size: 75.5%;
  vertical-align: bottom;
}

hr {
  border: none;
  border-bottom: solid 1px $c_border;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  // -webkit-tap-highlight-color:rgba(0,0,0,0);
}

html {
  height: 100%;
  font-size: 10px;
}

body {
  min-height: 100%;
  font-size: $f_size_pc;
  font-family: $f_base;
  line-height: 1.75;
  color: $c_text;
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
  position: relative;
  &:lang(en) {
    font-family: $f_en;
  }
  @include media(sp) {
    font-size: $f_size_sp;
  }

}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s ease 0s;
  &[href^="tel"] {
    color: inherit;
  }
  @include media(tab-min) {
    img {
      transition: 0.3s ease 0s;
    }
    &[href]:hover {
      opacity: 0.7;
      img {
        opacity: 0.7;
      }
    }
  }
}

img {
  max-width: 100%;
  vertical-align: top;
}

i {
  font-style: normal;
}

.only-pc {
  @include media(sp) {
    display: none !important;
  }
}
.only-sp {
  @include media(tab-min) {
    display: none !important;
  }
}
.only-sp-land {
  display: none !important;
  @include media(360px) {
    display: block !important;
  }
}
.only-ss {
  @include media(ss-min) {
    display: none !important;
  }
}

.is-required {
  &::after {
    content: "\00203b";
    margin: 0 0.5em;
    color: $c_error;
  }
}
.cf {
  @include cf;
}

input {
  &[type="text"],
  &[type="password"],
  &[type="email"],
  &[type="tel"],
  &[type="search"],
  &[type="date"] {
    height: 32px;
    padding: 0 10px;
    border: none;
    border-radius: 4px;
    box-shadow: none;
    -webkit-appearance: none;
    &[size="60"] {
      width: 100%;
    }
  }
}
textarea {
  width: 100%;
  min-height: 240px;
  padding: 7px 10px;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  -webkit-appearance: none;
}

/*▼▼common-style▼▼*/

////フォント

.f-maru {font-family: 'Zen Maru Gothic', sans-serif;}


////アイコン style

.arrow-cirlue {
  width: 30px;
  background: #ffffff;
  height: 30px;
  display: flex;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  path {
  }
}

.arrow-cirlue.green {
  path {
    fill:$key_color;
  }
}
.arrow-cirlue.green:hover {
  background: $key_color_light;
  path {
    fill:#ffffff;
  }
}

.arrow-cirlue.green2 {
  border: 1px solid $key_color_light2;
  path {
    fill:$key_color;
  }
}
.arrow-cirlue.green2:hover {
  background: $key_color_light2;
  path {
  }
}



.icon-green {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $key_color;
  border-radius: 100px;
}

////hタグ style
.type-a {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  margin: 0 auto;
  position: relative;
  transform: translateY(-20px);
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  width: 400px;
  @include media (sp) {
    width: 300px;
    font-size: 26px;
  }
  &::before {
    content:"";
    width: 400px;
    height: 180px;
    background: $bg_color;
    border-radius: 200px / 90px;
    position: absolute;
    top:-45px;
    left: 50%;
    transform: translateX(-50%);
    @include media(sp) {
      width: 300px;
      top:-30px;
    }
  }

  span.small-font {
    font-size: 0.6em;
    position: relative;
    text-align: center;
    display: block;
    line-height: 0.8;
  }    

  span.border {
    /*border-bottom: 2px solid $key_color;*/
    position: relative;
    padding-bottom: 10px;
    &::before {
      content: "";
      background-color: $key-color;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 4px;
      width: 100%;
    }
    &::after {
      content:"";
      position: absolute;
      border: 8px solid transparent;
      border-top: 12px solid $key_color;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);        
    }
  }
}

.type-b {
  text-align: center;
  color: $key_color;
  font-size: 24px;
  font-weight: 500;
  @include media(tab) {
    font-size: 21px;
  }
  span {
    padding: 0 0.5em;
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 1em;
      height: 1em;
      border-right: 1px solid $key_color;
      transform: rotate(-45deg);
      position: absolute;
      left: -1.5em;
      top: 50%
    }
    &::after {
      content: "";
      display: block;
      width: 1em;
      height: 1em;
      border-left: 1px solid $key_color;
      transform: rotate(45deg);
      position: absolute;
      right: -1.5em;
      top: 50%;
    }
  }
}

.type-c {
  color: $key_color;
  border-top: 1px solid $key_color;
  text-align: center;
  font-size: 21px;
  font-weight: bold;
  @include media(sp) {
    font-size: 18px;
  }
  span {
    background: #ffffff;
    border: 1px solid $key_color;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 0.2em 1em;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    display: inline-block;
    position: relative;
    &::before {
      content: "";
      display: block;
      background: url(../img/icon/h-parts-l.svg) center center no-repeat; 
      width: 40px;
      height: 60px;
      position: absolute;
      left: -1em;
      top: 50%;
      margin-top: -30px;
      @include media(sp) {
        background-size: 35px;
      }      
    }
    &::after {
      content: "";
      display: block;
      background: url(../img/icon/h-parts-r.svg) center center no-repeat; 
      width: 40px;
      height: 60px;
      position: absolute;
      right: -1em;
      top: 50%;
      margin-top: -30px;
      @include media(sp) {
        background-size: 35px;
      }      
    }
  }
}

.underpage-top {
  background-color: $bg_color;
  text-align: center;
  font-size: 32px;
  padding: 60px 0;
  font-weight: 700;
  line-height: 1.2;
  @include media(w-1000){
    padding: 30px 2%;
  }
  @include media(sp) {
    font-size: 26px;
  }
  span {
    font-size: 21px;
    color: $key_color;
  }
}


////ボタン　style
.btn-style {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  position: relative;
  &::before {
    content: "";
    background: #ffffff;
    width: 100%;
    height: 100%;
    position: absolute;
    border: 1px solid $line_gray;
    border-radius: 100px;
    top: 0;
    left: 0;
    z-index: 10;
  }
  &::after {
    content: "";
    background: #ffffff;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 100px;
    top: 5px;
    left: 5px;
    z-index: 5;
    border: 1px solid $line_gray;
  }
  .text {
    position: relative;
    z-index: 20;
  }

  .arrow {
    display: flex;
    align-items: center;
    margin-left: 10px;
    position: relative;
    z-index: 20;
  }
}
.btn-style:hover {
  background: $key_color;
  opacity: 0.7;
  &::before,
  &::after {
    content: none;
  }
  .text {
    color: #ffffff;
  }
  .arrow {
    path {
      fill: #ffffff;
    }
  }
}



////////////////////////////支援メニュー

h3.other-menu-h {
  text-align: center;
  position: relative;
  font-size: 21px;
  font-weight: 500;
  margin: 50px 0 20px;
}

.other-menu-area {
  .inner {
    width: 100%;
    max-width: 1200px;
    background: $bg_color;
    border-radius: 50px;
    margin: 0 auto 100px;
    padding: 50px 0;
    @include media(tab) {
      border-radius: 0;
    }

  }
  
}

.contents-shien {
  transition: 0.3s;  
  border-radius: 40px;

  width: 100%;
  max-width: 960px;
  background: #ffffff;
  margin: 0 auto 20px;
  padding: 15px;
  border: 1px solid #ffffff;
  height: inherit;
  @include media(w-1000) {
    width: 96%;
    padding: 15px 8px;
  }
  h4 {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 21px;
    position: relative;
    cursor: pointer;
    @include media (sp){
      display: grid;
      grid-template-columns: 45px auto;
      grid-template-rows: 1fr;
    }
    &::after {
      content: "";
      display: block;
      width: 30px;
      height: 30px;
      background: url(../img/icon/icon-plus.png) center center no-repeat;
      background-color: $key_color_light;
      border-radius: 50px;
      margin-left: 20px;
      position: absolute;
      right: 0;
    }
    &.arrow-style {
      &::after {
        background: url(../img/icon/icon-w-arrow-r.png) center center no-repeat;
        background-color: $key_color_light;
      }
    }
    .icon-green {
      margin-right: 20px;
      @include media(sp) {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        img {
          width: 20px;
        }
      }
    }
    .text {
      @include media(sp) {
        padding-right: 2em;
        line-height: 1.2;
        font-size: 18px;
      }
    }
  }
  >ul {
    max-height: 0;
    transition: 1s;  
    overflow: hidden;
    width: 100%;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    li {
      margin-bottom: 20px;
      a {
        background: $bg_color;
        border-radius: 100px;
        position: relative;
        padding: 0.5em 2.5em 0.5em 2em;
        width: 100%;
        display: block;
        &::before {
          content: "＊";
          color: $key_color;
          font-weight: bold;
          margin-right: 10px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0.8em;
        }
        &::after {
          content: "＞";
          font-weight: bold;
          position: absolute;
          right: 1em;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      a:hover {
        background-color: $key_color;
        color: #ffffff;
        &::before,
        &::after {
          color: #ffffff;
        }
      }
    }
  }
}
.contents-shien.on {
  border: 1px solid $key_color;
  border-radius: 40px;
  h4 {
    &::after{
      background: url(../img/icon/icon-minus.png) center center no-repeat;
      background-color: $key_color;
    }
  }
  > ul {
    max-height: 600px;
    display: block;
    margin: 20px auto;

  }
}


.other-menu-area {
  .cats-management {
    .menu-management {
      display: none;
    }
  }

  .cats-seminar,
  .seminar-1,
  .seminar-2,
  .seminar-3 {
    .menu-seminar {
      display: none;
    }
  }

  .cats-collaboration {
    .menu-collaboration {
      display: none;
    }
  }

  .cats-subsidy {
    .menu-subsidy {
      display: none;
    }
  }

  .cats-loan {
    .menu-loan {
      display: none;
    }
  }

  .cats-pbringing {
    .menu-upbringing {
      display: none;
    }
  }

  .cats-welfare {
    .menu-welfare {
      display: none;
    }
  }

  .cats-training {
    .menu-training {
      display: none;
    }
  }

  .cats-founding {
    .menu-founding {
      display: none;
    }
  }

  .cats-example {
    .menu-management,
    .menu-subsidy {
      display: none;
    }
  }

}

////gotop
.btn-top {
  position: fixed;
  right: 20px;
  bottom: 100px;
  z-index: 5000;
  @include media(sp){
    width:40px;
    right:10px;
    bottom:50px;
  }
}


/*▲▲common-style▲▲*/

/*▼▼page-list▼▼*/
body.list-stage1 {
  .s1 {display:none;}
}
body.list-stage2 {
  .s2 {display:none;}
}

body.list-stage3 {
  .s3 {display:none;}
}

body.list-stage4 {
  .s4 {display:none;}
}

/*▲▲common-style▲▲*/

/*▼▼news-style▼▼*/

.news-area {
  .inner {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 50px 4%;
    position: relative;
    @include media(tab) {
      padding: 50px 2%;
    }
    @include media(sp) {
      border-radius: 0;
    }
    .news-nav {
      width: 35%;
      max-width: 180px;
      margin-right: 20px;
      padding-top: 10px;
      @include media(tab) {
      }

      @include media(sp) {
        width: 100%;
        margin: 0 0 50px;
        padding: 0;

        ul {
          width: 100%;
          display: flex;
          flex-wrap: wrap;

        }
      }

      h2 {
        font-size: 32px;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 70px;
        @include media(sp) {
          margin-bottom: 20px;
        }
        span {
          font-size: 16px;
        }
      }

      .nav-switch {
        font-weight: bold;
        li {
          margin-bottom: 20px;
          @include media(sp) {
            width: 32%;
            margin-right: 2%;
          }
          &:nth-of-type(1) {
            @include media(sp){

            }
          }
          &:nth-of-type(2) {
            @include media(sp){

            }

          }
          &:nth-of-type(3) {
            @include media(sp){
              margin-right: 0;
            }

          }
          &:nth-of-type(4) {
            @include media(sp){
            }

          }
          &:last-child {
            margin-top: 80px;
            margin-bottom: 0;
            @include media(sp) {
              margin-top: 0;
              position: static;
              width: 180px;

            }
          }
          a {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 10px;
            background-color: $c_text_gray;
            color: #ffffff;
            border-radius: 300px;
            &::after {
              content: ">";
              font-weight: bold;
              font-size: 8px;
              width: 12px;
              height: 12px;
              background: #ffffff;
              color: #ababab;
              margin-right: 10px;
              border-radius: 300px;
              display: flex;
              justify-content: center;
              align-items: center;
            }            
            @include media(sp) {
              font-size: 18px;
              display: flex;
              align-items: center;
              margin: 0;
            }
            &.switch-delete {
              background: $c_second_light;            
            }
  
            &.switch-service {
              background: #FCBD5F;
            
            }
            &.switch-event {
              background: #F8764B;
            
            }

            &.switch-info {
              background: #F28BB0;
            }
            &.switch-old {
              &::after {
                transform: rotate(90deg);
              }
            }
          }
        }
        li.ver2 {
          &:last-child {
            margin-top: 0;
            margin-bottom: 0;
            @include media(sp) {
              margin-top: 0;
              position: static;
              width: 180px;
            }
          }
        }
      }
    }
    .news-contents {
      width: 100%;
      max-width: 800px;
      .select-news {
        li {
          padding: 10px 0;
          border-bottom: 1px solid $line_gray;
          a {
            display: flex;
            position: relative;
            padding: 0 40px 0 170px ;
            @include media(sp) {
              padding: 2em 40px 0 0; 
            }

            .date {
              display: flex;
              position:  absolute;
              top: 0;
              left: 0;
              font-size: 0.8em;
              @include media(sp) {
                font-size: 1em;
              }
              &::after {
                display: block;
                padding: 0.05em 1em;
                text-align: center;
                width: 6em;
                color: #ffffff;
                border-radius: 30px;
                margin-left: 10px;
              }
            }
            .arrow-cirlue {
              position: absolute;
              top: 0;
              right: 0;
              @include media(sp) {
                width: 20px;
                height: 20px;
                top: 50%;
                margin-top: -10px;
              }
            }
          }
        }

        li.news-service {
          a {
            .date {
              &::after {
                content: "サービス";
                background: #FCBD5F;
              }
            }
          }
        }

        li.news-event {
          a {
            .date {
              &::after {
                content: "イベント";
                background: #F8764B;
              }
            }
          }
        }

        li.news-info {
          a {
            .date {
              &::after {
                content: "お知らせ";
                background: #F28BB0;
              }
            }
          }
        }

      }
    }

    @include media(sp) {
      flex-direction: column;
      padding: 50px 15px;
      .news-contents {
        width: 100%;
      }
      
    }

  }
}

//ニュースソート
#show-area.on-service {
  .news-event,.news-info { display: none;}
}

#show-area.on-event {
  .news-service,.news-info { display: none;}
}

#show-area.on-info {
  .news-event,.news-service { display: none;}
}

/*▲▲news-style▲▲*/


/*▼▼qa_area-style▼▼*/

.qa_area {
  &_box {
    margin-bottom: 20px;
    border-bottom: 1px dashed $line_gray;
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
  dt {
    font-weight: bold;
    position: relative;
    padding-left: 30px;
    padding-right: 35px;
    font-size: 21px;
    margin-bottom: 10px;
    @include media(sp) {
      font-size: 18px;
    }
    &::before {
      content: "Q";
      display: block;
      color: $key-color;
      font-family: "Zen Maru Gothic",sans-serif;
      position: absolute;
      top:-14px;
      left:0;
      font-size: 32px;
    }

    &::after {
      content: "";
      display: block;
      width: 30px;
      height: 30px;
      background: url(../img/icon/icon-plus.png) center center no-repeat;
      background-color: $key_color_light;
      border-radius: 50px;
      margin-left: 20px;
      position: absolute;
      right: 0;
      top:0;
    }

  }
  dd {
    position: relative;
    padding-left: 30px;
    padding-right: 35px;
    max-height: 0;
    overflow: hidden;
    transition: 1s;
    @include media(sp) {
      padding-left: 40px;
    }
    &::before {
      content: "A";
      display: block;
      color: $c_accent;
      font-family: "Zen Maru Gothic",sans-serif;
      font-weight: bold;
      font-size: 32px;
      position: absolute;
      top:-14px;
      left: 0;
      
    }
    p {
      margin-bottom: 10px;
    }

  }
  .qa_area_box.on {
    dt {
      &::after{
        background: url(../img/icon/icon-minus.png) center center no-repeat;
        background-color: $key_color;
      }  
    }
    dd {
      max-height: 1000px;
    }
  }
}

/*▲▲news-style▲▲*/

/*404page*/

.inner-404 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 100px 0;
  h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  p {
    font-weight: bold;
    margin-bottom: 20px;
  }
  a {
    width: 98%;
    text-align: center;
    padding: 0.5em 1em;
    border-radius: 10px;
    display: block;
    background: $key-color;
    max-width: 300px;
    font-size: 1.2em;
    font-weight: bold;
    color: #ffffff;
  }


}



