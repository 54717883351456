.l-footer {
  background: $c_gray;
  padding: 50px 0;
  @include media(tab) {
    padding-bottom: 200px;
  }  
  @include media(sp) {
    font-size: 1.1em;
    padding-bottom: 100px;
  }
  .inner {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    @include media(tab) {
      padding: 0 2%;
    }  
    .contents-area {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      @include media(sp) {
        flex-direction: column;
      }
      .box1 {
        width: 100%;
        max-width: 420px;
        @include media(w-1000) {
          max-width: 270px;
          margin-right: 20px;
        }
        @include media(sp) {
          max-width: inherit;
          margin-bottom: 30px;
        }
        .footer-logo {
          margin-bottom: 30px;
          a {
            margin-bottom: 10px;
            display: block;
          }
          p { 
            font-size: 0.8em;
            font-weight: bold;
          }
        }
        .footer-sns {
          display: flex;
          align-items: center;
          p {
            font-weight: bold;
            margin-right: 15px;
          }
          ul {
            display: flex;
            li {
              margin-right: 15px;
              @include media(tab) {
                margin-right: 25px;
              }
              a {
                display: block;
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }

      .box2 {
        width: 100%;
        max-width: 550px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-right: 100px;
        @include media(w-1000) {
          margin-right: 0;
        }
        @include media(tab) {
          margin-right: 0;
        }
        @include media(sp) {
          flex-direction: column;
          max-width: inherit;
        }
        .footer-nav1 {
          width: 48%;
          @include media(sp) {
            font-size: 1.1em;
            width: 100%;
            margin-bottom: 20px;
            border-bottom: 1px solid $line_gray;
          }
          ul {
            border-right: 1px solid $line_gray;
            @include media(w-1000) {
              padding-right: 1em;
            }
            @include media(sp) {
              border-right: none;
            }
            li {
              font-weight: bold;
              margin-bottom: 15px;
              a {
                &::after {
                  content: ">";
                  margin-left: 10px;
                }
                span {
                  border-bottom: 1px solid ;
                }
                @include media(sp) {
                  display: block;
                }
              }
            }
          }
        }
        .footer-nav2 {
          width: 48%;
          @include media(sp) {
            width: 100%;
            border-bottom: 1px solid $line_gray;
          }
          p {
            margin-bottom: 15px;
            font-weight: bold;
            @include media(w-1000) {
              margin-bottom: 10px;
            }
            @include media(sp) {
              font-size: 1.1em;
            }
          }
          ul {
            padding-left: 0.5em;
            li {
              margin-bottom: 20px;
              a {
                text-indent: -1em;
                padding-left: 1em;
                display: block;
                span {
                  border-bottom: 1px solid ;
                }
                &::after {
                  content: ">";
                  margin-left: 10px;
                }
                @include media(sp) {
                  display: block;
                }
              }
            }
          }
        }
        .footer-nav3 {
          width: 100%;
          margin-top: 40px;
          @include media(sp) {
            margin-top: 20px;
          }
          ul {
            display: flex;
            @include media(sp) {
              flex-direction: column;
            }
            li {
              margin-right: 2em;
              @include media(sp) {
                margin-bottom: 20px;
              }
              a {
                span {
                  border-bottom: 1px solid ;
                }
                &::after {
                  content: ">";
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }
    .copy {
      width: 100%;
      background: #ffffff;
      border-radius: 100px;
      text-align: center;
      padding: 0.2em 0;
      span { margin-left: 1em;
        @include media(sp) {
          margin-left: 0;
        }
      }
      br { display: none;
        @include media(sp) {
          display: block;
        }
      }
    }
  }
  @include media(sp) {
  }
}