@import "../base/variable";

@keyframes headerSlideDown {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

/////////////

@keyframes slideDown {
  0% {

  }
  100% {


    
  }

}



