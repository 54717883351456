@charset "UTF-8";
@font-face {
  font-family: YuGothicM;
  src: local(Yu Gothic Medium), local(Yu Gothic);
  font-weight: 500;
}
@font-face {
  font-family: YuGothicM;
  src: local(Yu Gothic Medium), local(Yu Gothic);
  font-weight: 500;
}
@font-face {
  font-family: YuGothicM;
  src: local(Yu Gothic Medium), local(Yu Gothic);
  font-weight: 500;
}
@keyframes headerSlideDown {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideDown {}
/* ==========================================================
 html5 elements
========================================================== */
article, aside, figure, figcaption, footer, header, nav, section, main {
  display: block;
  overflow-wrap: break-word;
}

/* ==========================================================
 reseting
========================================================== */
body, div, pre, p, blockquote, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, form, fieldset, th, td, button, figure, figcaption {
  margin: 0;
  padding: 0;
}

input, textarea,
select {
  margin: 0;
  font-size: 16px;
}

button {
  padding: 0;
  border: none;
  overflow: visible;
  background: none;
  vertical-align: top;
  font: inherit;
  color: inherit;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
}

label {
  cursor: pointer;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%;
}

fieldset, img {
  border: 0;
}

img {
  height: auto;
  vertical-align: top;
}

address, caption, code, em, th {
  font-style: normal;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6, strong, em {
  font-size: 100%;
  font-weight: inherit;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.5;
}

a, input { /* outline: none; -webkit-tap-highlight-color:rgba(0,0,0,0);*/ }

sup, sub {
  font-size: 63%;
}

sup {
  font-size: 75.5%;
  vertical-align: top;
}

sub {
  font-size: 75.5%;
  vertical-align: bottom;
}

hr {
  border: none;
  border-bottom: solid 1px #D9D9D9;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 10px;
}

body {
  min-height: 100%;
  font-size: 1.6rem;
  font-family: "Noto Sans JP", YuGothicM, YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", "sans-serif";
  line-height: 1.75;
  color: #333;
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
  position: relative;
}
body:lang(en) {
  font-family: "Vidaloka", "Noto Serif JP", serif;
}
@media (max-width: 640px) {
  body {
    font-size: 1.4rem;
  }
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s ease 0s;
}
a[href^=tel] {
  color: inherit;
}
@media (min-width: 769px) {
  a img {
    transition: 0.3s ease 0s;
  }
  a[href]:hover {
    opacity: 0.7;
  }
  a[href]:hover img {
    opacity: 0.7;
  }
}

img {
  max-width: 100%;
  vertical-align: top;
}

i {
  font-style: normal;
}

@media (max-width: 640px) {
  .only-pc {
    display: none !important;
  }
}

@media (min-width: 769px) {
  .only-sp {
    display: none !important;
  }
}

.only-sp-land {
  display: none !important;
}
@media (max-width: 360px) {
  .only-sp-land {
    display: block !important;
  }
}

@media (min-width: 321px) {
  .only-ss {
    display: none !important;
  }
}

.is-required::after {
  content: "※";
  margin: 0 0.5em;
  color: #ED5454;
}

.cf::after {
  display: block;
  clear: both;
  content: "";
}

input[type=text], input[type=password], input[type=email], input[type=tel], input[type=search], input[type=date] {
  height: 32px;
  padding: 0 10px;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  -webkit-appearance: none;
}
input[type=text][size="60"], input[type=password][size="60"], input[type=email][size="60"], input[type=tel][size="60"], input[type=search][size="60"], input[type=date][size="60"] {
  width: 100%;
}

textarea {
  width: 100%;
  min-height: 240px;
  padding: 7px 10px;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  -webkit-appearance: none;
}

/*▼▼common-style▼▼*/
.f-maru {
  font-family: "Zen Maru Gothic", sans-serif;
}

.arrow-cirlue {
  width: 30px;
  background: #ffffff;
  height: 30px;
  display: flex;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
}
.arrow-cirlue.green path {
  fill: #00A960;
}

.arrow-cirlue.green:hover {
  background: #8ECE9A;
}
.arrow-cirlue.green:hover path {
  fill: #ffffff;
}

.arrow-cirlue.green2 {
  border: 1px solid #EFF8F0;
}
.arrow-cirlue.green2 path {
  fill: #00A960;
}

.arrow-cirlue.green2:hover {
  background: #EFF8F0;
}
.icon-green {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00A960;
  border-radius: 100px;
}

.type-a {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  margin: 0 auto;
  position: relative;
  transform: translateY(-20px);
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  width: 400px;
}
@media (max-width: 640px) {
  .type-a {
    width: 300px;
    font-size: 26px;
  }
}
.type-a::before {
  content: "";
  width: 400px;
  height: 180px;
  background: #EFF3F5;
  border-radius: 200px/90px;
  position: absolute;
  top: -45px;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 640px) {
  .type-a::before {
    width: 300px;
    top: -30px;
  }
}
.type-a span.small-font {
  font-size: 0.6em;
  position: relative;
  text-align: center;
  display: block;
  line-height: 0.8;
}
.type-a span.border {
  /*border-bottom: 2px solid $key_color;*/
  position: relative;
  padding-bottom: 10px;
}
.type-a span.border::before {
  content: "";
  background-color: #00A960;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 100%;
}
.type-a span.border::after {
  content: "";
  position: absolute;
  border: 8px solid transparent;
  border-top: 12px solid #00A960;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.type-b {
  text-align: center;
  color: #00A960;
  font-size: 24px;
  font-weight: 500;
}
@media (max-width: 768px) {
  .type-b {
    font-size: 21px;
  }
}
.type-b span {
  padding: 0 0.5em;
  position: relative;
}
.type-b span::before {
  content: "";
  display: block;
  width: 1em;
  height: 1em;
  border-right: 1px solid #00A960;
  transform: rotate(-45deg);
  position: absolute;
  left: -1.5em;
  top: 50%;
}
.type-b span::after {
  content: "";
  display: block;
  width: 1em;
  height: 1em;
  border-left: 1px solid #00A960;
  transform: rotate(45deg);
  position: absolute;
  right: -1.5em;
  top: 50%;
}

.type-c {
  color: #00A960;
  border-top: 1px solid #00A960;
  text-align: center;
  font-size: 21px;
  font-weight: bold;
}
@media (max-width: 640px) {
  .type-c {
    font-size: 18px;
  }
}
.type-c span {
  background: #ffffff;
  border: 1px solid #00A960;
  box-sizing: border-box;
  border-radius: 100px;
  padding: 0.2em 1em;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  display: inline-block;
  position: relative;
}
.type-c span::before {
  content: "";
  display: block;
  background: url(../img/icon/h-parts-l.svg) center center no-repeat;
  width: 40px;
  height: 60px;
  position: absolute;
  left: -1em;
  top: 50%;
  margin-top: -30px;
}
@media (max-width: 640px) {
  .type-c span::before {
    background-size: 35px;
  }
}
.type-c span::after {
  content: "";
  display: block;
  background: url(../img/icon/h-parts-r.svg) center center no-repeat;
  width: 40px;
  height: 60px;
  position: absolute;
  right: -1em;
  top: 50%;
  margin-top: -30px;
}
@media (max-width: 640px) {
  .type-c span::after {
    background-size: 35px;
  }
}

.underpage-top {
  background-color: #EFF3F5;
  text-align: center;
  font-size: 32px;
  padding: 60px 0;
  font-weight: 700;
  line-height: 1.2;
}
@media (max-width: 1000px) {
  .underpage-top {
    padding: 30px 2%;
  }
}
@media (max-width: 640px) {
  .underpage-top {
    font-size: 26px;
  }
}
.underpage-top span {
  font-size: 21px;
  color: #00A960;
}

.btn-style {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  position: relative;
}
.btn-style::before {
  content: "";
  background: #ffffff;
  width: 100%;
  height: 100%;
  position: absolute;
  border: 1px solid #d6d6d6;
  border-radius: 100px;
  top: 0;
  left: 0;
  z-index: 10;
}
.btn-style::after {
  content: "";
  background: #ffffff;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 100px;
  top: 5px;
  left: 5px;
  z-index: 5;
  border: 1px solid #d6d6d6;
}
.btn-style .text {
  position: relative;
  z-index: 20;
}
.btn-style .arrow {
  display: flex;
  align-items: center;
  margin-left: 10px;
  position: relative;
  z-index: 20;
}

.btn-style:hover {
  background: #00A960;
  opacity: 0.7;
}
.btn-style:hover::before, .btn-style:hover::after {
  content: none;
}
.btn-style:hover .text {
  color: #ffffff;
}
.btn-style:hover .arrow path {
  fill: #ffffff;
}

h3.other-menu-h {
  text-align: center;
  position: relative;
  font-size: 21px;
  font-weight: 500;
  margin: 50px 0 20px;
}

.other-menu-area .inner {
  width: 100%;
  max-width: 1200px;
  background: #EFF3F5;
  border-radius: 50px;
  margin: 0 auto 100px;
  padding: 50px 0;
}
@media (max-width: 768px) {
  .other-menu-area .inner {
    border-radius: 0;
  }
}

.contents-shien {
  transition: 0.3s;
  border-radius: 40px;
  width: 100%;
  max-width: 960px;
  background: #ffffff;
  margin: 0 auto 20px;
  padding: 15px;
  border: 1px solid #ffffff;
  height: inherit;
}
@media (max-width: 1000px) {
  .contents-shien {
    width: 96%;
    padding: 15px 8px;
  }
}
.contents-shien h4 {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 21px;
  position: relative;
  cursor: pointer;
}
@media (max-width: 640px) {
  .contents-shien h4 {
    display: grid;
    grid-template-columns: 45px auto;
    grid-template-rows: 1fr;
  }
}
.contents-shien h4::after {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  background: url(../img/icon/icon-plus.png) center center no-repeat;
  background-color: #8ECE9A;
  border-radius: 50px;
  margin-left: 20px;
  position: absolute;
  right: 0;
}
.contents-shien h4.arrow-style::after {
  background: url(../img/icon/icon-w-arrow-r.png) center center no-repeat;
  background-color: #8ECE9A;
}
.contents-shien h4 .icon-green {
  margin-right: 20px;
}
@media (max-width: 640px) {
  .contents-shien h4 .icon-green {
    margin-right: 10px;
    width: 30px;
    height: 30px;
  }
  .contents-shien h4 .icon-green img {
    width: 20px;
  }
}
@media (max-width: 640px) {
  .contents-shien h4 .text {
    padding-right: 2em;
    line-height: 1.2;
    font-size: 18px;
  }
}
.contents-shien > ul {
  max-height: 0;
  transition: 1s;
  overflow: hidden;
  width: 100%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}
.contents-shien > ul li {
  margin-bottom: 20px;
}
.contents-shien > ul li a {
  background: #EFF3F5;
  border-radius: 100px;
  position: relative;
  padding: 0.5em 2.5em 0.5em 2em;
  width: 100%;
  display: block;
}
.contents-shien > ul li a::before {
  content: "＊";
  color: #00A960;
  font-weight: bold;
  margin-right: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0.8em;
}
.contents-shien > ul li a::after {
  content: "＞";
  font-weight: bold;
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
}
.contents-shien > ul li a:hover {
  background-color: #00A960;
  color: #ffffff;
}
.contents-shien > ul li a:hover::before, .contents-shien > ul li a:hover::after {
  color: #ffffff;
}

.contents-shien.on {
  border: 1px solid #00A960;
  border-radius: 40px;
}
.contents-shien.on h4::after {
  background: url(../img/icon/icon-minus.png) center center no-repeat;
  background-color: #00A960;
}
.contents-shien.on > ul {
  max-height: 600px;
  display: block;
  margin: 20px auto;
}

.other-menu-area .cats-management .menu-management {
  display: none;
}
.other-menu-area .cats-seminar .menu-seminar,
.other-menu-area .seminar-1 .menu-seminar,
.other-menu-area .seminar-2 .menu-seminar,
.other-menu-area .seminar-3 .menu-seminar {
  display: none;
}
.other-menu-area .cats-collaboration .menu-collaboration {
  display: none;
}
.other-menu-area .cats-subsidy .menu-subsidy {
  display: none;
}
.other-menu-area .cats-loan .menu-loan {
  display: none;
}
.other-menu-area .cats-pbringing .menu-upbringing {
  display: none;
}
.other-menu-area .cats-welfare .menu-welfare {
  display: none;
}
.other-menu-area .cats-training .menu-training {
  display: none;
}
.other-menu-area .cats-founding .menu-founding {
  display: none;
}
.other-menu-area .cats-example .menu-management,
.other-menu-area .cats-example .menu-subsidy {
  display: none;
}

.btn-top {
  position: fixed;
  right: 20px;
  bottom: 100px;
  z-index: 5000;
}
@media (max-width: 640px) {
  .btn-top {
    width: 40px;
    right: 10px;
    bottom: 50px;
  }
}

/*▲▲common-style▲▲*/
/*▼▼page-list▼▼*/
body.list-stage1 .s1 {
  display: none;
}

body.list-stage2 .s2 {
  display: none;
}

body.list-stage3 .s3 {
  display: none;
}

body.list-stage4 .s4 {
  display: none;
}

/*▲▲common-style▲▲*/
/*▼▼news-style▼▼*/
.news-area .inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 50px 4%;
  position: relative;
}
@media (max-width: 768px) {
  .news-area .inner {
    padding: 50px 2%;
  }
}
@media (max-width: 640px) {
  .news-area .inner {
    border-radius: 0;
  }
}
.news-area .inner .news-nav {
  width: 35%;
  max-width: 180px;
  margin-right: 20px;
  padding-top: 10px;
}
@media (max-width: 640px) {
  .news-area .inner .news-nav {
    width: 100%;
    margin: 0 0 50px;
    padding: 0;
  }
  .news-area .inner .news-nav ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}
.news-area .inner .news-nav h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 70px;
}
@media (max-width: 640px) {
  .news-area .inner .news-nav h2 {
    margin-bottom: 20px;
  }
}
.news-area .inner .news-nav h2 span {
  font-size: 16px;
}
.news-area .inner .news-nav .nav-switch {
  font-weight: bold;
}
.news-area .inner .news-nav .nav-switch li {
  margin-bottom: 20px;
}
@media (max-width: 640px) {
  .news-area .inner .news-nav .nav-switch li {
    width: 32%;
    margin-right: 2%;
  }
}
@media (max-width: 640px) {
  .news-area .inner .news-nav .nav-switch li:nth-of-type(3) {
    margin-right: 0;
  }
}
.news-area .inner .news-nav .nav-switch li:last-child {
  margin-top: 80px;
  margin-bottom: 0;
}
@media (max-width: 640px) {
  .news-area .inner .news-nav .nav-switch li:last-child {
    margin-top: 0;
    position: static;
    width: 180px;
  }
}
.news-area .inner .news-nav .nav-switch li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  background-color: #666;
  color: #ffffff;
  border-radius: 300px;
}
.news-area .inner .news-nav .nav-switch li a::after {
  content: ">";
  font-weight: bold;
  font-size: 8px;
  width: 12px;
  height: 12px;
  background: #ffffff;
  color: #ababab;
  margin-right: 10px;
  border-radius: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 640px) {
  .news-area .inner .news-nav .nav-switch li a {
    font-size: 18px;
    display: flex;
    align-items: center;
    margin: 0;
  }
}
.news-area .inner .news-nav .nav-switch li a.switch-delete {
  background: #4D6CBC;
}
.news-area .inner .news-nav .nav-switch li a.switch-service {
  background: #FCBD5F;
}
.news-area .inner .news-nav .nav-switch li a.switch-event {
  background: #F8764B;
}
.news-area .inner .news-nav .nav-switch li a.switch-info {
  background: #F28BB0;
}
.news-area .inner .news-nav .nav-switch li a.switch-old::after {
  transform: rotate(90deg);
}
.news-area .inner .news-nav .nav-switch li.ver2:last-child {
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 640px) {
  .news-area .inner .news-nav .nav-switch li.ver2:last-child {
    margin-top: 0;
    position: static;
    width: 180px;
  }
}
.news-area .inner .news-contents {
  width: 100%;
  max-width: 800px;
}
.news-area .inner .news-contents .select-news li {
  padding: 10px 0;
  border-bottom: 1px solid #d6d6d6;
}
.news-area .inner .news-contents .select-news li a {
  display: flex;
  position: relative;
  padding: 0 40px 0 170px;
}
@media (max-width: 640px) {
  .news-area .inner .news-contents .select-news li a {
    padding: 2em 40px 0 0;
  }
}
.news-area .inner .news-contents .select-news li a .date {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.8em;
}
@media (max-width: 640px) {
  .news-area .inner .news-contents .select-news li a .date {
    font-size: 1em;
  }
}
.news-area .inner .news-contents .select-news li a .date::after {
  display: block;
  padding: 0.05em 1em;
  text-align: center;
  width: 6em;
  color: #ffffff;
  border-radius: 30px;
  margin-left: 10px;
}
.news-area .inner .news-contents .select-news li a .arrow-cirlue {
  position: absolute;
  top: 0;
  right: 0;
}
@media (max-width: 640px) {
  .news-area .inner .news-contents .select-news li a .arrow-cirlue {
    width: 20px;
    height: 20px;
    top: 50%;
    margin-top: -10px;
  }
}
.news-area .inner .news-contents .select-news li.news-service a .date::after {
  content: "サービス";
  background: #FCBD5F;
}
.news-area .inner .news-contents .select-news li.news-event a .date::after {
  content: "イベント";
  background: #F8764B;
}
.news-area .inner .news-contents .select-news li.news-info a .date::after {
  content: "お知らせ";
  background: #F28BB0;
}
@media (max-width: 640px) {
  .news-area .inner {
    flex-direction: column;
    padding: 50px 15px;
  }
  .news-area .inner .news-contents {
    width: 100%;
  }
}

#show-area.on-service .news-event, #show-area.on-service .news-info {
  display: none;
}

#show-area.on-event .news-service, #show-area.on-event .news-info {
  display: none;
}

#show-area.on-info .news-event, #show-area.on-info .news-service {
  display: none;
}

/*▲▲news-style▲▲*/
/*▼▼qa_area-style▼▼*/
.qa_area_box {
  margin-bottom: 20px;
  border-bottom: 1px dashed #d6d6d6;
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.qa_area dt {
  font-weight: bold;
  position: relative;
  padding-left: 30px;
  padding-right: 35px;
  font-size: 21px;
  margin-bottom: 10px;
}
@media (max-width: 640px) {
  .qa_area dt {
    font-size: 18px;
  }
}
.qa_area dt::before {
  content: "Q";
  display: block;
  color: #00A960;
  font-family: "Zen Maru Gothic", sans-serif;
  position: absolute;
  top: -14px;
  left: 0;
  font-size: 32px;
}
.qa_area dt::after {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  background: url(../img/icon/icon-plus.png) center center no-repeat;
  background-color: #8ECE9A;
  border-radius: 50px;
  margin-left: 20px;
  position: absolute;
  right: 0;
  top: 0;
}
.qa_area dd {
  position: relative;
  padding-left: 30px;
  padding-right: 35px;
  max-height: 0;
  overflow: hidden;
  transition: 1s;
}
@media (max-width: 640px) {
  .qa_area dd {
    padding-left: 40px;
  }
}
.qa_area dd::before {
  content: "A";
  display: block;
  color: #ED5454;
  font-family: "Zen Maru Gothic", sans-serif;
  font-weight: bold;
  font-size: 32px;
  position: absolute;
  top: -14px;
  left: 0;
}
.qa_area dd p {
  margin-bottom: 10px;
}
.qa_area .qa_area_box.on dt::after {
  background: url(../img/icon/icon-minus.png) center center no-repeat;
  background-color: #00A960;
}
.qa_area .qa_area_box.on dd {
  max-height: 1000px;
}

/*▲▲news-style▲▲*/
/*404page*/
.inner-404 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 100px 0;
}
.inner-404 h2 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}
.inner-404 p {
  font-weight: bold;
  margin-bottom: 20px;
}
.inner-404 a {
  width: 98%;
  text-align: center;
  padding: 0.5em 1em;
  border-radius: 10px;
  display: block;
  background: #00A960;
  max-width: 300px;
  font-size: 1.2em;
  font-weight: bold;
  color: #ffffff;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/compornent/slick/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 40px;
  width: 30px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  padding: 0;
  margin-top: -20px;
  border: none;
  outline: none;
  z-index: 1;
  transition: 0.3s transform;
}
@media (max-width: 767px) {
  .slick-prev,
  .slick-next {
    height: 33px;
    width: 22px;
  }
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev:hover,
.slick-next:hover {
  will-change: transform;
}
.c-slider-list .slick-prev,
.c-slider-list .slick-next {
  top: 0;
  margin-top: 65px;
}
@media (max-width: 961px) {
  .c-slider-list .slick-prev,
  .c-slider-list .slick-next {
    margin-top: 25px;
  }
}

.slick-prev {
  left: -8px;
}
@media (max-width: 767px) {
  .slick-prev {
    left: -13px;
  }
}
.slick-prev:hover {
  transform: translateX(-10px);
}
@media (max-width: 961px) {
  .slick-prev:hover {
    transform: translateX(0);
  }
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "";
  display: block;
  width: inherit;
  height: inherit;
  background: no-repeat center right;
  background-size: cover;
}
[dir=rtl] .slick-prev:before {
  content: "→";
}
@media (max-width: 767px) {
  .c-thumb .slick-prev {
    left: -8px;
  }
}

.slick-next {
  right: -8px;
}
@media (max-width: 767px) {
  .slick-next {
    right: -13px;
  }
}
.slick-next:hover {
  transform: translateX(10px);
}
@media (max-width: 961px) {
  .slick-next:hover {
    transform: translateX(0);
  }
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "";
  display: block;
  width: inherit;
  height: inherit;
  background: no-repeat center right;
  background-size: cover;
}
[dir=rtl] .slick-next:before {
  content: "←";
}
@media (max-width: 767px) {
  .c-thumb .slick-next {
    right: -8px;
  }
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
.slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
  width: 100%;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.l-header {
  padding: 15px 0;
}
@media (max-width: 768px) {
  .l-header {
    position: fixed;
    background: #ffffff;
    width: 100%;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    z-index: 5000;
  }
}
@media (max-width: 640px) {
  .l-header {
    padding: 15px 0;
  }
}
.l-header .inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.l-header .inner .l-header__logo {
  width: 20%;
  max-width: 230px;
  display: flex;
  align-items: center;
}
@media (max-width: 1000px) {
  .l-header .inner .l-header__logo {
    max-width: 230px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 9000;
  }
}
@media (max-width: 768px) {
  .l-header .inner .l-header__logo {
    position: static;
    display: flex;
    align-items: center;
    width: 40%;
    max-width: 280px;
    margin-left: 20px;
  }
}
@media (max-width: 640px) {
  .l-header .inner .l-header__logo {
    width: 50%;
    margin-left: 10px;
  }
}
.l-header .inner .header-contents {
  width: 83%;
  max-width: 890px;
  font-size: 14px;
}
@media (max-width: 1000px) {
  .l-header .inner .header-contents {
    width: 100%;
    max-width: inherit;
    margin-right: 5px;
    margin-left: 5px;
  }
}
@media (max-width: 768px) {
  .l-header .inner .header-contents {
    display: none;
    margin-right: 0;
    margin-left: 0;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    height: 100vh;
    z-index: 9000;
    padding-bottom: 100px;
    background: #EFF3F5;
  }
}
.l-header .inner .header-contents .sp-inner {
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  grid-template-rows: 1fr 1fr;
}
@media (max-width: 768px) {
  .l-header .inner .header-contents .sp-inner {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #EFF3F5;
    z-index: 9500;
    display: flex;
    flex-direction: column;
    padding: 20px 2% 40px;
    height: 100%;
  }
}
.l-header .inner .header-contents .sp-inner .serch-area {
  grid-column: 2/3;
  grid-row: 1/2;
  width: 100%;
  max-width: 220px;
  margin-right: 10px;
}
@media (max-width: 1000px) {
  .l-header .inner .header-contents .sp-inner .serch-area {
    max-width: 160px;
    margin-right: 10px;
  }
}
@media (max-width: 768px) {
  .l-header .inner .header-contents .sp-inner .serch-area {
    order: 1;
    max-width: inherit;
    max-width: calc(100% - 50px);
  }
}
.l-header .inner .header-contents .sp-inner .serch-area form#form {
  display: flex;
}
.l-header .inner .header-contents .sp-inner .serch-area form#form input {
  width: 100%;
  height: 28px;
  border-radius: 100px;
  background: url(../img/icon/icon-serch.png) left center no-repeat;
  border: 1px solid #d6d6d6;
  background-size: contain;
  padding: 0.2em 0 0.2em 2em;
  margin-right: 10px;
}
@media (max-width: 768px) {
  .l-header .inner .header-contents .sp-inner .serch-area form#form input {
    background-color: #ffffff;
    padding: 0.5em 0 0.5em 2.5em;
  }
}
.l-header .inner .header-contents .sp-inner .serch-area form#form input::placeholder {
  color: #717171;
  font-size: 12px;
}
.l-header .inner .header-contents .sp-inner .serch-area form#form button div {
  background: #717171;
  color: #ffffff;
  border-radius: 5px;
  width: 2.5em;
}
.l-header .inner .header-contents .sp-inner .text {
  grid-column: 3/4;
  grid-row: 1/2;
  display: flex;
  border-right: 1px solid #d6d6d6;
}
@media (max-width: 768px) {
  .l-header .inner .header-contents .sp-inner .text {
    order: 3;
    justify-content: center;
    margin-bottom: 20px;
    border-right: none;
  }
}
.l-header .inner .header-contents .sp-inner .text li {
  margin-right: 10px;
}
@media (max-width: 768px) {
  .l-header .inner .header-contents .sp-inner .text li {
    margin-right: 10px;
    font-weight: bold;
    letter-spacing: -1px;
  }
  .l-header .inner .header-contents .sp-inner .text li:first-child {
    margin-right: 20px;
  }
}
.l-header .inner .header-contents .sp-inner .sns {
  grid-column: 4/5;
  grid-row: 1/2;
  display: flex;
}
@media (max-width: 768px) {
  .l-header .inner .header-contents .sp-inner .sns {
    order: 4;
    justify-content: center;
    padding-bottom: 50px;
  }
}
.l-header .inner .header-contents .sp-inner .sns li {
  margin-left: 20px;
}
@media (max-width: 1000px) {
  .l-header .inner .header-contents .sp-inner .sns li {
    margin-left: 15px;
    width: 24px;
  }
}
@media (max-width: 768px) {
  .l-header .inner .header-contents .sp-inner .sns li {
    margin-left: 30px;
    width: 28px;
  }
  .l-header .inner .header-contents .sp-inner .sns li:first-child {
    margin-left: 0;
  }
}
.l-header .inner .header-contents .sp-inner .global-navi {
  grid-column: 1/6;
  grid-row: 2/3;
  margin-top: 10px;
  font-weight: bold;
}
@media (max-width: 768px) {
  .l-header .inner .header-contents .sp-inner .global-navi {
    order: 2;
    width: 100%;
    margin-bottom: 20px;
  }
}
.l-header .inner .header-contents .sp-inner .global-navi > ul {
  display: flex;
  justify-content: end;
}
@media (max-width: 768px) {
  .l-header .inner .header-contents .sp-inner .global-navi > ul {
    flex-direction: column;
  }
}
.l-header .inner .header-contents .sp-inner .global-navi > ul > li {
  margin-left: 20px;
  border-bottom: 2px solid transparent;
}
@media (max-width: 768px) {
  .l-header .inner .header-contents .sp-inner .global-navi > ul > li {
    letter-spacing: 0;
    margin-left: 0;
    border-bottom: 1px dashed #666;
  }
}
.l-header .inner .header-contents .sp-inner .global-navi > ul > li > a {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #ffffff;
}
@media (max-width: 768px) {
  .l-header .inner .header-contents .sp-inner .global-navi > ul > li > a {
    border-bottom: none;
  }
}
.l-header .inner .header-contents .sp-inner .global-navi > ul > li > a:hover {
  color: #00A960;
}
@media (max-width: 768px) {
  .l-header .inner .header-contents .sp-inner .global-navi > ul > li > a:hover {
    color: #333;
  }
}
.l-header .inner .header-contents .sp-inner .global-navi > ul > li > a.parent::after {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  border: 5px solid transparent;
  border-top: 5px solid #00A960;
  margin: 3px 0 0 5px;
}
@media (max-width: 768px) {
  .l-header .inner .header-contents .sp-inner .global-navi > ul > li > a {
    padding: 0.5em 0;
    background: url(../img/icon/sp-menu-icon-arrow.png) center right no-repeat;
    background-size: 20px;
  }
  .l-header .inner .header-contents .sp-inner .global-navi > ul > li > a.parent {
    background: url(../img/icon/sp-menu-icon-plus.png) center right no-repeat;
    background-size: 20px;
  }
  .l-header .inner .header-contents .sp-inner .global-navi > ul > li > a.parent::after {
    content: none;
  }
}
.l-header .inner .header-contents .sp-inner .global-navi > ul > li .child-menu {
  max-height: 0;
  overflow: hidden;
  transition: 0.5s;
  opacity: 0;
  position: absolute;
  padding: 1em 0.5em;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 0 0 15px 15px;
  font-weight: normal;
  margin-top: 2px;
  z-index: 100;
}
@media (max-width: 768px) {
  .l-header .inner .header-contents .sp-inner .global-navi > ul > li .child-menu {
    position: static;
    padding-top: 0;
    padding-right: 0;
    background: none;
  }
}
.l-header .inner .header-contents .sp-inner .global-navi > ul > li .child-menu li {
  text-indent: -1em;
  padding-left: 1em;
  margin-bottom: 5px;
}
.l-header .inner .header-contents .sp-inner .global-navi > ul > li .child-menu li:last-child {
  margin-bottom: 0px;
}
@media (max-width: 768px) {
  .l-header .inner .header-contents .sp-inner .global-navi > ul > li .child-menu li:last-child {
    border-bottom: none;
  }
}
@media (max-width: 768px) {
  .l-header .inner .header-contents .sp-inner .global-navi > ul > li .child-menu li {
    border-bottom: 1px dotted #666;
  }
}
@media (max-width: 768px) {
  .l-header .inner .header-contents .sp-inner .global-navi > ul > li .child-menu li a {
    display: block;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    background: url(../img/icon/sp-menu-icon-arrow.png) center right no-repeat;
    background-size: 20px;
  }
}
@media (max-width: 768px) {
  .l-header .inner .header-contents .sp-inner .global-navi > ul > li .child-menu li.on a {
    display: block;
    background: url(../img/icon/sp-menu-icon-arrow-on.png) center right no-repeat;
    background-size: 20px;
  }
}
.l-header .inner .header-contents .sp-inner .global-navi > ul > li:first-child {
  margin-left: 0;
}
@media (min-width: 769px) {
  .l-header .inner .header-contents .sp-inner .global-navi > ul .gnav-switch-pc:hover {
    border-bottom: 2px solid #00A960;
  }
  .l-header .inner .header-contents .sp-inner .global-navi > ul .gnav-switch-pc:hover .parent::after {
    content: "";
    border-bottom: 5px solid #00A960;
    margin: 3px 0 0 5px;
    border-top: none;
  }
}
@media (min-width: 769px) and (max-width: 768px) {
  .l-header .inner .header-contents .sp-inner .global-navi > ul .gnav-switch-pc:hover .parent::after {
    content: none;
  }
}
@media (min-width: 769px) {
  .l-header .inner .header-contents .sp-inner .global-navi > ul .gnav-switch-pc:hover .child-menu {
    max-height: 500px;
    display: block;
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .l-header .inner .header-contents .sp-inner .global-navi > ul > li.on {
    border-bottom: 1px dashed #666;
  }
}
@media (max-width: 768px) {
  .l-header .inner .header-contents .sp-inner .global-navi > ul > li.on > a {
    color: #00A960;
    background: url(../img/icon/sp-menu-icon-minus.png) center right no-repeat;
    background-size: 20px;
  }
}
.l-header .inner .header-contents .sp-inner .global-navi > ul > li.on > a.parent::after {
  content: "";
  /*border-bottom: 5px solid $key_color;
  margin: 3px 0 0 5px;
  border-top: none;*/
}
@media (max-width: 768px) {
  .l-header .inner .header-contents .sp-inner .global-navi > ul > li.on > a.parent::after {
    content: none;
  }
}
@media (max-width: 768px) {
  .l-header .inner .header-contents .sp-inner .global-navi > ul > li.on .child-menu {
    max-height: 500px;
    display: block;
    opacity: 1;
  }
}
.l-header .menu-button {
  display: none;
  position: fixed;
  top: 15px;
  right: 20px;
  z-index: 9500;
}
@media (max-width: 640px) {
  .l-header .menu-button {
    top: 10px;
    right: 10px;
  }
}
.l-header .menu-button .icon-area {
  width: 40px;
  height: 40px;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #666;
  border-radius: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 5px;
  position: relative;
}
.l-header .menu-button .icon-area span {
  display: block;
  width: 20px;
  height: 2px;
  background: #666;
  margin-bottom: 3px;
}
.l-header .menu-button .icon-area span:last-child {
  margin-bottom: 0;
}
.l-header .menu-button .icon-area::after {
  content: "MENU";
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  color: #666;
  position: absolute;
  bottom: -2em;
}
@media (max-width: 768px) {
  .l-header .menu-button {
    display: block;
  }
}

@media (max-width: 768px) {
  .on .header-contents {
    display: block !important;
  }
}

.on .menu-button .icon-area {
  border: 1px solid #d6d6d6;
}
.on .menu-button .icon-area span.bar1 {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1px;
  margin-left: -10px;
  background: #00A960;
}
.on .menu-button .icon-area span.bar2 {
  display: none;
}
.on .menu-button .icon-area span.bar3 {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1px;
  margin-left: -10px;
  background: #00A960;
}
.on .menu-button .icon-area::after {
  content: "CLOSE";
}

.fixed {
  width: 100%;
  padding: 15px 0;
  position: fixed;
  background: #fff;
  z-index: 9900;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.l-main {
  /**▼▼▼▼▼toppage▼▼▼▼▼**/
  /**▼▼▼▼▼page-list▼▼▼▼▼**/
  /**▲▲▲▲▲page-list▲▲▲▲▲**/
  /**▼▼▼▼▼page-shousai▼▼▼▼▼**/
  /**▲▲▲▲▲#page-shousai▲▲▲▲▲**/
  /**▼▼▼▼▼#page-about▼▼▼▼▼**/
  /**▲▲▲▲▲#page-about▲▲▲▲▲**/
  /**▼▼▼▼▼#page-menu▼▼▼▼▼**/
  /**▲▲▲▲▲#layout-menu▲▲▲▲▲**/
  /**▼▼▼▼▼#page-ticket▼▼▼▼▼**/
  /**▲▲▲▲▲page-06▲▲▲▲▲**/
  /**▼▼▼▼▼#page-gaiyou▼▼▼▼▼**/
  /**▲▲▲▲▲#page-gaiyou▲▲▲▲▲**/
  /**▼▼▼▼▼#page-news-list▼▼▼▼▼**/
  /**▲▲▲▲▲#page-news-list▲▲▲▲▲**/
  /**▼▼▼▼▼page-contact▼▼▼▼▼**/
  /**▲▲▲▲▲#page-contact▲▲▲▲▲**/
  /**▼▼▼▼▼#search-list▼▼▼▼▼**/
  /**▲▲▲▲▲#search-list▲▲▲▲▲**/
}
@media (max-width: 768px) {
  .l-main {
    padding-top: 90px;
  }
}
@media (max-width: 640px) {
  .l-main {
    padding-top: 80px;
  }
}
.l-main #toppage .mv-area {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .l-main #toppage .mv-area {
    overflow: hidden;
  }
}
.l-main #toppage .mv-area .mv-slider .slick-slider {
  background: #EFF3F5;
  margin-bottom: 0;
}
.l-main #toppage .mv-area .mv-slider .slick-dots {
  bottom: 20px;
}
.l-main #toppage .mv-area .mv-slider .slick-dots li button::before {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  background: #00A960;
  border: 1px solid #00A960;
  border-radius: 300px;
  opacity: 1;
}
.l-main #toppage .mv-area .mv-slider .slick-dots li.slick-active button::before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 2px solid #00A960;
  border-radius: 300px;
}
.l-main #toppage .news-area {
  margin-bottom: 150px;
}
@media (max-width: 640px) {
  .l-main #toppage .news-area {
    margin-bottom: 100px;
  }
}
.l-main #toppage .news-area .inner {
  background: #EFF3F5;
  border-radius: 0 0 50px 50px;
}
.l-main #toppage .news-area .inner .news-nav {
  margin-bottom: 0;
}
@media (max-width: 640px) {
  .l-main #toppage .news-area .inner .news-nav .nav-switch li:last-child {
    margin-top: 0;
    position: absolute;
    top: 55px;
    right: 15px;
    width: 145px;
  }
}
@media (max-width: 768px) {
  .l-main #toppage .news-area .inner .news-contents .select-news li:nth-last-of-type(1), .l-main #toppage .news-area .inner .news-contents .select-news li:nth-last-of-type(2) {
    display: none;
  }
}
.l-main #toppage .no1 {
  margin-bottom: 150px;
}
@media (max-width: 640px) {
  .l-main #toppage .no1 {
    margin-bottom: 100px;
  }
}
.l-main #toppage .no1 .inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background: #EFF3F5;
  border-radius: 50px;
  padding-top: 0;
  padding-bottom: 50px;
}
@media (max-width: 640px) {
  .l-main #toppage .no1 .inner {
    border-radius: 0;
  }
}
.l-main #toppage .no1 .inner h3 {
  text-align: center;
  position: relative;
  font-size: 21px;
  font-weight: 500;
  margin: 50px 0;
}
@media (max-width: 640px) {
  .l-main #toppage .no1 .inner h3 {
    margin: 20px 0 30px;
    font-size: 18px;
  }
}
.l-main #toppage .no1 .inner > ul {
  display: flex;
  justify-content: space-between;
  padding: 0 2%;
}
@media (max-width: 768px) {
  .l-main #toppage .no1 .inner > ul {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.l-main #toppage .no1 .inner > ul li {
  width: 24%;
  max-width: 270px;
  text-align: center;
}
@media (max-width: 768px) {
  .l-main #toppage .no1 .inner > ul li {
    width: 100%;
    margin-bottom: 30px;
    max-width: inherit;
  }
}
.l-main #toppage .no1 .inner > ul li a {
  display: block;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 60px;
  min-height: 410px;
  box-sizing: border-box;
}
@media (max-width: 1000px) {
  .l-main #toppage .no1 .inner > ul li a {
    border-radius: 30px;
  }
}
@media (max-width: 768px) {
  .l-main #toppage .no1 .inner > ul li a {
    display: grid;
    grid-template-columns: 5px 130px 1fr 5px;
    grid-template-rows: 1fr 1fr;
    position: relative;
    padding-top: 2em;
    min-height: inherit;
    align-items: center;
  }
}
.l-main #toppage .no1 .inner > ul li a h4 {
  margin: -10px 0 20px;
}
@media (max-width: 768px) {
  .l-main #toppage .no1 .inner > ul li a h4 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    font-size: 18px;
  }
}
.l-main #toppage .no1 .inner > ul li a h4 span {
  background: #00A960;
  padding: 0.1em 1em;
  font-weight: bold;
  color: #ffffff;
  border: 6px solid #EFF3F5;
  border-radius: 100px;
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
  -ms-background-clip: paddng-box;
}
.l-main #toppage .no1 .inner > ul li a p.ex1 {
  min-height: 70px;
  line-height: 1.5;
  font-weight: bold;
}
@media (max-width: 768px) {
  .l-main #toppage .no1 .inner > ul li a p.ex1 {
    grid-column: 3/4;
    grid-row: 1/2;
    min-height: inherit;
    margin-bottom: 0px;
    font-size: 18px;
  }
}
.l-main #toppage .no1 .inner > ul li a p.ex1 span {
  color: #00A960;
}
.l-main #toppage .no1 .inner > ul li a figure {
  min-height: 213px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: end;
}
@media (max-width: 768px) {
  .l-main #toppage .no1 .inner > ul li a figure {
    grid-column: 2/3;
    grid-row: 1/3;
    min-height: inherit;
    margin: 0 auto 20px;
  }
}
.l-main #toppage .no1 .inner > ul li a .btn-style {
  width: 90%;
  max-width: 200px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .l-main #toppage .no1 .inner > ul li a .btn-style {
    grid-column: 3/5;
    grid-row: 2/3;
    max-width: 360px;
  }
}
.l-main #toppage .no1 .inner > ul li a:hover {
  border: 1px solid #00A960;
  box-sizing: border-box;
}
.l-main #toppage .no1 .inner > ul li a:hover .btn-style {
  background: #00A960;
  opacity: 0.7;
}
.l-main #toppage .no1 .inner > ul li a:hover .btn-style::before, .l-main #toppage .no1 .inner > ul li a:hover .btn-style::after {
  content: none;
}
.l-main #toppage .no1 .inner > ul li a:hover .btn-style .text {
  color: #ffffff;
}
.l-main #toppage .no1 .inner > ul li a:hover .btn-style .arrow path {
  fill: #ffffff;
}
@media (max-width: 768px) {
  .l-main #toppage .no1 .inner > ul li.s1 figure {
    max-width: 70px;
  }
}
@media (max-width: 768px) {
  .l-main #toppage .no1 .inner > ul li.s2 figure {
    max-width: 77px;
  }
}
@media (max-width: 768px) {
  .l-main #toppage .no1 .inner > ul li.s3 figure {
    max-width: 99px;
  }
}
@media (max-width: 768px) {
  .l-main #toppage .no1 .inner > ul li.s4 figure {
    max-width: 113px;
  }
}
.l-main #toppage .no2 {
  margin-bottom: 100px;
}
@media (max-width: 640px) {
  .l-main #toppage .no2 {
    margin-bottom: 60px;
  }
}
.l-main #toppage .no2 .inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background: #EFF3F5;
  border-radius: 50px;
  padding-bottom: 50px;
}
@media (max-width: 640px) {
  .l-main #toppage .no2 .inner {
    border-radius: 0;
  }
}
.l-main #toppage .no2 .inner h2 {
  margin-bottom: 40px;
}
@media (max-width: 640px) {
  .l-main #toppage .no2 .inner h2 {
    margin-bottom: 20px;
  }
}
.l-main #toppage .no2 .inner .thumb-slider-area {
  padding: 0 4%;
}
.l-main #toppage .no2 .inner .thumb-slider-area ul {
  position: relative;
  display: flex;
  justify-content: space-around;
}
.l-main #toppage .no2 .inner .thumb-slider-area ul li {
  width: 34%;
  max-width: 360px;
  padding: 0 5px;
  text-align: center;
}
@media (max-width: 640px) {
  .l-main #toppage .no2 .inner .thumb-slider-area ul li {
    width: 100%;
    max-width: inherit;
  }
}
.l-main #toppage .no2 .inner .thumb-slider-area ul li .contents a figure {
  margin-bottom: 0;
  display: inline-block;
  height: 165px;
  position: relative;
  width: 100%;
}
.l-main #toppage .no2 .inner .thumb-slider-area ul li .contents a figure img {
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  object-fit: contain;
}
.l-main #toppage .no2 .inner .thumb-slider-area ul li .contents a p {
  margin-bottom: 20px;
  font-weight: bold;
}
.l-main #toppage .no2 .inner .thumb-slider-area ul li .contents a .date {
  font-size: 0.8em;
  margin-bottom: 0;
}
.l-main #toppage .no2 .inner .thumb-slider-area .slick-prev {
  background: url(../img/icon/icon-slick-arrow-l.png) center center no-repeat;
  background-color: #ffffff;
  background-size: contain;
  border-radius: 80px;
  width: 28px;
  height: 28px;
  left: -25px;
}
@media (max-width: 640px) {
  .l-main #toppage .no2 .inner .thumb-slider-area .slick-prev {
    top: inherit;
    bottom: -30px;
    left: 40%;
  }
}
.l-main #toppage .no2 .inner .thumb-slider-area .slick-next {
  background: url(../img/icon/icon-slick-arrow-r.png) center center no-repeat;
  background-color: #ffffff;
  background-size: contain;
  border-radius: 80px;
  width: 28px;
  height: 28px;
  right: -25px;
}
@media (max-width: 640px) {
  .l-main #toppage .no2 .inner .thumb-slider-area .slick-next {
    top: inherit;
    bottom: -30px;
    right: 40%;
  }
}
.l-main #toppage .no2 .inner .btn-style {
  width: 100%;
  max-width: 410px;
  margin: 0 auto;
}
@media (max-width: 640px) {
  .l-main #toppage .no2 .inner .btn-style {
    width: 80%;
  }
}
.l-main #toppage .no3 {
  margin-bottom: 100px;
}
@media (max-width: 768px) {
  .l-main #toppage .no3 .inner {
    padding: 0 2%;
  }
}
.l-main #toppage .no3 .inner h3 {
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 40px;
}
@media (max-width: 640px) {
  .l-main #toppage .no3 .inner h3 {
    font-size: 28px;
  }
}
.l-main #toppage .no3 .inner h3 span {
  font-size: 18px;
}
.l-main #toppage .no3 .inner .calendar-area {
  width: 100%;
  margin: 0 auto;
  max-width: 800px;
}
@media (max-width: 768px) {
  .l-main #toppage .no3 .inner .calendar-area {
    max-width: inherit;
  }
}
.l-main #toppage .no3 .inner .calendar-area .fc-left {
  float: none;
}
.l-main #toppage .no3 .inner .calendar-area .fc-left h2 {
  float: none;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}
.l-main #toppage .no3 .inner .calendar-area .fc-right {
  float: none;
  display: flex;
  justify-content: center;
}
.l-main #toppage .no3 .inner .calendar-area .fc-right > div:nth-of-type(1) {
  order: 1;
}
.l-main #toppage .no3 .inner .calendar-area .fc-right > div:nth-of-type(2) {
  order: 3;
  margin: 0;
}
.l-main #toppage .no3 .inner .calendar-area .fc-right > div:nth-of-type(3) {
  order: 2;
  margin: 0 20px;
}
.l-main #toppage .no4 {
  margin-bottom: 100px;
}
@media (max-width: 640px) {
  .l-main #toppage .no4 {
    margin-bottom: 60px;
  }
}
.l-main #toppage .no4 .inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background: #EFF3F5;
  border-radius: 50px;
  display: flex;
  justify-content: space-around;
  padding: 50px 100px;
  position: relative;
}
@media (max-width: 768px) {
  .l-main #toppage .no4 .inner {
    padding: 50px 2%;
  }
}
@media (max-width: 640px) {
  .l-main #toppage .no4 .inner {
    width: 96%;
    padding-top: 200px;
    flex-direction: column;
  }
}
.l-main #toppage .no4 .inner .box1 {
  width: 48%;
  max-width: 435px;
}
@media (max-width: 640px) {
  .l-main #toppage .no4 .inner .box1 {
    order: 2;
    width: 100%;
    max-width: inherit;
  }
}
@media (max-width: 640px) {
  .l-main #toppage .no4 .inner .box1 .sub-top {
    position: absolute;
    top: 30px;
    text-align: center;
    width: 100%;
  }
}
.l-main #toppage .no4 .inner .box1 .sub-top h2 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
}
.l-main #toppage .no4 .inner .box1 .sub-top p {
  margin-bottom: 50px;
  font-weight: bold;
}
@media (max-width: 640px) {
  .l-main #toppage .no4 .inner .box1 .sub-top p {
    font-size: 16px;
  }
}
.l-main #toppage .no4 .inner .box1 ul li {
  display: flex;
  align-items: center;
  padding: 0 0 20px 0;
  border-bottom: 1px solid #d6d6d6;
  margin-bottom: 20px;
}
@media (max-width: 640px) {
  .l-main #toppage .no4 .inner .box1 ul li {
    display: block;
  }
}
.l-main #toppage .no4 .inner .box1 ul li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 640px) {
  .l-main #toppage .no4 .inner .box1 ul li a {
    flex-direction: column;
  }
}
.l-main #toppage .no4 .inner .box1 ul li figure {
  width: 88px;
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  flex-direction: column;
}
@media (max-width: 640px) {
  .l-main #toppage .no4 .inner .box1 ul li figure {
    flex-direction: row;
    justify-content: inherit;
    width: inherit;
    margin-right: 0;
  }
}
.l-main #toppage .no4 .inner .box1 ul li figure figcaption {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
@media (max-width: 640px) {
  .l-main #toppage .no4 .inner .box1 ul li figure figcaption {
    margin-left: 10px;
  }
}
.l-main #toppage .no4 .inner .box1 ul li p {
  width: calc(100% - 110px);
  font-size: 0.8em;
  padding-right: 1em;
}
@media (max-width: 640px) {
  .l-main #toppage .no4 .inner .box1 ul li p {
    width: inherit;
    font-size: 1em;
    padding-right: 0;
  }
}
.l-main #toppage .no4 .inner .box1 ul li:last-child {
  padding: 20px 0 0 0;
  border-bottom: none;
}
.l-main #toppage .no4 .inner .box2 {
  width: 48%;
  max-width: 344px;
}
@media (max-width: 640px) {
  .l-main #toppage .no4 .inner .box2 {
    order: 1;
    width: 100%;
    margin-bottom: 50px;
    max-width: inherit;
  }
}
.l-main #toppage .no4 .inner .box2 h3 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}
.l-main #toppage .no4 .inner .box2 h3 figure {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
@media (max-width: 640px) {
  .l-main #toppage .no4 .inner .box2 h3 figure {
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
  }
}
.l-main #toppage .no4 .inner .box2 h3 figure figcaption {
  margin-left: 10px;
}
@media (max-width: 640px) {
  .l-main #toppage .no4 .inner .box2 h3 {
    font-size: 18px;
    font-weight: bold;
  }
}
.l-main #toppage .no4 .inner .box2 .photo-box {
  height: 360px;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.l-main #toppage .no4 .inner .box2 .photo-box::-webkit-scrollbar {
  width: 10px;
}
.l-main #toppage .no4 .inner .box2 .photo-box::-webkit-scrollbar-track {
  background: #cccccc;
  border-radius: 5px;
}
.l-main #toppage .no4 .inner .box2 .photo-box::-webkit-scrollbar-thumb {
  background-color: #1DA1F2;
  border-radius: 5px;
}
.l-main #toppage .mail-magazine {
  padding-bottom: 100px;
}
.l-main #toppage .mail-magazine h2 {
  margin-bottom: 20px;
}
.l-main #toppage .mail-magazine .inner {
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  border: 1px solid #00A960;
  border-radius: 500px;
  display: flex;
  padding: 40px 80px;
  align-items: center;
}
@media (max-width: 640px) {
  .l-main #toppage .mail-magazine .inner {
    width: 96%;
    border-radius: 30px;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
  }
}
.l-main #toppage .mail-magazine .inner ul {
  width: 100%;
  max-width: 430px;
  margin-right: 50px;
}
@media (max-width: 640px) {
  .l-main #toppage .mail-magazine .inner ul {
    margin: 0 auto 20px;
  }
}
.l-main #toppage .mail-magazine .inner ul li {
  margin-bottom: 20px;
  text-align: center;
  color: #00A960;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.l-main #toppage .mail-magazine .inner ul li a {
  display: block;
  background: #EFF8F0;
  border: 1px solid #EFF8F0;
  border-radius: 100px;
  padding: 1em;
  width: 100%;
  position: relative;
}
@media (max-width: 640px) {
  .l-main #toppage .mail-magazine .inner ul li a {
    border: 1px solid #00A960;
  }
}
.l-main #toppage .mail-magazine .inner ul li a .text {
  position: relative;
}
.l-main #toppage .mail-magazine .inner ul li a .text::before {
  content: "";
  width: 26px;
  height: 22px;
  background: url(../img/icon/icon-mail.png) center center no-repeat;
  display: block;
  position: absolute;
  left: -40px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
.l-main #toppage .mail-magazine .inner ul li a .arrow-cirlue.green {
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
.l-main #toppage .mail-magazine .inner ul li:last-child {
  margin-bottom: 0;
}
.l-main #toppage .mail-magazine .inner ul li:last-child a .text::before {
  background: url(../img/icon/icon-article.png) center center no-repeat;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
.l-main #toppage .mail-magazine .inner ul li:hover a {
  background: #ffffff;
  border: 1px solid #00A960;
}
.l-main #toppage .mail-magazine .inner ul li:hover a .arrow-cirlue.green {
  background: #8ECE9A;
}
.l-main #toppage .mail-magazine .inner ul li:hover a .arrow-cirlue.green path {
  fill: #ffffff;
}
.l-main #toppage .mail-magazine .inner .qr-code-box {
  width: 110px;
  height: 110px;
}
.l-main #layout-list .mv-area {
  margin-bottom: 60px;
}
@media (max-width: 640px) {
  .l-main #layout-list .mv-area {
    margin-bottom: 50px;
  }
}
.l-main #layout-list .mv-area .inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background: #EFF3F5;
  border-radius: 0 0 50px 50px;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
@media (max-width: 640px) {
  .l-main #layout-list .mv-area .inner {
    padding: 15px;
  }
}
.l-main #layout-list .mv-area .inner h2 {
  font-size: 32px;
  font-weight: bold;
}
@media (max-width: 640px) {
  .l-main #layout-list .mv-area .inner h2 {
    font-size: 21px;
  }
}
.l-main #layout-list .mv-area .inner h2 span {
  color: #00A960;
  position: relative;
}
.l-main #layout-list .mv-area .inner h2 span::after {
  content: "・";
  position: absolute;
  top: -1em;
  left: 50%;
  margin-left: -0.5em;
}
.l-main #layout-list .mv-area .inner figure {
  width: 230px;
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 300px;
}
@media (max-width: 640px) {
  .l-main #layout-list .mv-area .inner figure {
    width: 130px;
    height: 130px;
  }
}
.l-main #layout-list .page-link {
  text-align: center;
  margin-bottom: 60px;
  font-size: 1.1em;
  font-weight: bold;
}
.l-main #layout-list .page-link a {
  color: #00A960;
  text-decoration: underline;
}
.l-main #layout-list .no1 .inner {
  width: 100%;
  max-width: 1060px;
  margin: 0 auto 100px;
}
@media (max-width: 768px) {
  .l-main #layout-list .no1 .inner {
    width: 96%;
    margin-bottom: 50px;
  }
}
.l-main #layout-list .no1 .inner h3 {
  margin-bottom: 30px;
}
.l-main #layout-list .no1 .inner > p {
  margin-bottom: 50px;
}
.l-main #layout-list .no1 .inner ul li {
  border-bottom: 1px dashed #d6d6d6;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
@media (max-width: 640px) {
  .l-main #layout-list .no1 .inner ul li {
    position: relative;
  }
}
.l-main #layout-list .no1 .inner ul li a {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.l-main #layout-list .no1 .inner ul li .head-word {
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  margin-bottom: 20px;
  line-height: 1.2;
  padding-left: 1.5em;
  position: relative;
}
@media (max-width: 640px) {
  .l-main #layout-list .no1 .inner ul li .head-word {
    padding-right: 40px;
  }
}
.l-main #layout-list .no1 .inner ul li .head-word::before {
  content: "●";
  color: #00A960;
  position: absolute;
  top: 0;
  left: 0;
}
.l-main #layout-list .no1 .inner ul li .text {
  width: calc(100% - 80px);
}
@media (max-width: 640px) {
  .l-main #layout-list .no1 .inner ul li .text {
    width: inherit;
  }
}
@media (max-width: 640px) {
  .l-main #layout-list .no1 .inner ul li .arrow-cirlue.green2 {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.l-main #layout-list .no1 .inner ul li:hover .arrow-cirlue.green2 {
  background: #EFF8F0;
}
.l-main #layout-list .no1 .inner ul.dl-style li {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 640px) {
  .l-main #layout-list .no1 .inner ul.dl-style li {
    flex-direction: column;
  }
}
.l-main #layout-list .no1 .inner ul.dl-style .head-word {
  max-width: 300px;
  margin-bottom: 0;
  margin-right: 10px;
}
@media (max-width: 640px) {
  .l-main #layout-list .no1 .inner ul.dl-style .head-word {
    max-width: inherit;
    margin: 0 0 10px;
  }
}
.l-main #layout-list .no1 .inner ul.dl-style .text {
  width: 100%;
  max-width: 200px;
  margin-left: 10px;
  display: block;
  background: #00A960;
  color: #ffffff;
  display: block;
  text-align: center;
  padding: 0.5em 1em;
  text-align: center;
  border-radius: 10px;
  font-weight: bold;
  font-size: 18px;
}
@media (max-width: 640px) {
  .l-main #layout-list .no1 .inner ul.dl-style .text {
    width: 80%;
    max-width: inherit;
    margin-left: 0;
  }
}
.l-main #layout-list .no1 .inner .table2-area {
  margin-top: 60px;
}
.l-main #layout-list .no2 {
  margin-bottom: 100px;
}
.l-main #layout-list .no2 h3 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 50px;
  text-align: center;
}
@media (max-width: 640px) {
  .l-main #layout-list .no2 h3 {
    font-size: 24px;
  }
}
.l-main #layout-list .no2 .inner {
  background: #EFF3F5;
  border-radius: 80px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 20px;
}
.l-main #layout-list .no2 .inner.stage-ver {
  max-width: 1000px;
}
@media (max-width: 768px) {
  .l-main #layout-list .no2 .inner {
    border-radius: 0;
    padding: 50px 10px;
  }
}
.l-main #layout-list .no2 .inner > ul {
  display: flex;
  justify-content: space-between;
  padding: 0 3%;
}
@media (max-width: 768px) {
  .l-main #layout-list .no2 .inner > ul {
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0;
  }
}
.l-main #layout-list .no2 .inner > ul li {
  width: 49%;
  max-width: 270px;
  text-align: center;
  margin-right: 1%;
}
@media (max-width: 768px) {
  .l-main #layout-list .no2 .inner > ul li {
    margin-bottom: 40px;
  }
}
@media (max-width: 640px) {
  .l-main #layout-list .no2 .inner > ul li {
    width: 100%;
    max-width: inherit;
  }
}
.l-main #layout-list .no2 .inner > ul li:last-child {
  margin-right: 0;
}
.l-main #layout-list .no2 .inner > ul li a {
  display: block;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 60px;
  min-height: 410px;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .l-main #layout-list .no2 .inner > ul li a {
    border-radius: 30px;
    display: grid;
    grid-template-columns: 5px 130px 1fr 5px;
    grid-template-rows: 1fr 1fr;
    position: relative;
    padding-top: 2em;
    min-height: inherit;
    align-items: center;
  }
}
.l-main #layout-list .no2 .inner > ul li a h4 {
  margin: -10px 0 20px;
}
@media (max-width: 640px) {
  .l-main #layout-list .no2 .inner > ul li a h4 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    font-size: 18px;
  }
}
.l-main #layout-list .no2 .inner > ul li a h4 span {
  background: #00A960;
  padding: 0.1em 1em;
  font-weight: bold;
  color: #ffffff;
  border: 6px solid #EFF3F5;
  border-radius: 100px;
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
  -ms-background-clip: paddng-box;
}
.l-main #layout-list .no2 .inner > ul li a p.ex1 {
  min-height: 70px;
  line-height: 1.5;
  font-weight: bold;
}
@media (max-width: 640px) {
  .l-main #layout-list .no2 .inner > ul li a p.ex1 {
    grid-column: 3/4;
    grid-row: 1/2;
    min-height: inherit;
    font-size: 18px;
    margin: 0;
  }
}
.l-main #layout-list .no2 .inner > ul li a p.ex1 span {
  color: #00A960;
}
.l-main #layout-list .no2 .inner > ul li a figure {
  min-height: 213px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: end;
}
@media (max-width: 640px) {
  .l-main #layout-list .no2 .inner > ul li a figure {
    grid-column: 2/3;
    grid-row: 1/3;
    min-height: inherit;
    margin: 0 auto 20px;
  }
}
.l-main #layout-list .no2 .inner > ul li a .btn-style {
  width: 90%;
  max-width: 200px;
  margin: 0 auto;
}
@media (max-width: 640px) {
  .l-main #layout-list .no2 .inner > ul li a .btn-style {
    grid-column: 3/5;
    grid-row: 2/3;
    max-width: 360px;
  }
}
.l-main #layout-list .no2 .inner > ul li a:hover {
  border: 1px solid #00A960;
  box-sizing: border-box;
}
.l-main #layout-list .no2 .inner > ul li a:hover .btn-style {
  background: #00A960;
  opacity: 0.7;
}
.l-main #layout-list .no2 .inner > ul li a:hover .btn-style::before, .l-main #layout-list .no2 .inner > ul li a:hover .btn-style::after {
  content: none;
}
.l-main #layout-list .no2 .inner > ul li a:hover .btn-style .text {
  color: #ffffff;
}
.l-main #layout-list .no2 .inner > ul li a:hover .btn-style .arrow path {
  fill: #ffffff;
}
@media (max-width: 768px) {
  .l-main #layout-list .no2 .inner > ul li.s1 figure {
    max-width: 70px;
  }
}
@media (max-width: 768px) {
  .l-main #layout-list .no2 .inner > ul li.s2 figure {
    max-width: 77px;
  }
}
@media (max-width: 768px) {
  .l-main #layout-list .no2 .inner > ul li.s3 figure {
    max-width: 99px;
  }
}
@media (max-width: 768px) {
  .l-main #layout-list .no2 .inner > ul li.s4 figure {
    max-width: 113px;
  }
}
.l-main #layout-list .other-menu-area h3 {
  text-align: center;
  position: relative;
  font-size: 21px;
  font-weight: 500;
  margin: 50px 0;
}
.l-main #layout-list .other-menu-area .inner {
  width: 100%;
  max-width: 1200px;
  background: #EFF3F5;
  border-radius: 50px;
  margin: 0 auto 100px;
  padding: 50px 0;
}
@media (max-width: 768px) {
  .l-main #layout-list .other-menu-area .inner {
    border-radius: 0;
  }
}
.l-main #layout-shousai .contents-area {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .l-main #layout-shousai .contents-area {
    width: 96%;
    padding-top: 10px;
  }
}
.l-main #layout-shousai .contents-area .sub-top {
  width: 68%;
  max-width: 820px;
  position: relative;
}
@media (max-width: 768px) {
  .l-main #layout-shousai .contents-area .sub-top {
    width: 100%;
    max-width: inherit;
  }
}
.l-main #layout-shousai .contents-area .sub-top h2 {
  font-weight: bold;
  font-size: 28px;
  color: #00A960;
  margin-bottom: 20px;
  width: 100%;
}
.l-main #layout-shousai .contents-area .sub-top h2::before {
  content: "";
  font-size: 16px;
  color: #333;
  display: block;
}
@media (max-width: 768px) {
  .l-main #layout-shousai .contents-area .sub-top h2::before {
    margin-right: 5.5em;
  }
}
.l-main #layout-shousai .contents-area .sub-top h2.cats-management::before {
  content: "経営の相談をしたい";
}
.l-main #layout-shousai .contents-area .sub-top h2.cats-seminar::before {
  content: "経営に関するセミナーや交流会等に参加したい";
}
.l-main #layout-shousai .contents-area .sub-top h2.cats-collaboration::before {
  content: "協業できる区内の事業所や事業用物件を探したい";
}
.l-main #layout-shousai .contents-area .sub-top h2.cats-subsidy::before {
  content: "助成金を活用したい";
}
.l-main #layout-shousai .contents-area .sub-top h2.cats-loan::before {
  content: "融資を受けたい";
}
.l-main #layout-shousai .contents-area .sub-top h2.cats-upbringing::before {
  content: "人材育成をしたい";
}
.l-main #layout-shousai .contents-area .sub-top h2.cats-welfare::before {
  content: "福利厚生のサービスを受けたい";
}
.l-main #layout-shousai .contents-area .sub-top h2.cats-training::before {
  content: "産業研修センターを利用したい";
}
.l-main #layout-shousai .contents-area .sub-top h2.cats-founding::before {
  content: "創業支援施設について";
}
.l-main #layout-shousai .contents-area .sub-top h2.cats-example::before {
  content: "活用事例";
}
.l-main #layout-shousai .contents-area .sub-top time {
  position: absolute;
  top: 0;
  right: 0;
}
.l-main #layout-shousai .contents-area .main-contents {
  width: 68%;
  max-width: 820px;
}
@media (max-width: 768px) {
  .l-main #layout-shousai .contents-area .main-contents {
    width: 100%;
    max-width: inherit;
  }
}
.l-main #layout-shousai .contents-area .main-contents .write-area .mv-area figure {
  margin-bottom: 20px;
  text-align: center;
}
.l-main #layout-shousai .contents-area .main-contents .write-area > p,
.l-main #layout-shousai .contents-area .main-contents .write-area > div {
  margin-bottom: 20px;
}
.l-main #layout-shousai .contents-area .main-contents .write-area a {
  color: #1622ad;
  font-weight: bold;
  text-decoration: underline;
}
.l-main #layout-shousai .contents-area .main-contents .write-area a img {
  text-decoration: none;
}
.l-main #layout-shousai .contents-area .main-contents .write-area > ul, .l-main #layout-shousai .contents-area .main-contents .write-area > ol, .l-main #layout-shousai .contents-area .main-contents .write-area > div ul, .l-main #layout-shousai .contents-area .main-contents .write-area > div ol {
  margin: 20px auto 30px;
  width: 100%;
  max-width: 630px;
  padding: 1em;
  background: #EFF8F0;
  border-radius: 20px;
  border: 1px dotted #00A960;
}
.l-main #layout-shousai .contents-area .main-contents .write-area > ul li, .l-main #layout-shousai .contents-area .main-contents .write-area > ol li, .l-main #layout-shousai .contents-area .main-contents .write-area > div ul li, .l-main #layout-shousai .contents-area .main-contents .write-area > div ol li {
  position: relative;
  padding-left: 1.8em;
  margin-bottom: 10px;
  line-height: 1.5;
}
.l-main #layout-shousai .contents-area .main-contents .write-area > ul li::before, .l-main #layout-shousai .contents-area .main-contents .write-area > ol li::before, .l-main #layout-shousai .contents-area .main-contents .write-area > div ul li::before, .l-main #layout-shousai .contents-area .main-contents .write-area > div ol li::before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background-color: #00A960;
  border-radius: 300px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.l-main #layout-shousai .contents-area .main-contents .write-area > ul li:last-child, .l-main #layout-shousai .contents-area .main-contents .write-area > ol li:last-child, .l-main #layout-shousai .contents-area .main-contents .write-area > div ul li:last-child, .l-main #layout-shousai .contents-area .main-contents .write-area > div ol li:last-child {
  margin-bottom: 0;
}
.l-main #layout-shousai .contents-area .main-contents .write-area > ol li, .l-main #layout-shousai .contents-area .main-contents .write-area > div ol li {
  list-style: decimal;
  margin-left: 1.4em;
  padding-left: 0.5em;
}
.l-main #layout-shousai .contents-area .main-contents .write-area > ol li::marker, .l-main #layout-shousai .contents-area .main-contents .write-area > div ol li::marker {
  font-weight: bold;
  font-size: 0.9em;
  color: #008149;
}
.l-main #layout-shousai .contents-area .main-contents .write-area > ol li::before, .l-main #layout-shousai .contents-area .main-contents .write-area > div ol li::before {
  content: none;
}
.l-main #layout-shousai .contents-area .main-contents .write-area ul.free-style-list {
  margin: 20px auto 30px;
  width: 100%;
  max-width: 630px;
  padding: 1em;
  background: #EFF8F0;
  border-radius: 20px;
  border: 1px dotted #00A960;
}
.l-main #layout-shousai .contents-area .main-contents .write-area ul.free-style-list li {
  position: relative;
  padding-left: 2em;
  margin-bottom: 10px;
  line-height: 1.5;
}
.l-main #layout-shousai .contents-area .main-contents .write-area ul.free-style-list li::before {
  content: none;
}
.l-main #layout-shousai .contents-area .main-contents .write-area ul.free-style-list li:last-child {
  margin-bottom: 0;
}
.l-main #layout-shousai .contents-area .main-contents .write-area ul.free-style-list li span {
  position: absolute;
  top: 0.2em;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: #00A960;
  border-radius: 100px;
  color: #ffffff;
  padding: 0.2em;
  font-size: 0.8em !important;
}
.l-main #layout-shousai .contents-area .main-contents .write-area > h2 {
  background: #EFF8F0;
  color: #00A960;
  font-size: 24px;
  font-weight: bold;
  position: relative;
  margin-bottom: 20px;
  padding: 0.5em 0.5em 0.5em 1.5em;
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .main-contents .write-area > h2 {
    font-size: 21px;
  }
}
.l-main #layout-shousai .contents-area .main-contents .write-area > h2::before {
  content: "";
  display: block;
  background: #00A960;
  width: 8px;
  height: calc(100% - 1em);
  position: absolute;
  top: 0.5em;
  left: 0.5em;
}
.l-main #layout-shousai .contents-area .main-contents .write-area > h3 {
  color: #00A960;
  font-size: 21px;
  font-weight: bold;
  position: relative;
  margin-bottom: 20px;
  padding: 0.5em 0 0.5em 1.8em;
  border-bottom: 1px solid #00A960;
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .main-contents .write-area > h3 {
    font-size: 18px;
  }
}
.l-main #layout-shousai .contents-area .main-contents .write-area > h3::before {
  content: "";
  display: block;
  background: #00A960;
  width: 8px;
  height: calc(100% - 1em);
  position: absolute;
  top: 0.5em;
  left: 0;
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .main-contents .write-area > h3::before {
    width: 6px;
  }
}
.l-main #layout-shousai .contents-area .main-contents .write-area > h3::after {
  content: "";
  display: block;
  background: #00A960;
  width: 8px;
  height: calc(100% - 1em);
  position: absolute;
  top: 0.5em;
  left: 0.8em;
}
.l-main #layout-shousai .contents-area .main-contents .write-area > h4 {
  color: #00A960;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  padding: 0.05em 0 0.05em 1em;
  margin-bottom: 20px;
}
.l-main #layout-shousai .contents-area .main-contents .write-area > h4::before {
  content: "";
  display: block;
  background: #00A960;
  width: 8px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.l-main #layout-shousai .contents-area .main-contents .write-area > h5 {
  font-weight: bold;
  position: relative;
  padding-left: 1.5em;
  margin-bottom: 20px;
}
.l-main #layout-shousai .contents-area .main-contents .write-area > h5::before {
  content: ">>";
  color: #00A960;
  position: absolute;
  font-weight: bold;
  top: 0.2em;
  left: 0;
  font-size: 0.8em;
}
.l-main #layout-shousai .contents-area .main-contents .write-area > h6 {
  font-weight: bold;
  position: relative;
  padding-left: 1.5em;
  margin-bottom: 20px;
}
.l-main #layout-shousai .contents-area .main-contents .write-area > h6::before {
  content: "◇";
  position: absolute;
  font-weight: bold;
  top: 0.25em;
  left: 0.25em;
  font-size: 0.8em;
}
.l-main #layout-shousai .contents-area .main-contents .write-area > table, .l-main #layout-shousai .contents-area .main-contents .write-area > div table {
  width: 100%;
}
.l-main #layout-shousai .contents-area .main-contents .write-area > table tr, .l-main #layout-shousai .contents-area .main-contents .write-area > div table tr {
  border-top: 1px solid #d6d6d6;
}
.l-main #layout-shousai .contents-area .main-contents .write-area > table tr th, .l-main #layout-shousai .contents-area .main-contents .write-area > div table tr th {
  text-align: center;
  padding: 0.5em;
  min-width: 3em;
}
.l-main #layout-shousai .contents-area .main-contents .write-area > table tr td, .l-main #layout-shousai .contents-area .main-contents .write-area > div table tr td {
  padding: 0.5em;
}
.l-main #layout-shousai .contents-area .main-contents .write-area > table tr > *, .l-main #layout-shousai .contents-area .main-contents .write-area > div table tr > * {
  border-right: 1px solid #d6d6d6;
  box-sizing: content-box;
}
.l-main #layout-shousai .contents-area .main-contents .write-area > table tr > *:last-child, .l-main #layout-shousai .contents-area .main-contents .write-area > div table tr > *:last-child {
  border-right: none;
}
.l-main #layout-shousai .contents-area .main-contents .write-area > table tr:last-child, .l-main #layout-shousai .contents-area .main-contents .write-area > div table tr:last-child {
  border-bottom: 1px solid #d6d6d6;
}
.l-main #layout-shousai .contents-area .main-contents .write-area > table tr:nth-child(odd), .l-main #layout-shousai .contents-area .main-contents .write-area > div table tr:nth-child(odd) {
  background: #EFF3F5;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area > p {
  text-align: center;
  margin-bottom: 20px;
  color: #00A960;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area > p span {
  padding: 0 0.5em;
  position: relative;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area > p span::before {
  content: "";
  display: block;
  width: 1em;
  height: 1em;
  border-right: 1px solid #00A960;
  transform: rotate(-45deg);
  position: absolute;
  top: 0.5em;
  left: -1.5em;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area > p span::after {
  content: "";
  display: block;
  width: 1em;
  height: 1em;
  border-left: 1px solid #00A960;
  transform: rotate(45deg);
  position: absolute;
  top: 0.5em;
  right: -1.5em;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area > p > a {
  display: block;
  width: 100%;
  text-decoration: none;
  max-width: 480px;
  margin: 0 auto 30px;
  color: #00A960;
  font-size: 21px;
  font-weight: bold;
  font-family: "Zen Maru Gothic", sans-serif;
  text-align: center;
  border: 1px solid #00A960;
  padding: 0.5em;
  border-radius: 100px;
  background: url(../img/icon/icon-arrow-u.png) right 10px center no-repeat;
  background-color: #ffffff;
  box-shadow: 5px 5px #8ECE9A;
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .main-contents .write-area .button-area > p > a {
    width: 90%;
  }
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area > p > a span {
  position: relative;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area > p > a span::before {
  content: "";
  background: url(../img/icon/icon-shorui.png) center center no-repeat;
  width: 20px;
  height: 22px;
  background-size: contain;
  position: absolute;
  top: 50%;
  margin-top: -11px;
  left: -1.5em;
  border-right: none;
  transform: none;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area > p > a span::after {
  border-left: none;
  transform: none;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area > p > a:hover {
  box-shadow: none;
  color: #ffffff;
  background: url(../img/icon/icon-arrow-u-on.png) right 10px center no-repeat;
  background-color: #00A960;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area > p > a:hover span::before {
  content: "";
  background: url(../img/icon/icon-shorui-on.png) center center no-repeat;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size {
  margin: 50px auto;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size p {
  margin-bottom: 0;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size br {
  display: none;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size a {
  font-family: "Noto Sans JP", YuGothicM, YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", "sans-serif";
  position: relative;
  text-decoration: none;
  background: none;
  max-width: 740px;
  padding: 40px 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: none;
  border: none;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 768px) {
  .l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size a {
    width: 96%;
    margin: 0 auto;
    flex-direction: column;
  }
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size a::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  z-index: 10;
  border: 1px solid #00A960;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 600px;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size a::after {
  content: "";
  display: block;
  width: 100%;
  max-width: 740px;
  height: 100%;
  border-radius: 600px;
  background: #EFF8F0;
  z-index: 5;
  position: absolute;
  top: 15px;
  left: 20px;
}
@media (max-width: 768px) {
  .l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size a::after {
    left: 10px;
  }
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size a span.head {
  position: absolute;
  top: -1em;
  font-size: 18px;
  font-weight: bold;
  color: #00A960;
  background: #ffffff;
  padding: 0 1em;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size a span.head::before, .l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size a span.head::after {
  content: none;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size a span.text {
  text-align: left;
  color: #333;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  display: block;
  z-index: 10;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size a span.text::before, .l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size a span.text::after {
  content: none;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size a span.text br {
  display: block;
}
@media (max-width: 768px) {
  .l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size a span.text br {
    display: none;
  }
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size a span.tel {
  text-align: left;
  font-size: 36px;
  color: #00A960;
  font-weight: bold;
  z-index: 10;
  position: relative;
}
@media (max-width: 768px) {
  .l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size a span.tel {
    font-size: 28px;
  }
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size a span.tel::before {
  content: "";
  display: block;
  background: url(../img/icon/icon-tel.png) center center no-repeat;
  background-size: contain;
  width: 38px;
  height: 38px;
  left: -45px;
  top: 10px;
  position: absolute;
}
@media (max-width: 768px) {
  .l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size a span.tel::before {
    width: 28px;
    top: 5px;
    left: -35px;
  }
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size a span.tel::after {
  content: none;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size a:hover {
  background-image: none;
  background-color: #ffffff;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size a:hover span.head {
  color: #00A960;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size a:hover span.tel {
  color: #00A960;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size > a {
  font-family: "Noto Sans JP", YuGothicM, YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", "sans-serif";
  position: relative;
  background: none;
  background-color: inherit;
  box-shadow: none;
  max-width: 600px;
  padding: 0;
  border: none;
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size > a {
    width: 100%;
    max-width: inherit;
  }
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size > a::after {
  content: "";
  display: block;
  width: 100%;
  max-width: 600px;
  height: 100%;
  border-radius: 600px;
  background: #EFF8F0;
  z-index: 5;
  position: absolute;
  top: 15px;
  left: 20px;
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size > a::after {
    left: 10px;
  }
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size > a > div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  z-index: 10;
  border: 1px solid #00A960;
  border-radius: 600px;
  padding: 1.8em 50px;
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size > a > div {
    flex-direction: column;
  }
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size > a > div .head {
  font-size: 16px;
  padding: 0 1em;
  display: inline-block;
  margin: 0 auto;
  background-color: #ffffff;
  text-align: center;
  position: absolute;
  top: -1em;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size > a > div .head::before {
  content: none;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size > a > div .text {
  text-align: left;
  color: #333;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size > a > div .text::before {
  content: none;
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size > a > div .text br {
    display: none;
  }
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size > a > div .tel {
  text-align: left;
  font-size: 36px;
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size > a > div .tel {
    font-size: 28px;
  }
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size > a > div .tel::before {
  background: url(../img/icon/icon-tel.png) center center no-repeat;
  background-size: contain;
  width: 38px;
  height: 38px;
  margin-top: -19px;
  left: -1.2em;
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size > a > div .tel::before {
    width: 28px;
  }
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size > a:hover {
  box-shadow: none;
  color: #00A960;
  background: none;
  background-color: #ffffff;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size > a:hover span::before {
  content: none;
}
.l-main #layout-shousai .contents-area .main-contents .write-area .button-area-l-size > a:hover .tel::before {
  content: "";
  background: url(../img/icon/icon-tel.png) center center no-repeat;
  background-size: contain;
}
.l-main #layout-shousai .contents-area .side-contents {
  width: 30%;
  max-width: 320px;
  /*.banner-area {
    ul{
      li{
        height: 160px;
        background: $bg_color;
        margin-bottom: 20px;
        a{
          figure {}
        }
      }
    }
  }*/
}
@media (max-width: 768px) {
  .l-main #layout-shousai .contents-area .side-contents {
    width: 100%;
    max-width: inherit;
    border-radius: 30px;
    background: #EFF3F5;
    padding: 20px 0 20px;
  }
}
.l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien {
  border: none;
  padding: 0;
}
@media (max-width: 768px) {
  .l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien {
    width: 100%;
    max-width: inherit;
    margin: 0 0 20px;
    background: #EFF3F5;
  }
}
.l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien h4 {
  margin-bottom: 10px;
  display: flex;
  font-weight: bold;
  font-size: 18px;
  padding-left: 30px;
}
@media (max-width: 768px) {
  .l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien h4 {
    font-size: 21px;
    padding-left: 65px;
    margin-left: 8px;
  }
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien h4 {
    padding-left: 50px;
  }
}
.l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien h4::after {
  content: none !important;
}
.l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien h4 .icon-green {
  width: 25px;
  height: 25px;
  position: absolute;
  left: 0;
}
@media (max-width: 768px) {
  .l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien h4 .icon-green {
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien h4 .icon-green {
    width: 40px;
    height: 40px;
  }
}
.l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien h4 .icon-green img {
  width: 18px;
}
@media (max-width: 768px) {
  .l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien h4 .icon-green img {
    width: 32px;
  }
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien h4 .icon-green img {
    width: 25px;
  }
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien h4 .text {
    font-size: 21px;
  }
}
.l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien > ul {
  width: 100%;
  margin-bottom: 50px;
  max-height: inherit;
  overflow: hidden;
  transition: inherit;
}
@media (max-width: 768px) {
  .l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien > ul {
    margin-bottom: 0;
    background: none;
  }
}
.l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien > ul li {
  border-bottom: 1px dotted #d6d6d6;
  background: #EFF3F5;
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien > ul li {
    background-color: none;
  }
}
.l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien > ul li:first-child {
  border-radius: 10px 10px 0 0;
}
@media (max-width: 768px) {
  .l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien > ul li:first-child {
    border-radius: 0;
  }
}
.l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien > ul li:last-child {
  border-radius: 0 0 10px 10px;
}
@media (max-width: 768px) {
  .l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien > ul li:last-child {
    border-radius: 0;
  }
}
.l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien > ul li a {
  display: block;
  padding: 0.5em 0.5em 0.5em 1.5em;
  position: relative;
}
.l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien > ul li a::before {
  content: none;
}
@media (max-width: 768px) {
  .l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien > ul li a::before {
    content: "＊";
    position: absolute;
    left: 0.5em;
    color: #00A960;
  }
}
.l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien > ul li a:after {
  content: none;
}
.l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien > ul li a.on, .l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien > ul li a:hover {
  padding: 0.5em 0.5em 0.5em 1.5em;
  color: #00A960;
  background: none;
  border-radius: inherit;
}
.l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien > ul li a.on::before, .l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien > ul li a:hover::before {
  content: ">";
  position: absolute;
  left: 0.5em;
  color: #00A960;
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien > ul li a.on::before, .l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien > ul li a:hover::before {
    content: "＊";
  }
}
.l-main #layout-shousai .contents-area .side-contents .side-navi .contents-shien > ul :last-child {
  border-bottom: none;
}
.l-main #layout-shousai .contents-area .side-contents {
  width: 30%;
  max-width: 320px;
  /*.banner-area {
    ul{
      li{
        height: 160px;
        background: $bg_color;
        margin-bottom: 20px;
        a{
          figure {}
        }
      }
    }
  }*/
}
@media (max-width: 768px) {
  .l-main #layout-shousai .contents-area .side-contents {
    width: 100%;
    max-width: inherit;
  }
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .side-contents .side-navi {
    border-radius: 20px;
    background: #EFF3F5;
    padding: 15px 8px;
    border-radius: 30px;
  }
}
.l-main #layout-shousai .contents-area .side-contents .side-navi h4 {
  margin-bottom: 10px;
  display: flex;
  font-weight: bold;
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .side-contents .side-navi h4 {
    display: grid;
    grid-template-columns: 45px auto;
    grid-template-rows: 1fr;
  }
}
.l-main #layout-shousai .contents-area .side-contents .side-navi h4 .icon-green {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .side-contents .side-navi h4 .icon-green {
    width: 40px;
    height: 40px;
  }
}
.l-main #layout-shousai .contents-area .side-contents .side-navi h4 .icon-green img {
  width: 20px;
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .side-contents .side-navi h4 .icon-green img {
    width: 25px;
  }
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .side-contents .side-navi h4 .text {
    font-size: 21px;
  }
}
.l-main #layout-shousai .contents-area .side-contents .side-navi > ul {
  width: 100%;
  margin-bottom: 50px;
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .side-contents .side-navi > ul {
    margin-bottom: 0;
  }
}
.l-main #layout-shousai .contents-area .side-contents .side-navi > ul li {
  border-bottom: 1px dotted #d6d6d6;
  background: #EFF3F5;
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .side-contents .side-navi > ul li {
    background-color: none;
  }
}
.l-main #layout-shousai .contents-area .side-contents .side-navi > ul li:first-child {
  border-radius: 10px 10px 0 0;
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .side-contents .side-navi > ul li:first-child {
    border-radius: 0;
  }
}
.l-main #layout-shousai .contents-area .side-contents .side-navi > ul li:last-child {
  border-radius: 0 0 10px 10px;
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .side-contents .side-navi > ul li:last-child {
    border-radius: 0;
  }
}
.l-main #layout-shousai .contents-area .side-contents .side-navi > ul li a {
  display: block;
  padding: 0.5em 0.5em 0.5em 1.5em;
  position: relative;
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .side-contents .side-navi > ul li a::before {
    content: "＊";
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    color: #00A960;
  }
}
.l-main #layout-shousai .contents-area .side-contents .side-navi > ul li a.on, .l-main #layout-shousai .contents-area .side-contents .side-navi > ul li a:hover {
  padding: 0.5em 0.5em 0.5em 1.5em;
  color: #00A960;
}
.l-main #layout-shousai .contents-area .side-contents .side-navi > ul li a.on::before, .l-main #layout-shousai .contents-area .side-contents .side-navi > ul li a:hover::before {
  content: ">";
  position: absolute;
  top: 0.5em;
  left: 0.5em;
}
@media (max-width: 640px) {
  .l-main #layout-shousai .contents-area .side-contents .side-navi > ul li a.on::before, .l-main #layout-shousai .contents-area .side-contents .side-navi > ul li a:hover::before {
    content: "＊";
  }
}
.l-main #layout-shousai .contents-area .side-contents .side-navi > ul :last-child {
  border-bottom: none;
}
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-management .menu-seminar,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-management .menu-collaboration,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-management .menu-subsidy,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-management .menu-loan,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-management .menu-upbringing,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-management .menu-welfare,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-management .menu-training,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-management .menu-founding {
  display: none;
}
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-seminar .menu-management,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-seminar .menu-collaboration,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-seminar .menu-subsidy,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-seminar .menu-loan,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-seminar .menu-upbringing,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-seminar .menu-welfare,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-seminar .menu-training,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-seminar .menu-founding,
.l-main #layout-shousai .contents-area .side-contents .side-navi.seminar-1 .menu-management,
.l-main #layout-shousai .contents-area .side-contents .side-navi.seminar-1 .menu-collaboration,
.l-main #layout-shousai .contents-area .side-contents .side-navi.seminar-1 .menu-subsidy,
.l-main #layout-shousai .contents-area .side-contents .side-navi.seminar-1 .menu-loan,
.l-main #layout-shousai .contents-area .side-contents .side-navi.seminar-1 .menu-upbringing,
.l-main #layout-shousai .contents-area .side-contents .side-navi.seminar-1 .menu-welfare,
.l-main #layout-shousai .contents-area .side-contents .side-navi.seminar-1 .menu-training,
.l-main #layout-shousai .contents-area .side-contents .side-navi.seminar-1 .menu-founding,
.l-main #layout-shousai .contents-area .side-contents .side-navi.seminar-2 .menu-management,
.l-main #layout-shousai .contents-area .side-contents .side-navi.seminar-2 .menu-collaboration,
.l-main #layout-shousai .contents-area .side-contents .side-navi.seminar-2 .menu-subsidy,
.l-main #layout-shousai .contents-area .side-contents .side-navi.seminar-2 .menu-loan,
.l-main #layout-shousai .contents-area .side-contents .side-navi.seminar-2 .menu-upbringing,
.l-main #layout-shousai .contents-area .side-contents .side-navi.seminar-2 .menu-welfare,
.l-main #layout-shousai .contents-area .side-contents .side-navi.seminar-2 .menu-training,
.l-main #layout-shousai .contents-area .side-contents .side-navi.seminar-2 .menu-founding,
.l-main #layout-shousai .contents-area .side-contents .side-navi.seminar-3 .menu-management,
.l-main #layout-shousai .contents-area .side-contents .side-navi.seminar-3 .menu-collaboration,
.l-main #layout-shousai .contents-area .side-contents .side-navi.seminar-3 .menu-subsidy,
.l-main #layout-shousai .contents-area .side-contents .side-navi.seminar-3 .menu-loan,
.l-main #layout-shousai .contents-area .side-contents .side-navi.seminar-3 .menu-upbringing,
.l-main #layout-shousai .contents-area .side-contents .side-navi.seminar-3 .menu-welfare,
.l-main #layout-shousai .contents-area .side-contents .side-navi.seminar-3 .menu-training,
.l-main #layout-shousai .contents-area .side-contents .side-navi.seminar-3 .menu-founding {
  display: none;
}
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-collaboration .menu-seminar,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-collaboration .menu-management,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-collaboration .menu-subsidy,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-collaboration .menu-loan,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-collaboration .menu-upbringing,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-collaboration .menu-welfare,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-collaboration .menu-training,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-collaboration .menu-founding {
  display: none;
}
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-subsidy .menu-seminar,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-subsidy .menu-collaboration,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-subsidy .menu-management,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-subsidy .menu-loan,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-subsidy .menu-upbringing,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-subsidy .menu-welfare,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-subsidy .menu-training,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-subsidy .menu-founding {
  display: none;
}
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-loan .menu-seminar,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-loan .menu-collaboration,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-loan .menu-subsidy,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-loan .menu-management,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-loan .menu-upbringing,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-loan .menu-welfare,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-loan .menu-training,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-loan .menu-founding {
  display: none;
}
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-upbringing .menu-seminar,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-upbringing .menu-collaboration,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-upbringing .menu-subsidy,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-upbringing .menu-loan,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-upbringing .menu-management,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-upbringing .menu-welfare,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-upbringing .menu-training,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-upbringing .menu-founding {
  display: none;
}
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-welfare .menu-seminar,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-welfare .menu-collaboration,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-welfare .menu-subsidy,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-welfare .menu-loan,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-welfare .menu-upbringing,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-welfare .menu-management,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-welfare .menu-training,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-welfare .menu-founding {
  display: none;
}
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-training .menu-seminar,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-training .menu-collaboration,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-training .menu-subsidy,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-training .menu-loan,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-training .menu-upbringing,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-training .menu-welfare,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-training .menu-management,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-training .menu-founding {
  display: none;
}
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-founding .menu-seminar,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-founding .menu-collaboration,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-founding .menu-subsidy,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-founding .menu-loan,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-founding .menu-upbringing,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-founding .menu-welfare,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-founding .menu-management,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-founding .menu-training {
  display: none;
}
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-example .menu-seminar,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-example .menu-collaboration,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-example .menu-loan,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-example .menu-upbringing,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-example .menu-training,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-example .menu-welfare,
.l-main #layout-shousai .contents-area .side-contents .side-navi.cats-example .menu-founding {
  display: none;
}
.l-main #layout-shousai.uncategorized .side-contents,
.l-main #layout-shousai.uncategorized .sub-top time {
  display: none;
}
.l-main #layout-shousai.uncategorized .sub-top,
.l-main #layout-shousai.uncategorized .main-contents {
  width: 100%;
  max-width: inherit;
}
.l-main #layout-about .mv-area .mv-slider {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 50px;
}
@media (max-width: 768px) {
  .l-main #layout-about .mv-area .mv-slider {
    width: 96%;
  }
}
.l-main #layout-about .mv-area .mv-slider .slick-dots {
  bottom: 20px;
}
.l-main #layout-about .mv-area .mv-slider .slick-dots li button::before {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  background: #00A960;
  border: 1px solid #00A960;
  border-radius: 300px;
  opacity: 1;
}
.l-main #layout-about .mv-area .mv-slider .slick-dots li.slick-active button::before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 2px solid #00A960;
  border-radius: 300px;
}
.l-main #layout-about .mv-area .mv-slider img {
  border-radius: 0 0 50px 50px;
}
@media (max-width: 768px) {
  .l-main #layout-about .mv-area .mv-slider img {
    border-radius: 0 0 30px 30px;
  }
}
@media (max-width: 640px) {
  .l-main #layout-about .mv-area .mv-slider img {
    border-radius: 0 0 20px 20px;
  }
}
.l-main #layout-about .no1 .inner {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 100px;
  position: relative;
}
@media (max-width: 768px) {
  .l-main #layout-about .no1 .inner {
    width: 96%;
  }
}
.l-main #layout-about .no1 .inner .sub-top {
  display: flex;
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  .l-main #layout-about .no1 .inner .sub-top {
    flex-direction: column;
  }
}
.l-main #layout-about .no1 .inner .sub-top h3 {
  font-size: 18px;
  line-height: 1.2;
}
@media (max-width: 768px) {
  .l-main #layout-about .no1 .inner .sub-top h3 {
    margin-bottom: 20px;
  }
}
@media (max-width: 640px) {
  .l-main #layout-about .no1 .inner .sub-top h3 {
    margin-bottom: 40px;
  }
}
.l-main #layout-about .no1 .inner .sub-top h3 span {
  color: #00A960;
  font-size: 36px;
  font-weight: bold;
  padding-right: 20px;
  display: flex;
  transform: translateY(-7px);
  -webkit-transform: translateY(-7px);
  -ms-transform: translateY(-7px);
}
@media (max-width: 640px) {
  .l-main #layout-about .no1 .inner .sub-top h3 span {
    font-size: 28px;
  }
}
.l-main #layout-about .no1 .inner .sub-top h3 span::after {
  content: "";
  display: block;
  width: 1em;
  height: 1em;
  border-right: 1px solid #d6d6d6;
  transform: translateY(7px);
  -webkit-transform: translateY(7px);
  -ms-transform: translateY(7px);
}
@media (max-width: 768px) {
  .l-main #layout-about .no1 .inner .sub-top h3 span::after {
    display: none;
  }
}
.l-main #layout-about .no1 .inner .sub-top p {
  display: inline-block;
  padding-left: 20px;
  line-height: 1.2;
}
@media (max-width: 768px) {
  .l-main #layout-about .no1 .inner .sub-top p {
    padding-left: 0;
    font-weight: bold;
  }
  .l-main #layout-about .no1 .inner .sub-top p br {
    display: none;
  }
}
.l-main #layout-about .no1 .inner .info-contents {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.l-main #layout-about .no1 .inner .info-contents li {
  width: 48%;
  padding-bottom: 10px;
  border-bottom: 1px dotted #d6d6d6;
  margin-bottom: 20px;
}
@media (max-width: 640px) {
  .l-main #layout-about .no1 .inner .info-contents li {
    width: 100%;
  }
}
.l-main #layout-about .no1 .inner .info-contents li a {
  display: block;
}
.l-main #layout-about .no1 .inner .info-contents li a .box .date {
  margin-bottom: 10px;
  display: block;
}
.l-main #layout-about .no1 .inner .btn-all {
  position: absolute;
  top: 10px;
  right: 0;
}
@media (max-width: 768px) {
  .l-main #layout-about .no1 .inner .btn-all {
    border-left: 1px solid #d6d6d6;
    padding-left: 10px;
  }
}
.l-main #layout-about .no1 .inner .btn-all a {
  font-weight: bold;
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .l-main #layout-about .no1 .inner .btn-all a {
    flex-direction: column-reverse;
    justify-content: center;
  }
}
.l-main #layout-about .no1 .inner .btn-all a span.arrow-cirlue.green {
  border: 1px solid #EFF8F0;
  margin-left: 20px;
}
@media (max-width: 768px) {
  .l-main #layout-about .no1 .inner .btn-all a span.arrow-cirlue.green {
    margin-left: 0;
    margin-bottom: 5px;
  }
}
.l-main #layout-about .pdf-area {
  margin-bottom: 150px;
}
.l-main #layout-about .pdf-area .inner {
  padding: 50px 0;
  background: #EFF3F5;
}
.l-main #layout-about .pdf-area .inner > ul {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 768px) {
  .l-main #layout-about .pdf-area .inner > ul {
    width: 96%;
    flex-direction: column;
  }
}
.l-main #layout-about .pdf-area .inner > ul > li {
  background: #ffffff;
  border-radius: 30px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  width: 48%;
}
@media (max-width: 768px) {
  .l-main #layout-about .pdf-area .inner > ul > li {
    width: 100%;
    max-width: 540px;
    margin-bottom: 50px;
  }
}
.l-main #layout-about .pdf-area .inner > ul > li:last-child {
  margin-bottom: 0;
}
.l-main #layout-about .pdf-area .inner > ul > li .contents {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 768px) {
  .l-main #layout-about .pdf-area .inner > ul > li .contents {
    justify-content: space-around;
  }
}
@media (max-width: 640px) {
  .l-main #layout-about .pdf-area .inner > ul > li .contents {
    justify-content: center;
    flex-direction: column;
  }
}
.l-main #layout-about .pdf-area .inner > ul > li .contents .photo-box {
  width: 25%;
  max-width: 110px;
}
@media (max-width: 640px) {
  .l-main #layout-about .pdf-area .inner > ul > li .contents .photo-box {
    width: 100%;
    max-width: inherit;
    margin-bottom: 20px;
  }
}
.l-main #layout-about .pdf-area .inner > ul > li .contents .photo-box a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 640px) {
  .l-main #layout-about .pdf-area .inner > ul > li .contents .photo-box a {
    flex-direction: row;
  }
}
.l-main #layout-about .pdf-area .inner > ul > li .contents .photo-box a figure img {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.l-main #layout-about .pdf-area .inner > ul > li .contents .photo-box a p {
  padding: 0.2em 1em;
  text-align: center;
  border-radius: 100px;
  font-size: 0.8em;
  letter-spacing: -0.1em;
}
@media (max-width: 640px) {
  .l-main #layout-about .pdf-area .inner > ul > li .contents .photo-box a p {
    font-size: 1em;
    padding: 1em;
    height: auto;
  }
}
.l-main #layout-about .pdf-area .inner > ul > li .contents .text-box {
  width: 73%;
  max-width: 300px;
  min-height: 272px;
  border-radius: 30px;
}
@media (max-width: 640px) {
  .l-main #layout-about .pdf-area .inner > ul > li .contents .text-box {
    width: 100%;
    max-width: inherit;
    min-height: inherit;
  }
}
.l-main #layout-about .pdf-area .inner > ul > li .contents .text-box h4 {
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 21px;
}
.l-main #layout-about .pdf-area .inner > ul > li .contents .text-box p {
  margin-bottom: 20px;
}
.l-main #layout-about .pdf-area .inner > ul > li .contents .text-box .icon-word {
  margin-bottom: 0;
}
.l-main #layout-about .pdf-area .inner > ul > li .contents .text-box .icon-word a {
  display: flex;
  font-size: 0.9em;
  align-items: center;
}
@media (max-width: 640px) {
  .l-main #layout-about .pdf-area .inner > ul > li .contents .text-box .icon-word a {
    font-size: 1.2em;
  }
}
.l-main #layout-about .pdf-area .inner > ul > li .contents .text-box .icon-word a span.text {
  font-weight: bold;
  font-size: 0.8em;
}
.l-main #layout-about .pdf-area .inner > ul > li .contents .text-box .icon-word a span.arrow-cirlue {
  margin-left: 1em;
  width: 20px;
  height: 20px;
}
.l-main #layout-about .pdf-area .inner > ul li.news .contents .photo-box a p {
  color: #00A960;
  background: #EFF8F0;
}
.l-main #layout-about .pdf-area .inner > ul li.news .contents .photo-box a:hover p {
  color: #ffffff;
  background: #8ECE9A;
}
.l-main #layout-about .pdf-area .inner > ul li.news .contents .text-box {
  padding: 1.5em 1em;
  background: #EFF8F0;
}
.l-main #layout-about .pdf-area .inner > ul li.news .contents .text-box h4 {
  color: #00A960;
}
.l-main #layout-about .pdf-area .inner > ul li.news .contents .text-box .icon-word a {
  justify-content: end;
}
.l-main #layout-about .pdf-area .inner > ul li.news .contents .text-box .icon-word a span.arrow-cirlue {
  border: 1px solid #00A960;
}
.l-main #layout-about .pdf-area .inner > ul li.news .contents .text-box .icon-word a:hover {
  color: #00A960;
}
.l-main #layout-about .pdf-area .inner > ul li.news .contents .text-box .icon-word a:hover span.arrow-cirlue {
  background-color: #00A960;
}
.l-main #layout-about .pdf-area .inner > ul li.news .contents .text-box .icon-word a:hover span.arrow-cirlue path {
  fill: #ffffff;
}
.l-main #layout-about .pdf-area .inner > ul li.life .contents .photo-box a p {
  background: #ECF3F8;
  line-height: 1.5;
  color: #3170B9;
}
.l-main #layout-about .pdf-area .inner > ul li.life .contents .photo-box a:hover p {
  color: #ffffff;
  background: #3170B9;
}
.l-main #layout-about .pdf-area .inner > ul li.life .contents .text-box {
  padding: 1em;
  background: #ECF3F8;
}
.l-main #layout-about .pdf-area .inner > ul li.life .contents .text-box .log-in-switch {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}
@media (max-width: 640px) {
  .l-main #layout-about .pdf-area .inner > ul li.life .contents .text-box .log-in-switch {
    flex-direction: column;
  }
}
.l-main #layout-about .pdf-area .inner > ul li.life .contents .text-box .log-in-switch .icon-word {
  letter-spacing: -0.1em;
}
@media (max-width: 640px) {
  .l-main #layout-about .pdf-area .inner > ul li.life .contents .text-box .log-in-switch .icon-word {
    margin-bottom: 20px;
  }
}
@media (max-width: 640px) {
  .l-main #layout-about .pdf-area .inner > ul li.life .contents .text-box .log-in-switch .icon-word a {
    justify-content: end;
  }
}
@media (max-width: 640px) {
  .l-main #layout-about .pdf-area .inner > ul li.life .contents .text-box .log-in-switch .icon-word a span.text br {
    display: none;
  }
}
.l-main #layout-about .pdf-area .inner > ul li.life .contents .text-box .log-in-switch .icon-word a span.arrow-cirlue {
  border: 1px solid #3170B9;
}
.l-main #layout-about .pdf-area .inner > ul li.life .contents .text-box .log-in-switch .icon-word a span.arrow-cirlue path {
  fill: #3170B9;
}
.l-main #layout-about .pdf-area .inner > ul li.life .contents .text-box .log-in-switch .icon-word a:hover {
  color: #3170B9;
}
.l-main #layout-about .pdf-area .inner > ul li.life .contents .text-box .log-in-switch .icon-word a:hover span.arrow-cirlue {
  background: #3170B9;
}
.l-main #layout-about .pdf-area .inner > ul li.life .contents .text-box .log-in-switch .icon-word a:hover span.arrow-cirlue path {
  fill: #ffffff;
}
.l-main #layout-about .no3 .inner {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 50px;
  border-radius: 50px;
  background: #EFF3F5;
  padding: 0 50px 50px;
}
@media (max-width: 768px) {
  .l-main #layout-about .no3 .inner {
    border-radius: 0;
    padding: 0 2% 50px;
  }
}
.l-main #layout-about .no3 .inner .type-a {
  margin-bottom: 30px;
  width: 480px;
}
@media (max-width: 768px) {
  .l-main #layout-about .no3 .inner .type-a {
    width: 400px;
  }
}
@media (max-width: 640px) {
  .l-main #layout-about .no3 .inner .type-a {
    width: 300px;
  }
}
.l-main #layout-about .no3 .inner .type-a br {
  display: none;
}
@media (max-width: 768px) {
  .l-main #layout-about .no3 .inner .type-a br {
    display: block;
  }
}
.l-main #layout-about .no3 .inner .type-a::before {
  width: 480px;
  border-radius: 300px/100px;
}
@media (max-width: 768px) {
  .l-main #layout-about .no3 .inner .type-a::before {
    width: 400px;
  }
}
@media (max-width: 640px) {
  .l-main #layout-about .no3 .inner .type-a::before {
    width: 300px;
  }
}
.l-main #layout-about .no3 .inner p {
  margin-bottom: 20px;
  position: relative;
}
.l-main #layout-about .no3 .inner ul {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .l-main #layout-about .no3 .inner ul {
    flex-direction: column;
    margin-bottom: 0;
  }
}
.l-main #layout-about .no3 .inner ul li {
  width: 48%;
  position: relative;
  margin-bottom: 20px;
  font-weight: bold;
}
@media (max-width: 768px) {
  .l-main #layout-about .no3 .inner ul li {
    width: 100%;
  }
}
@media (max-width: 640px) {
  .l-main #layout-about .no3 .inner ul li {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .l-main #layout-about .no3 .inner ul li:last-child {
    margin-bottom: 50px;
  }
}
.l-main #layout-about .no3 .inner ul li div {
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 100px;
  height: 70px;
}
.l-main #layout-about .no3 .inner ul li div .ex1 {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  background: #d6d6d6;
  color: #ffffff;
  border-radius: 300px;
  position: absolute;
  top: 10px;
  left: 10px;
}
@media (max-width: 640px) {
  .l-main #layout-about .no3 .inner ul li div .text {
    line-height: 1.5;
  }
}
.l-main #layout-about .no3 .inner ul li div .text .ex2 {
  color: #00A960;
}
.l-main #layout-about .no3 .inner ul li div .text br {
  display: none;
}
@media (max-width: 640px) {
  .l-main #layout-about .no3 .inner ul li div .text br {
    display: block;
  }
}
.l-main #layout-about .banner-area ul {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .l-main #layout-about .banner-area ul {
    width: 96%;
  }
}
.l-main #layout-about .banner-area ul li {
  width: 32%;
  margin: 0 0.5% 20px;
}
@media (max-width: 768px) {
  .l-main #layout-about .banner-area ul li {
    width: 49%;
  }
}
@media (max-width: 640px) {
  .l-main #layout-about .banner-area ul li {
    width: 100%;
    max-width: 320px;
    margin: 0 auto 20px;
    text-align: center;
  }
}
.l-main #layout-about .login-form.on {
  display: block;
}
.l-main #layout-about .login-form {
  display: none;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  padding: 30px 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9910;
}
.l-main #layout-about .login-form .inner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 960px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 80px;
  padding-bottom: 20px;
}
@media (max-width: 1000px) {
  .l-main #layout-about .login-form .inner {
    width: 750px;
  }
}
@media (max-width: 768px) {
  .l-main #layout-about .login-form .inner {
    width: 560px;
    scrollbar-width: none;
    border-radius: 50px;
  }
}
@media (max-width: 640px) {
  .l-main #layout-about .login-form .inner {
    width: 340px;
  }
}
.l-main #layout-about .login-form .inner .number-btn {
  display: none;
}
@media (max-width: 768px) {
  .l-main #layout-about .login-form .inner .number-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #ffffff;
    width: 1.5em;
    height: 1.5em;
    font-size: 18px;
    font-weight: bold;
    background-color: #F79428;
    border-radius: 300px;
  }
  .l-main #layout-about .login-form .inner .number-btn.p-1 {
    position: absolute;
    top: 110px;
    right: 80px;
  }
}
@media (max-width: 768px) and (max-width: 640px) {
  .l-main #layout-about .login-form .inner .number-btn.p-1 {
    top: 103px;
    right: 20px;
  }
}
@media (max-width: 768px) {
  .l-main #layout-about .login-form .inner .number-btn.p-1::after {
    content: "";
    display: block;
    border: 10px solid transparent;
    border-top: 10px solid #F79428;
    position: absolute;
    top: 50%;
    transform: translateY(-25%);
    left: 1.5em;
  }
}
@media (max-width: 768px) {
  .l-main #layout-about .login-form .inner .number-btn.p-2 {
    position: absolute;
    top: 205px;
    left: 70px;
  }
}
@media (max-width: 768px) and (max-width: 640px) {
  .l-main #layout-about .login-form .inner .number-btn.p-2 {
    top: 190px;
    left: 28px;
  }
}
@media (max-width: 768px) {
  .l-main #layout-about .login-form .inner .number-btn.p-2::after {
    content: "";
    display: block;
    border: 10px solid transparent;
    border-top: 10px solid #F79428;
    position: absolute;
    top: 50%;
    transform: translateY(-25%);
    left: -1.5em;
  }
}
@media (max-width: 768px) {
  .l-main #layout-about .login-form .inner .number-btn.p-3 {
    position: absolute;
    bottom: 102px;
    right: 83px;
  }
}
@media (max-width: 768px) and (max-width: 640px) {
  .l-main #layout-about .login-form .inner .number-btn.p-3 {
    bottom: 127px;
    right: 20px;
  }
}
@media (max-width: 768px) {
  .l-main #layout-about .login-form .inner .number-btn.p-3::after {
    content: "";
    display: block;
    border: 10px solid transparent;
    border-top: 10px solid #F79428;
    position: absolute;
    top: 50%;
    transform: translateY(-25%);
    left: 1.5em;
  }
}
.l-main #layout-about .login-form .inner .sub-top {
  padding-top: 20px;
  margin-bottom: 20px;
}
.l-main #layout-about .login-form .inner .sub-top h3 {
  font-size: 21px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .l-main #layout-about .login-form .inner .sub-top h3 {
    font-size: 18px;
  }
}
.l-main #layout-about .login-form .inner .sub-top p {
  margin: 0 auto;
  text-align: center;
}
@media (max-width: 768px) {
  .l-main #layout-about .login-form .inner .sub-top p {
    padding: 0 10px;
    font-size: 0.8em;
  }
}
.l-main #layout-about .login-form .inner .sub-top p span {
  color: #F79428;
}
.l-main #layout-about .login-form .inner .comment-position {
  position: relative;
}
@media (max-width: 768px) {
  .l-main #layout-about .login-form .inner .comment-position {
    height: 430px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 96%;
    margin: 0 auto;
  }
}
.l-main #layout-about .login-form .inner .comment-position .form-area {
  width: 380px;
  margin: 0 auto;
  padding: 20px 15px;
  background: #ffffff;
  border-radius: 30px;
  border: 10px solid #ECF3F8;
}
@media (max-width: 768px) {
  .l-main #layout-about .login-form .inner .comment-position .form-area {
    margin-bottom: 50px;
    border: 5px solid #ECF3F8;
  }
}
@media (max-width: 640px) {
  .l-main #layout-about .login-form .inner .comment-position .form-area {
    width: 96%;
  }
}
.l-main #layout-about .login-form .inner .comment-position .form-area h4 {
  color: #00A960;
  font-size: 32px;
  text-align: center;
  font-weight: bold;
  line-height: 0.8;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .l-main #layout-about .login-form .inner .comment-position .form-area h4 {
    font-size: 28px;
  }
}
.l-main #layout-about .login-form .inner .comment-position .form-area h4 span {
  font-size: 14px;
}
@media (max-width: 768px) {
  .l-main #layout-about .login-form .inner .comment-position .form-area h4 span {
    font-size: 12px;
  }
}
.l-main #layout-about .login-form .inner .comment-position .form-area .form-contents {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .l-main #layout-about .login-form .inner .comment-position .form-area .form-contents p {
    font-size: 0.9em;
  }
}
.l-main #layout-about .login-form .inner .comment-position .form-area .form-contents p.check-modoki {
  width: 100%;
  display: flex;
  align-items: center;
}
.l-main #layout-about .login-form .inner .comment-position .form-area .form-contents p.check-modoki::before {
  content: "";
  width: 15px;
  height: 15px;
  display: block;
  background: #ffffff;
  border: 1px solid #d6d6d6;
  margin-right: 10px;
  border-radius: 3px;
}
.l-main #layout-about .login-form .inner .comment-position .form-area .form-contents .sample-area {
  width: 100%;
  background: #ffffff;
  padding: 0.2em 0.5em;
  border: 1px solid #d6d6d6;
}
.l-main #layout-about .login-form .inner .comment-position .form-area .form-contents a {
  width: 100%;
  display: block;
  text-align: right;
}
.l-main #layout-about .login-form .inner .comment-position .form-area .form-contents .box {
  display: flex;
}
.l-main #layout-about .login-form .inner .comment-position .form-area .form-contents .box input {
  width: 1em;
  margin-right: 10px;
}
.l-main #layout-about .login-form .inner .comment-position .form-area .ex3 {
  margin-bottom: 10px;
}
.l-main #layout-about .login-form .inner .comment-position .form-area .ex3 .btn-modoki {
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  padding: 1em;
  border-radius: 300px;
  background: url(../img/icon/icon-arrow-r.png) right 10px center no-repeat;
  background-color: #00A960;
  border: 1px solid #00A960;
}
@media (max-width: 768px) {
  .l-main #layout-about .login-form .inner .comment-position .form-area .ex3 .btn-modoki {
    padding: 0.3em;
    background: url(../img/icon/icon-arrow-r.png) right 20px center no-repeat;
    background-color: #00A960;
    background-size: 18px;
  }
}
.l-main #layout-about .login-form .inner .comment-position .form-area ul li {
  display: flex;
  align-items: center;
}
.l-main #layout-about .login-form .inner .comment-position .form-area ul li .arrow-cirlue {
  width: 20px;
  height: 20px;
  border: 1px solid #00A960;
  margin-right: 0.5em;
}
.l-main #layout-about .login-form .inner .comment-position .comment-box {
  position: absolute;
  padding: 1em;
  width: 250px;
  border: 2px solid #F79428;
  border-radius: 20px;
  background: #ffffff;
  font-size: 0.8em;
}
@media (max-width: 768px) {
  .l-main #layout-about .login-form .inner .comment-position .comment-box {
    position: static;
    width: 300px !important;
    margin: 0 auto 20px;
  }
}
@media (max-width: 768px) {
  .l-main #layout-about .login-form .inner .comment-position .comment-box p {
    position: relative;
    padding-top: 1.5em;
  }
}
.l-main #layout-about .login-form .inner .comment-position .comment-box p .ex1 {
  color: #F79428;
}
@media (max-width: 768px) {
  .l-main #layout-about .login-form .inner .comment-position .comment-box p .ex1 {
    font-size: 1.5em;
    position: absolute;
    top: -10px;
    left: 0;
  }
}
.l-main #layout-about .login-form .inner .comment-position .comment-box p .ex2 {
  font-weight: bold;
}
.l-main #layout-about .login-form .inner .comment-position .comment-box p .ex2 span {
  color: #F79428;
}
.l-main #layout-about .login-form .inner .comment-position .comment-box.part1 {
  top: 75px;
  right: 20px;
}
@media (max-width: 1000px) {
  .l-main #layout-about .login-form .inner .comment-position .comment-box.part1 {
    width: 180px;
    right: 10px;
    top: 80px;
  }
}
.l-main #layout-about .login-form .inner .comment-position .comment-box.part1::before {
  content: "";
  display: block;
  border: 15px solid transparent;
  border-right: 50px solid #F79428;
  position: absolute;
  left: -65px;
  top: 40px;
}
@media (max-width: 1000px) {
  .l-main #layout-about .login-form .inner .comment-position .comment-box.part1::before {
    border: 15px solid transparent;
    border-right: 25px solid #F79428;
    position: absolute;
    left: -40px;
    top: 40px;
  }
}
@media (max-width: 768px) {
  .l-main #layout-about .login-form .inner .comment-position .comment-box.part1::before {
    content: none;
  }
}
.l-main #layout-about .login-form .inner .comment-position .comment-box.part1::after {
  content: "";
  display: block;
  border: 15px solid transparent;
  border-right: 50px solid #ffffff;
  position: absolute;
  left: -58px;
  top: 40px;
}
@media (max-width: 1000px) {
  .l-main #layout-about .login-form .inner .comment-position .comment-box.part1::after {
    border: 15px solid transparent;
    border-right: 25px solid #ffffff;
    position: absolute;
    left: -36px;
    top: 40px;
  }
}
@media (max-width: 768px) {
  .l-main #layout-about .login-form .inner .comment-position .comment-box.part1::after {
    content: none;
  }
}
.l-main #layout-about .login-form .inner .comment-position .comment-box.part2 {
  top: 190px;
  left: 20px;
}
@media (max-width: 1000px) {
  .l-main #layout-about .login-form .inner .comment-position .comment-box.part2 {
    width: 180px;
    left: 10px;
  }
}
.l-main #layout-about .login-form .inner .comment-position .comment-box.part2::before {
  content: "";
  display: block;
  border: 15px solid transparent;
  border-left: 50px solid #F79428;
  position: absolute;
  right: -65px;
  top: 30px;
}
@media (max-width: 1000px) {
  .l-main #layout-about .login-form .inner .comment-position .comment-box.part2::before {
    border: 15px solid transparent;
    border-left: 25px solid #F79428;
    position: absolute;
    right: -40px;
    top: 30px;
  }
}
@media (max-width: 768px) {
  .l-main #layout-about .login-form .inner .comment-position .comment-box.part2::before {
    content: none;
  }
}
.l-main #layout-about .login-form .inner .comment-position .comment-box.part2::after {
  content: "";
  display: block;
  border: 15px solid transparent;
  border-left: 50px solid #ffffff;
  position: absolute;
  right: -57px;
  top: 30px;
}
@media (max-width: 1000px) {
  .l-main #layout-about .login-form .inner .comment-position .comment-box.part2::after {
    border: 15px solid transparent;
    border-left: 25px solid #ffffff;
    position: absolute;
    right: -37px;
    top: 30px;
  }
}
@media (max-width: 768px) {
  .l-main #layout-about .login-form .inner .comment-position .comment-box.part2::after {
    content: none;
  }
}
.l-main #layout-about .login-form .inner .comment-position .comment-box.part3 {
  top: 315px;
  right: 20px;
}
@media (max-width: 1000px) {
  .l-main #layout-about .login-form .inner .comment-position .comment-box.part3 {
    top: 410px;
    right: 110px;
    width: 200px;
  }
}
.l-main #layout-about .login-form .inner .comment-position .comment-box.part3::before {
  content: "";
  display: block;
  border: 15px solid transparent;
  border-right: 50px solid #F79428;
  position: absolute;
  left: -65px;
  top: 20px;
}
@media (max-width: 1000px) {
  .l-main #layout-about .login-form .inner .comment-position .comment-box.part3::before {
    border: 15px solid transparent;
    border-bottom: 25px solid #F79428;
    top: -40px;
    left: 30px;
  }
}
@media (max-width: 768px) {
  .l-main #layout-about .login-form .inner .comment-position .comment-box.part3::before {
    content: none;
  }
}
.l-main #layout-about .login-form .inner .comment-position .comment-box.part3::after {
  content: "";
  display: block;
  border: 15px solid transparent;
  border-right: 50px solid #ffffff;
  position: absolute;
  top: 20px;
  left: -58px;
}
@media (max-width: 1000px) {
  .l-main #layout-about .login-form .inner .comment-position .comment-box.part3::after {
    border: 15px solid transparent;
    border-bottom: 25px solid #ffffff;
    position: absolute;
    top: -37px;
    left: 30px;
  }
}
@media (max-width: 768px) {
  .l-main #layout-about .login-form .inner .comment-position .comment-box.part3::after {
    content: none;
  }
}
.l-main #layout-menu .mv-area {
  margin-bottom: 60px;
}
@media (max-width: 640px) {
  .l-main #layout-menu .mv-area {
    margin-bottom: 40px;
  }
}
.l-main #layout-menu .no1 {
  margin-bottom: 50px;
}
.l-main #layout-menu .no1 .inner {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .l-main #layout-menu .no1 .inner {
    width: 96%;
  }
}
.l-main #layout-menu .no1 .inner > ul > li {
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
  border-bottom: 1px solid #00A960;
}
@media (max-width: 640px) {
  .l-main #layout-menu .no1 .inner > ul > li {
    flex-direction: column;
    padding: 0 0 50px;
    margin-bottom: 50px;
  }
}
.l-main #layout-menu .no1 .inner > ul > li:nth-child(even) {
  flex-direction: row-reverse;
}
@media (max-width: 640px) {
  .l-main #layout-menu .no1 .inner > ul > li:nth-child(even) {
    flex-direction: column;
  }
}
.l-main #layout-menu .no1 .inner > ul > li .photo-box {
  width: 48%;
}
@media (max-width: 640px) {
  .l-main #layout-menu .no1 .inner > ul > li .photo-box {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
}
.l-main #layout-menu .no1 .inner > ul > li .text-box {
  width: 48%;
}
@media (max-width: 640px) {
  .l-main #layout-menu .no1 .inner > ul > li .text-box {
    width: 100%;
  }
}
.l-main #layout-menu .no1 .inner > ul > li .text-box h3 {
  color: #00A960;
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 20px;
}
.l-main #layout-menu .no1 .inner > ul > li .text-box ul {
  padding-left: 1em;
  margin-bottom: 20px;
}
.l-main #layout-menu .no1 .inner > ul > li .text-box ul li {
  text-indent: -1em;
  padding-left: 1em;
  margin-bottom: 10px;
}
.l-main #layout-menu .no1 .inner > ul > li .text-box ul li a::before {
  content: "＞";
}
.l-main #layout-menu .no1 .inner > ul > li .text-box ul li a:hover {
  color: #00A960;
}
.l-main #layout-menu .no1 .inner > ul > li .text-box p {
  text-indent: -1em;
  padding-left: 1em;
}
.l-main #layout-menu .no2 {
  margin-bottom: 100px;
}
.l-main #layout-menu .no2 h3 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 50px;
  text-align: center;
}
.l-main #layout-ticket .mv-area {
  margin-bottom: 60px;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .mv-area {
    margin-bottom: 50px;
  }
}
.l-main #layout-ticket .no1 {
  margin-bottom: 100px;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no1 {
    margin-bottom: 20px;
  }
}
.l-main #layout-ticket .no1 .inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.l-main #layout-ticket .no1 .inner > p {
  font-size: 21px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 50px;
}
.l-main #layout-ticket .no1 .inner > p .ex1 {
  display: inline;
}
@media (max-width: 768px) {
  .l-main #layout-ticket .no1 .inner > p .ex1 {
    display: none;
  }
}
.l-main #layout-ticket .no1 .inner > p .ex2 {
  display: none;
}
@media (max-width: 768px) {
  .l-main #layout-ticket .no1 .inner > p .ex2 {
    display: inline;
  }
}
.l-main #layout-ticket .no1 .inner > p.ex3 {
  font-weight: normal;
  font-size: 14px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto 10px;
  padding: 0 1em;
}
.l-main #layout-ticket .no1 .inner ul {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no1 .inner ul {
    flex-wrap: wrap;
    justify-content: space-around !important;
  }
}
.l-main #layout-ticket .no1 .inner ul li {
  margin-right: 2%;
}
.l-main #layout-ticket .no1 .inner ul li:last-child {
  margin-right: 0;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no1 .inner ul li {
    width: 30%;
    margin-bottom: 35px;
  }
}
.l-main #layout-ticket .no1 .inner ul li a figure {
  width: 100%;
  max-width: 190px;
  border: 5px solid #8ECE9A;
  border-radius: 300px;
  margin-bottom: 20px;
}
.l-main #layout-ticket .no1 .inner ul li a p {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  position: relative;
  line-height: 1.2;
}
.l-main #layout-ticket .no1 .inner ul li a p br {
  display: none;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no1 .inner ul li a p br {
    display: block;
  }
}
.l-main #layout-ticket .no1 .inner ul li.on a figure, .l-main #layout-ticket .no1 .inner ul li:hover a figure {
  border: 5px solid #00A960;
}
.l-main #layout-ticket .no1 .inner ul li.on a p, .l-main #layout-ticket .no1 .inner ul li:hover a p {
  color: #00A960;
}
.l-main #layout-ticket .no1 .inner ul li.on a p::after, .l-main #layout-ticket .no1 .inner ul li:hover a p::after {
  content: "";
  display: block;
  width: 1em;
  border: solid 10px transparent;
  border-top: solid 18px #00A960;
  text-align: center;
  margin: 0 auto;
  position: absolute;
  bottom: -1.5em;
  left: 50%;
  margin-left: -0.5em;
}
.l-main #layout-ticket .no2 {
  margin-bottom: 50px;
}
.l-main #layout-ticket .no2 .inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.l-main #layout-ticket .no2 .inner table {
  width: 100%;
}
.l-main #layout-ticket .no2 .inner table tbody tr td {
  width: 25%;
  border-top: 1px solid #d6d6d6;
  border-right: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  color: #00A960;
  background: #EFF8F0;
  padding: 1em;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}
.l-main #layout-ticket .no2 .inner table tbody tr td:last-child {
  border-right: none;
}
.l-main #layout-ticket .no2 .inner dl .ticket-area {
  width: 100%;
  display: flex;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area {
    flex-direction: column;
    margin-bottom: 50px;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .show-off {
  display: none !important;
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .red {
  color: #ED5454;
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .bold {
  font-weight: bold;
}
.l-main #layout-ticket .no2 .inner dl .ticket-area dt, .l-main #layout-ticket .no2 .inner dl .ticket-area dd {
  width: 25%;
  padding: 1em;
  border-bottom: 1px solid #d6d6d6;
  border-right: 1px solid #d6d6d6;
  box-sizing: content-box;
  box-sizing: border-box;
  word-break: break-all;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area dt, .l-main #layout-ticket .no2 .inner dl .ticket-area dd {
    width: 100%;
    box-sizing: border-box;
    padding-top: 0;
    padding-bottom: 50px;
    border-bottom: none;
    border-right: none;
  }
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .title-box {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .title-box::before {
    content: "－チケット名－";
    width: 100%;
    display: block;
    background-color: #00A960;
    color: #ffffff;
    padding: 0.2em 0;
    text-align: center;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .title-box > .name {
  margin-bottom: 20px;
  font-weight: bold;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .title-box > .name {
    text-align: center;
    background: #EFF8F0;
    font-size: 1.2em;
    padding: 0.2em 1em;
    margin-bottom: 30px;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .title-box > .place {
  margin-bottom: 20px;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .title-box > .place {
    width: calc(100% - 2em);
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding-left: 4em;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .title-box > .place::before {
  content: "■会場:";
  width: 100%;
  display: block;
  font-weight: bold;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .title-box > .place::before {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .title-box > .photo {
  position: relative;
  width: 100%;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .title-box > .photo {
    padding: 0 1em;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .title-box > .photo::before {
  content: "";
  display: block;
  padding-top: 75%;
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .title-box > .photo figure {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .title-box > .photo figure {
    width: calc(100% - 2em);
    left: 1em;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .title-box > .photo figure img {
  width: auto;
  height: 100%;
  object-fit: contain;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .term-box::before {
    content: "－イベント情報－";
    background: #EFF3F5;
    font-weight: bold;
    width: 100%;
    display: block;
    padding: 0.2em 0;
    text-align: center;
    margin-bottom: 20px;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .term-box > p {
  line-height: 1.5;
  margin-bottom: 20px;
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .term-box > p.term {
  position: relative;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .term-box > p.term {
    padding-left: 4em;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .term-box > p.term::before {
  content: "■期日:";
  width: 100%;
  display: block;
  font-weight: bold;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .term-box > p.term::before {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .term-box > p.time {
  position: relative;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .term-box > p.time {
    padding-left: 6em;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .term-box > p.time::before {
  content: "■営業時間:";
  width: 100%;
  display: block;
  font-weight: bold;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .term-box > p.time::before {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .term-box > p.holiday {
  position: relative;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .term-box > p.holiday {
    padding-left: 4em;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .term-box > p.holiday::before {
  content: "■休日:";
  width: 100%;
  display: block;
  font-weight: bold;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .term-box > p.holiday::before {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .term-box > p.bikou {
  position: relative;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .term-box > p.bikou {
    padding-left: 4em;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .term-box > p.bikou::before {
  content: "■備考:";
  width: 100%;
  font-weight: bold;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .term-box > p.bikou::before {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .term-box > p:last-child {
  margin-bottom: 0;
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .term-box .button-event a {
  width: 90%;
  margin: 30px auto 10px;
  text-align: center;
  display: block;
  font-weight: bold;
  background: #EFF8F0;
  color: #00A960;
  font-weight: bold;
  padding: 0.4em 0;
  border-radius: 300px;
  border: 1px solid #00A960;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .pay-box::before {
    content: "－料金－";
    background: #EFF3F5;
    font-weight: bold;
    width: 100%;
    display: block;
    padding: 0.2em 0;
    text-align: center;
    margin-bottom: 20px;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .pay-box > p {
  margin-bottom: 20px;
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .pay-box > p.price {
  position: relative;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .pay-box > p.price {
    padding-left: 4em;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .pay-box > p.price::before {
  content: "■料金:";
  width: 100%;
  display: block;
  font-weight: bold;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .pay-box > p.price::before {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .pay-box > p.max {
  position: relative;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .pay-box > p.max {
    padding-left: 7em;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .pay-box > p.max::before {
  content: "■購入可能数:";
  width: 100%;
  display: block;
  font-weight: bold;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .pay-box > p.max::before {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .pay-box > p.bikou {
  font-weight: normal;
  position: relative;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .pay-box > p.bikou {
    padding-left: 4em;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .pay-box > p.bikou::before {
  content: "■備考:";
  width: 100%;
  display: block;
  font-weight: bold;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .pay-box > p.bikou::before {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box {
  border-right: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box {
    border-bottom: 1px solid #d6d6d6;
    padding-bottom: 20px;
  }
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box::before {
    content: "－お申込み方法－";
    background: #EFF3F5;
    font-weight: bold;
    width: 100%;
    display: block;
    padding: 0.2em 0;
    text-align: center;
    margin-bottom: 20px;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box .button-tel,
.l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box .button-mail {
  width: 100%;
  margin-top: 20px;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box .button-tel,
  .l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box .button-mail {
    width: 95%;
    margin: 0 auto 40px;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box a {
  display: block;
  text-align: center;
  width: 100%;
  padding: 1em 0;
  font-size: 18px;
  font-weight: bold;
  border-radius: 300px;
  background-color: #EFF8F0;
  color: #00A960;
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box p {
  font-size: 0.8em;
  text-align: center;
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box p.contact-term {
  font-size: 1em;
  margin-bottom: 20px;
  position: relative;
  text-align: left;
  width: 100%;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box p.contact-term {
    padding-left: 6em;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box p.contact-term::before {
  content: "■受付期間:";
  display: block;
  font-weight: bold;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box p.contact-term::before {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box p.delivery-date {
  font-size: 1em;
  margin-bottom: 20px;
  position: relative;
  text-align: left;
  width: 100%;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box p.delivery-date {
    padding-left: 6em;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box p.delivery-date::before {
  content: "■引換期間:";
  font-weight: bold;
  display: block;
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box p.delivery-date::before {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box .button-tel a {
  background-image: url(../img/icon/icon-tel.png);
  background-position: left 15px center;
  background-repeat: no-repeat;
  background-size: 26px;
}
@media (max-width: 1000px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box .button-tel a {
    background-size: 15px;
    background-position: center top 5px;
  }
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box .button-tel a {
    background-size: 26px;
    background-position: left 15px center;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box .button-tel p::before {
  content: "受付時間:";
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box .button-mail a {
  background-image: url(../img/icon/icon-mail.png);
  background-position: left 15px center;
  background-repeat: no-repeat;
}
@media (max-width: 1000px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box .button-mail a {
    background-size: 15px;
    background-position: center top 5px;
  }
}
@media (max-width: 640px) {
  .l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box .button-mail a {
    background-size: 26px;
    background-position: left 15px center;
  }
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box .contact-message-box {
  margin-top: 20px;
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box .contact-message-box p {
  text-align: left;
  font-size: 1em;
}
.l-main #layout-ticket .no2 .inner dl .ticket-area .contact-box .contact-message-box .button-area {
  width: 100%;
}
.l-main #layout-ticket .no3 {
  margin-bottom: 100px;
}
.l-main #layout-ticket .no3 h3 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 50px;
  text-align: center;
}
.l-main #layout-ticket .no3 .inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
.l-main #layout-gaiyou .mv-area {
  margin-bottom: 60px;
}
@media (max-width: 768px) {
  .l-main #layout-gaiyou .mv-area {
    margin-bottom: 50px;
  }
}
.l-main #layout-gaiyou .no1 {
  margin-bottom: 100px;
}
@media (max-width: 768px) {
  .l-main #layout-gaiyou .no1 {
    margin-bottom: 80px;
  }
}
.l-main #layout-gaiyou .no1 .inner {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .l-main #layout-gaiyou .no1 .inner {
    width: 96%;
  }
}
.l-main #layout-gaiyou .no1 .inner .sub-top {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 640px) {
  .l-main #layout-gaiyou .no1 .inner .sub-top {
    flex-direction: column-reverse;
  }
}
.l-main #layout-gaiyou .no1 .inner .sub-top .text-box {
  width: 48%;
}
@media (max-width: 640px) {
  .l-main #layout-gaiyou .no1 .inner .sub-top .text-box {
    width: 100%;
  }
}
.l-main #layout-gaiyou .no1 .inner .sub-top .text-box h3 {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 18px;
}
.l-main #layout-gaiyou .no1 .inner .sub-top .text-box p {
  margin-bottom: 40px;
}
.l-main #layout-gaiyou .no1 .inner .sub-top .text-box a {
  display: flex;
}
.l-main #layout-gaiyou .no1 .inner .sub-top .text-box a .arrow-cirlue {
  border: 1px solid #EFF8F0;
  margin-left: 10px;
}
.l-main #layout-gaiyou .no1 .inner .sub-top .text-box a:hover {
  color: #00A960;
}
.l-main #layout-gaiyou .no1 .inner .sub-top .text-box a:hover .arrow-cirlue {
  border: 1px solid #8ECE9A;
  background-color: #00A960;
}
.l-main #layout-gaiyou .no1 .inner .sub-top .text-box a:hover .arrow-cirlue path {
  fill: #ffffff;
}
.l-main #layout-gaiyou .no1 .inner .sub-top .photo-box {
  width: 48%;
}
@media (max-width: 640px) {
  .l-main #layout-gaiyou .no1 .inner .sub-top .photo-box {
    width: 100%;
  }
}
.l-main #layout-gaiyou .no1 .inner .sub-top .photo-box figure {
  border-radius: 50px;
  /*暫定*/
  text-align: center;
  background: #efefef;
  height: 300px;
}
@media (max-width: 640px) {
  .l-main #layout-gaiyou .no1 .inner .sub-top .photo-box figure {
    margin-bottom: 20px;
  }
}
.l-main #layout-gaiyou .no2 .inner {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}
.l-main #layout-gaiyou .no2 .inner h3 {
  margin-bottom: 50px;
}
.l-main #layout-gaiyou .no2 .inner ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .l-main #layout-gaiyou .no2 .inner ul {
    width: 96%;
    margin: 0 auto;
  }
}
.l-main #layout-gaiyou .no2 .inner ul li {
  width: 48%;
  margin-bottom: 50px;
  border-radius: 30px;
  background: #EFF3F5;
  border: 1px solid #EFF3F5;
}
@media (max-width: 640px) {
  .l-main #layout-gaiyou .no2 .inner ul li {
    width: 100%;
  }
}
.l-main #layout-gaiyou .no2 .inner ul li a {
  display: block;
  padding: 20px 20px 10px;
  height: 100%;
}
@media (max-width: 640px) {
  .l-main #layout-gaiyou .no2 .inner ul li a {
    padding: 10px;
  }
}
.l-main #layout-gaiyou .no2 .inner ul li a h4 {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #00A960;
}
.l-main #layout-gaiyou .no2 .inner ul li a h4 span::before {
  content: "◇";
  margin-right: 0.5em;
}
.l-main #layout-gaiyou .no2 .inner ul li a h4 span::after {
  content: "◇";
  margin-left: 0.5em;
}
.l-main #layout-gaiyou .no2 .inner ul li a .text-box {
  background: #ffffff;
  border-radius: 20px;
  min-height: 145px;
  width: 100%;
  margin: 0 auto 20px;
}
.l-main #layout-gaiyou .no2 .inner ul li a .text-box > p {
  padding: 1em;
}
.l-main #layout-gaiyou .no2 .inner ul li a > p {
  display: flex;
  text-align: center;
  justify-content: center;
  font-weight: bold;
}
.l-main #layout-gaiyou .no2 .inner ul li a > p .arrow-cirlue {
  margin-left: 10px;
}
.l-main #layout-gaiyou .no2 .inner ul li:hover {
  background: #EFF8F0;
  border: 1px solid #00A960;
}
.l-main #layout-gaiyou .no2 .inner ul li:hover a > p {
  color: #00A960;
}
.l-main #layout-gaiyou .no2 .inner ul li:hover a > p .arrow-cirlue {
  background: #00A960;
}
.l-main #layout-gaiyou .no2 .inner ul li:hover a > p .arrow-cirlue path {
  fill: #ffffff;
}
.l-main #layout-news-list .news-area .arrow-cirlue {
  border: 1px solid #666;
}
.l-main #layout-news-list .old-news-area {
  background-color: #EFF3F5;
  margin: 0 0 50px;
  padding: 50px 0;
}
.l-main #layout-news-list .old-news-area .inner {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  background: #ffffff;
  padding: 30px 50px 50px;
  border-radius: 50px;
}
.l-main #layout-news-list .old-news-area .inner h3 {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
}
.l-main #layout-news-list .old-news-area .inner ul {
  display: flex;
  font-size: 1.2em;
  justify-content: center;
}
.l-main #layout-news-list .old-news-area .inner ul li {
  margin-right: 20px;
}
.l-main #layout-news-list .old-news-area .inner ul li a::after {
  content: ">";
  margin-left: 0.5em;
}
.l-main #layout-contact-page .mv-area {
  margin-bottom: 50px;
}
.l-main #layout-contact-page .must {
  font-weight: bold;
  color: #00A960;
}
.l-main #layout-contact-page .inner {
  width: 100%;
  max-width: 860px;
  margin: 0 auto 100px;
  position: relative;
}
@media (max-width: 768px) {
  .l-main #layout-contact-page .inner {
    width: 96%;
  }
}
.l-main #layout-contact-page .introduction-word {
  text-align: center;
  margin-bottom: 20px;
}
.l-main #layout-contact-page .contact-form-box {
  border-radius: 50px;
  padding: 30px;
  background: #EFF3F5;
}
@media (max-width: 640px) {
  .l-main #layout-contact-page .contact-form-box {
    padding: 30px 10px;
  }
}
.l-main #layout-contact-page .contact-form-box .form-item {
  margin-bottom: 20px;
}
.l-main #layout-contact-page .contact-form-box .form-item br {
  display: none;
}
.l-main #layout-contact-page .contact-form-box .form-item .title-word {
  font-weight: bold;
}
.l-main #layout-contact-page .contact-form-box .form-item input {
  width: 100%;
  border-radius: 300px;
}
.l-main #layout-contact-page .contact-form-box .form-item .sub-top {
  margin-bottom: 10px;
}
.l-main #layout-contact-page .contact-form-box .form-item .sub-top p {
  display: flex;
}
@media (max-width: 640px) {
  .l-main #layout-contact-page .contact-form-box .form-item .sub-top p {
    flex-direction: column;
  }
}
.l-main #layout-contact-page .contact-form-box .form-item .sub-top p br {
  display: none;
}
.l-main #layout-contact-page .contact-form-box .form-item .sub-top input {
  width: inherit;
}
.l-main #layout-contact-page .contact-form-box .type-checkbox br {
  display: block;
}
.l-main #layout-contact-page .contact-form-box .type-checkbox label {
  display: flex;
  margin-right: 20px;
}
.l-main #layout-contact-page .contact-form-box .type-checkbox label input {
  width: 1em;
  margin-right: 0.5em;
}
.l-main #layout-contact-page .contact-form-box .form-btn p {
  display: flex;
  flex-direction: column;
}
.l-main #layout-contact-page .contact-form-box .form-btn input {
  width: 100%;
  max-width: 300px;
  padding: 0.5em;
  text-align: center;
  border-radius: 300px;
  background: #ffffff;
  border: 2px solid #00A960;
  color: #00A960;
  font-weight: bold;
  font-size: 1.1em;
  margin: 0 auto 20px;
}
.l-main #layout-contact-page .contact-form-box .form-btn .wpcf7-spinner {
  margin: 0 auto 10px;
}
.l-main #layout-search-list .mv-area {
  margin-bottom: 60px;
}
.l-main #layout-search-list .inner {
  width: 96%;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 50px;
}
.l-main #layout-search-list .search-result li {
  padding: 10px 0;
  border-bottom: 1px solid #d6d6d6;
}
.l-main #layout-search-list .search-result li a {
  display: flex;
  position: relative;
  padding: 0 40px 0 170px;
}
@media (max-width: 640px) {
  .l-main #layout-search-list .search-result li a {
    padding: 2em 40px 0 0;
  }
}
.l-main #layout-search-list .search-result li a .date {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.8em;
}
@media (max-width: 640px) {
  .l-main #layout-search-list .search-result li a .date {
    font-size: 1em;
  }
}
.l-main #layout-search-list .search-result li a .date::after {
  display: block;
  padding: 0.05em 1em;
  text-align: center;
  width: 6em;
  color: #ffffff;
  border-radius: 30px;
  margin-left: 10px;
}
.l-main #layout-search-list .search-result li a .arrow-cirlue {
  position: absolute;
  top: 0;
  right: 0;
}
@media (max-width: 640px) {
  .l-main #layout-search-list .search-result li a .arrow-cirlue {
    width: 20px;
    height: 20px;
    top: 50%;
    margin-top: -10px;
  }
}
.l-main #layout-search-list .search-result li .arrow-cirlue {
  border: 1px solid #666;
}
.l-main #layout-search-list .search-result li.news-service a .date::after {
  content: "サービス";
  background: #FCBD5F;
}
.l-main #layout-search-list .search-result li.news-event a .date::after {
  content: "イベント";
  background: #F8764B;
}
.l-main #layout-search-list .search-result li.news-info a .date::after {
  content: "お知らせ";
  background: #F28BB0;
}

/*dl-list　ページ専用*/
.dl-list .l-main #layout-list .no1 .inner ul li .head-word {
  font-size: 21px;
  max-width: 360px;
}

.l-footer {
  background: #efefef;
  padding: 50px 0;
}
@media (max-width: 768px) {
  .l-footer {
    padding-bottom: 200px;
  }
}
@media (max-width: 640px) {
  .l-footer {
    font-size: 1.1em;
    padding-bottom: 100px;
  }
}
.l-footer .inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .l-footer .inner {
    padding: 0 2%;
  }
}
.l-footer .inner .contents-area {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
@media (max-width: 640px) {
  .l-footer .inner .contents-area {
    flex-direction: column;
  }
}
.l-footer .inner .contents-area .box1 {
  width: 100%;
  max-width: 420px;
}
@media (max-width: 1000px) {
  .l-footer .inner .contents-area .box1 {
    max-width: 270px;
    margin-right: 20px;
  }
}
@media (max-width: 640px) {
  .l-footer .inner .contents-area .box1 {
    max-width: inherit;
    margin-bottom: 30px;
  }
}
.l-footer .inner .contents-area .box1 .footer-logo {
  margin-bottom: 30px;
}
.l-footer .inner .contents-area .box1 .footer-logo a {
  margin-bottom: 10px;
  display: block;
}
.l-footer .inner .contents-area .box1 .footer-logo p {
  font-size: 0.8em;
  font-weight: bold;
}
.l-footer .inner .contents-area .box1 .footer-sns {
  display: flex;
  align-items: center;
}
.l-footer .inner .contents-area .box1 .footer-sns p {
  font-weight: bold;
  margin-right: 15px;
}
.l-footer .inner .contents-area .box1 .footer-sns ul {
  display: flex;
}
.l-footer .inner .contents-area .box1 .footer-sns ul li {
  margin-right: 15px;
}
@media (max-width: 768px) {
  .l-footer .inner .contents-area .box1 .footer-sns ul li {
    margin-right: 25px;
  }
}
.l-footer .inner .contents-area .box1 .footer-sns ul li a {
  display: block;
}
.l-footer .inner .contents-area .box1 .footer-sns ul li:last-child {
  margin-right: 0;
}
.l-footer .inner .contents-area .box2 {
  width: 100%;
  max-width: 550px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: 100px;
}
@media (max-width: 1000px) {
  .l-footer .inner .contents-area .box2 {
    margin-right: 0;
  }
}
@media (max-width: 768px) {
  .l-footer .inner .contents-area .box2 {
    margin-right: 0;
  }
}
@media (max-width: 640px) {
  .l-footer .inner .contents-area .box2 {
    flex-direction: column;
    max-width: inherit;
  }
}
.l-footer .inner .contents-area .box2 .footer-nav1 {
  width: 48%;
}
@media (max-width: 640px) {
  .l-footer .inner .contents-area .box2 .footer-nav1 {
    font-size: 1.1em;
    width: 100%;
    margin-bottom: 20px;
    border-bottom: 1px solid #d6d6d6;
  }
}
.l-footer .inner .contents-area .box2 .footer-nav1 ul {
  border-right: 1px solid #d6d6d6;
}
@media (max-width: 1000px) {
  .l-footer .inner .contents-area .box2 .footer-nav1 ul {
    padding-right: 1em;
  }
}
@media (max-width: 640px) {
  .l-footer .inner .contents-area .box2 .footer-nav1 ul {
    border-right: none;
  }
}
.l-footer .inner .contents-area .box2 .footer-nav1 ul li {
  font-weight: bold;
  margin-bottom: 15px;
}
.l-footer .inner .contents-area .box2 .footer-nav1 ul li a::after {
  content: ">";
  margin-left: 10px;
}
.l-footer .inner .contents-area .box2 .footer-nav1 ul li a span {
  border-bottom: 1px solid;
}
@media (max-width: 640px) {
  .l-footer .inner .contents-area .box2 .footer-nav1 ul li a {
    display: block;
  }
}
.l-footer .inner .contents-area .box2 .footer-nav2 {
  width: 48%;
}
@media (max-width: 640px) {
  .l-footer .inner .contents-area .box2 .footer-nav2 {
    width: 100%;
    border-bottom: 1px solid #d6d6d6;
  }
}
.l-footer .inner .contents-area .box2 .footer-nav2 p {
  margin-bottom: 15px;
  font-weight: bold;
}
@media (max-width: 1000px) {
  .l-footer .inner .contents-area .box2 .footer-nav2 p {
    margin-bottom: 10px;
  }
}
@media (max-width: 640px) {
  .l-footer .inner .contents-area .box2 .footer-nav2 p {
    font-size: 1.1em;
  }
}
.l-footer .inner .contents-area .box2 .footer-nav2 ul {
  padding-left: 0.5em;
}
.l-footer .inner .contents-area .box2 .footer-nav2 ul li {
  margin-bottom: 20px;
}
.l-footer .inner .contents-area .box2 .footer-nav2 ul li a {
  text-indent: -1em;
  padding-left: 1em;
  display: block;
}
.l-footer .inner .contents-area .box2 .footer-nav2 ul li a span {
  border-bottom: 1px solid;
}
.l-footer .inner .contents-area .box2 .footer-nav2 ul li a::after {
  content: ">";
  margin-left: 10px;
}
@media (max-width: 640px) {
  .l-footer .inner .contents-area .box2 .footer-nav2 ul li a {
    display: block;
  }
}
.l-footer .inner .contents-area .box2 .footer-nav3 {
  width: 100%;
  margin-top: 40px;
}
@media (max-width: 640px) {
  .l-footer .inner .contents-area .box2 .footer-nav3 {
    margin-top: 20px;
  }
}
.l-footer .inner .contents-area .box2 .footer-nav3 ul {
  display: flex;
}
@media (max-width: 640px) {
  .l-footer .inner .contents-area .box2 .footer-nav3 ul {
    flex-direction: column;
  }
}
.l-footer .inner .contents-area .box2 .footer-nav3 ul li {
  margin-right: 2em;
}
@media (max-width: 640px) {
  .l-footer .inner .contents-area .box2 .footer-nav3 ul li {
    margin-bottom: 20px;
  }
}
.l-footer .inner .contents-area .box2 .footer-nav3 ul li a span {
  border-bottom: 1px solid;
}
.l-footer .inner .contents-area .box2 .footer-nav3 ul li a::after {
  content: ">";
  margin-left: 10px;
}
.l-footer .inner .copy {
  width: 100%;
  background: #ffffff;
  border-radius: 100px;
  text-align: center;
  padding: 0.2em 0;
}
.l-footer .inner .copy span {
  margin-left: 1em;
}
@media (max-width: 640px) {
  .l-footer .inner .copy span {
    margin-left: 0;
  }
}
.l-footer .inner .copy br {
  display: none;
}
@media (max-width: 640px) {
  .l-footer .inner .copy br {
    display: block;
  }
}