.l-header {
  padding: 15px 0;
  @include media(tab) {
    position: fixed;  
    background: #ffffff;
    width: 100%;
    box-shadow: 0 0 3px rgba(0,0,0,0.3);
    z-index: 5000;

  }  
  @include media(sp) {
    padding: 15px 0;
  }
  .inner {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .l-header__logo {
      width: 20%;
      max-width: 230px;
      display: flex;
      align-items: center;
      @include media(w-1000) {
        max-width: 230px;
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 9000;
      }
      @include media(tab) {
        position: static;
        display: flex;
        align-items: center;
        width: 40%;
        max-width: 280px;
        margin-left: 20px;
      }
      @include media(sp) {
        width: 50%;
        margin-left: 10px;
      }
    }

    .header-contents {
      width: 83%;
      max-width: 890px;
      font-size: 14px;
      @include media(w-1000) {
        width: 100%;
        max-width: inherit;
        margin-right: 5px;
        margin-left: 5px;
      }
      @include media(tab) {
        display:none;
        margin-right: 0;
        margin-left: 0;
        position: fixed;
        top:0;
        left: 0;
        overflow: auto;
        height: 100vh;
        z-index: 9000;
        padding-bottom: 100px;
        background: $bg_color;
      }

      .sp-inner {
        display: grid;
        grid-template-columns: 1fr auto auto auto;
        grid-template-rows: 1fr 1fr;
        @include media(tab) {
          width: 100%;
          position: absolute;
          top:0;
          left: 0;
          background: $bg_color;
          z-index: 9500;
          display: flex;
          flex-direction: column;
          padding: 20px 2% 40px;
          height: 100%;
        }

        .serch-area {
          grid-column: 2 / 3;
          grid-row: 1 / 2;
          width: 100%;
          max-width: 220px;
          margin-right: 10px;
          @include media(w-1000) {
            max-width: 160px;
            margin-right: 10px;
          }
          @include media(tab) {
            order: 1;
            max-width: inherit;
            max-width: calc(100% - 50px);
          }
          form#form {
            display: flex;
            input {
              width: 100%;
              height: 28px;
              border-radius: 100px;
              background: url(../img/icon/icon-serch.png) left center no-repeat;
              border: 1px solid $line_gray;
              background-size: contain;
              padding: 0.2em 0 0.2em 2em;
              margin-right: 10px;
              @include media(tab) {
                background-color: #ffffff;
                padding: 0.5em 0 0.5em 2.5em;
              }
              &::placeholder { 
                color: #717171;
                font-size: 12px;
              }
            }

            button {
              div {
                background: #717171;
                color: #ffffff;
                border-radius: 5px;
                width: 2.5em;
              }
            }
          }

        }
        .text {
          grid-column: 3 / 4;
          grid-row: 1 / 2;
          display: flex;
          border-right: 1px solid $line-gray;
          @include media (tab) {
            order: 3;
            justify-content: center;
            margin-bottom: 20px;
            border-right: none;
          }
          li {
            margin-right: 10px;
            @include media (tab) {
              margin-right: 10px;
              font-weight: bold;
              letter-spacing: -1px;
              &:first-child {
                margin-right: 20px;
              }
              &:last-child {
              }
            }
            a {}
          }
        }
        .sns {
          grid-column: 4 / 5;
          grid-row: 1 / 2;

          display: flex;
          @include media(tab) {
            order: 4;
            justify-content: center;
            padding-bottom: 50px;
          }
          li {
            margin-left: 20px;
            @include media(w-1000) {
              margin-left: 15px;
              width: 24px;
            }
            @include media(tab) {
              margin-left: 30px;
              width: 28px;
              &:first-child {
                margin-left: 0;
              }
            }
            a {}
          }
        }
        
        .global-navi {
          grid-column: 1 / 6;
          grid-row: 2 / 3;
          margin-top: 10px;
          font-weight: bold;
          @include media(tab) {
            order: 2;
            width: 100%;
            margin-bottom: 20px;
          }
          >ul {
            display: flex;
            justify-content: end;
            @include media(tab) {
              flex-direction: column;
            }
            >li {
              margin-left: 20px;
              border-bottom: 2px solid transparent ;
              @include media(tab) {
                letter-spacing: 0;
                margin-left: 0;
                border-bottom: 1px dashed $c_text_gray;
              }
              >a {
                display: flex;
                align-items: center;
                border-bottom: 2px solid #ffffff;
                @include media(tab) {
                  border-bottom: none;
                }
                &:hover {
                  color: $key_color;
                  @include media(tab){
                    color:$c_text;
                  }
                }
                &.parent {
                  &::after {
                    content:"";
                    display: block;
                    width: 5px;
                    height: 5px;
                    border: 5px solid transparent;
                    border-top: 5px solid $key_color;
                    margin: 3px 0 0 5px;
                  }
                }
                @include media(tab) {
                  padding: 0.5em 0;
                  background: url(../img/icon/sp-menu-icon-arrow.png) center right no-repeat;
                  background-size: 20px;
                  &.parent {
                    background: url(../img/icon/sp-menu-icon-plus.png) center right no-repeat;
                    background-size: 20px;
                    &::after {
                      content: none;
                    }
                  }
                }
              }
              .child-menu {
                max-height: 0;
                overflow: hidden;
                transition: 0.5s;  
                opacity: 0;
                position: absolute;
                padding: 1em 0.5em;
                background: rgba(255,255,255,0.9);
                border-radius: 0 0 15px 15px;
                font-weight: normal;
                margin-top: 2px;
                z-index: 100;
                @include media(tab) {
                  position: static;
                  padding-top: 0;
                  padding-right: 0;
                  background: none;
                }
                li {
                  text-indent: -1em;
                  padding-left: 1em;
                  margin-bottom: 5px;
                  &:last-child {
                    margin-bottom: 0px;
                    @include media(tab) {
                      border-bottom: none;
                    }
                  }
                  @include media(tab) {
                    border-bottom: 1px dotted $c_text_gray;
                  }
                  a{
                    @include media(tab) {
                      display: block;
                      padding-top: 0.5em;
                      padding-bottom: 0.5em;
                      background: url(../img/icon/sp-menu-icon-arrow.png) center right no-repeat;
                      background-size: 20px;
                    }
                  }
                  &.on {
                    a {
                      @include media(tab) {
                        display: block;
                        background: url(../img/icon/sp-menu-icon-arrow-on.png) center right no-repeat;
                        background-size: 20px;
                      }
                    }
                  }
                }
              }
              &:first-child {
                margin-left: 0;
              }
            } 

            @include media(tab-min) {
              .gnav-switch-pc:hover {
                border-bottom: 2px solid $key_color;
                .parent {
                  &::after {
                    content:"";
                    border-bottom: 5px solid $key_color;
                    margin: 3px 0 0 5px;
                    border-top: none;
                    @include media(tab) {
                      content: none;
                    }
                  }
                }               
                .child-menu {
                  max-height: 500px;
                  display: block;
                  opacity: 1.0;
                }
              }
            }


            >li.on {
              @include media(tab) {
                border-bottom: 1px dashed $c_text_gray;
              }
              >a {
                @include media(tab) {
                  color: $key_color;
                  background: url(../img/icon/sp-menu-icon-minus.png) center right no-repeat;
                  background-size: 20px;
                }
                &.parent {
                  &::after {
                    content:"";
                    /*border-bottom: 5px solid $key_color;
                    margin: 3px 0 0 5px;
                    border-top: none;*/
                    @include media(tab) {
                      content: none;
                    }
                  }
                }
              }
              .child-menu {
                @include media(tab) {
                  max-height: 500px;
                  display: block;
                  opacity: 1.0;
                }
              }
            }
          }
        }
      }
    }
  }

  .menu-button {
    display: none;
    position: fixed;
    top: 15px;
    right: 20px;
    z-index: 9500;
    @include media(sp) {
      top: 10px;
      right: 10px;
    }

    .icon-area {
      width: 40px;
      height: 40px;
      padding: 10px;
      background: #ffffff;
      border: 1px solid $c_text_gray;
      border-radius: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 5px;
      position: relative;
      span {
        display: block;
        width: 20px;
        height: 2px;
        background: $c_text_gray;
        margin-bottom: 3px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      span.bar1 {}
      span.bar2 {}
      span.bar3 {}
      &::after {
        content: "MENU";
        text-align: center;
        font-size: 11px;
        font-weight: bold;
        color: $c_text_gray;
        position: absolute;
        bottom: -2em
      }
    }

    @include media(tab) {
      display: block;
    }
  }

}

.on {
  .header-contents {
    @include media(tab) {
      display: block !important;
    }
  }
}


.on {
  .menu-button {
    .icon-area {
      border: 1px solid $line-gray;
      span.bar1 {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        position: absolute;
        top:50%;
        left: 50%;
        margin-top: -1px;
        margin-left: -10px;
        background: $key-color;
      }
      span.bar2 {
        display: none;
      }
      span.bar3 {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        position: absolute;
        top:50%;
        left: 50%;
        margin-top: -1px;
        margin-left: -10px;
        background: $key-color;
      }
      &::after {
        content: "CLOSE";
      }
    }
  }

}

.fixed {
  width: 100%;
  padding: 15px 0;
  position: fixed;
  background: #fff;
  z-index: 9900;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);
}

.l-navi {
  @include media(sp) {
  }
}