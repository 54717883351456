.l-main {
  @include media(tab) {
    padding-top: 90px ;
  }
  @include media(sp) {
    padding-top: 80px ;
  }

  /**▼▼▼▼▼toppage▼▼▼▼▼**/
  #toppage {
    .mv-area {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      @include media(tab) {
        overflow: hidden;
      }
      .mv-slider {
        .slick-slider {
          background: $bg_color;
          margin-bottom: 0;
        }

        .slick-dots {
          bottom: 20px;
          li {
            button {
              &::before {
                content: "";
                display: block;
                width: 14px;
                height: 14px;
                background: $key-color;
                border: 1px solid $key-color;
                border-radius: 300px;
                opacity: 1;
              }
            }
            &.slick-active {
              button {
                &::before {
                  content: "";
                  display: block;
                  width: 16px;
                  height: 16px;
                  background: #ffffff;
                  border: 2px solid $key-color;
                  border-radius: 300px;
                }
              }
            }
          }
        }

      }
    }

    .news-area {
      margin-bottom: 150px;
      @include media(sp) {
        margin-bottom: 100px;
      }
      .inner {
        background: $bg_color;
        border-radius: 0 0 50px 50px;
        .news-nav{
          margin-bottom: 0;
          .nav-switch {
            li {
              &:last-child {
                @include media(sp) {
                  margin-top: 0;
                  position: absolute;
                  top: 55px;
                  right: 15px;
                  width: 145px;
                }
              }
            }
          }
        }
        .news-contents {
          .select-news {
            li {
              &:nth-last-of-type(1),
              &:nth-last-of-type(2) {
                @include media(tab) {
                  display: none;
                }
              }
            }
          }
        }
      }
      
    }

    .no1 {
      margin-bottom: 150px;
      @include media(sp) {
        margin-bottom: 100px;
      }
      .inner {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        background: $bg_color;
        border-radius: 50px;
        padding-top: 0;
        padding-bottom: 50px;
        @include media(sp) {
          border-radius: 0;
        }
        .type-a {}
        h3 {
          text-align: center;
          position: relative;
          font-size: 21px;
          font-weight: 500;
          margin: 50px 0;
          @include media (sp) {
            margin: 20px 0 30px;
            font-size: 18px;

          }
        }
        > ul {
          display: flex;
          justify-content: space-between;
          padding: 0 2%;
          @include media (tab) {
            flex-wrap: wrap;
            justify-content: space-between;
          }
          li {
            width: 24%;
            max-width: 270px;
            text-align: center;
            @include media (tab) {
              width: 100%;
              margin-bottom: 30px;
              max-width: inherit;
            }
            a {
              display: block;
              background: #ffffff;
              border: 1px solid #ffffff;
              border-radius: 60px;
              min-height: 410px;
              box-sizing: border-box;
              @include media(w-1000) {
                border-radius: 30px;
              }
              @include media(tab) {
                display: grid;
                grid-template-columns: 5px 130px 1fr 5px;
                grid-template-rows: 1fr 1fr;
                position: relative;
                padding-top: 2em;
                min-height: inherit;
                align-items: center;
              }

              h4 {
                margin: -10px 0 20px;
                @include media(tab) {
                  position: absolute;
                  left: 50%;
                  transform: translateX(-50%);
                  top: 0;
                  font-size: 18px;
                }
                span {
                  background: $key_color;
                  padding: 0.1em 1em;
                  font-weight: bold;
                  color: #ffffff;
                  border: 6px solid $bg_color;
                  border-radius: 100px;
                  background-clip: padding-box;
                  -webkit-background-clip: padding-box;
                  -ms-background-clip: paddng-box;
                }
              }
              p.ex1 {
                min-height: 70px;
                line-height: 1.5;
                font-weight: bold;
                @include media(tab) {
                  grid-column: 3/4;
                  grid-row: 1/2;
                  min-height: inherit;
                  margin-bottom: 0px;
                  font-size: 18px;
                }
                span { color: $key_color;}
              }
              figure {
                min-height: 213px;
                margin-bottom: 20px;
                display: flex;
                justify-content: center;
                align-items: end;
                @include media(tab) {
                  grid-column: 2/3;
                  grid-row: 1/3;
                  min-height: inherit;
                  margin: 0 auto 20px;
                }

              }
              .btn-style {
                width: 90%;
                max-width: 200px;
                margin: 0 auto;
                @include media(tab) {
                  grid-column: 3/5;
                  grid-row: 2/3;
                  max-width: 360px;
                }
              }
            }
            a:hover {
              border: 1px solid $key_color;
              box-sizing: border-box;
              .btn-style {
                background: $key_color;
                opacity: 0.7;
                &::before,
                &::after {
                  content: none;
                }
                .text {
                  color: #ffffff;
                }
                .arrow {
                  path {
                    fill: #ffffff;
                  }
                }
              }
            }
            &.s1 {
              figure {
                @include media(tab) {
                  max-width: 70px;
                }
              }
            }
            &.s2 {
              figure {
                @include media(tab) {
                  max-width: 77px;
                }
              }
            }
            &.s3 {
              figure {
                @include media(tab) {
                  max-width: 99px;
                }
              }
            }
            &.s4 {
              figure {
                @include media(tab) {
                  max-width: 113px;
                }
              }
            }
          }
        }
      }
    }

    .no2 {
      margin-bottom: 100px;
      @include media(sp) {
        margin-bottom: 60px;
      }
      .inner {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        background: $bg_color;
        border-radius: 50px;
        padding-bottom: 50px;
        @include media(sp) {
          border-radius: 0;
        }
        h2 {
          margin-bottom: 40px;
          @include media(sp) {
            margin-bottom: 20px;
          }
        }
        .thumb-slider-area {
          padding: 0 4%;
          ul { 
            position: relative;
            display: flex;
            justify-content: space-around;
            li {
              width: 34%;
              max-width: 360px;
              padding: 0 5px;
              text-align: center;
              @include media(sp) {
                width: 100%;
                max-width: inherit;
              }
              .contents {
                a {
                  figure {
                    margin-bottom: 0;
                    display: inline-block;
                    height: 165px;
                    position: relative;
                    width: 100%;

                    img {
                      height: 100%;
                      position: absolute;
                      top:0;
                      left: 50%;
                      width: 100%;
                      transform: translateX(-50%);
                      object-fit: contain;

                    }
                  }
                  p {
                    margin-bottom: 20px;
                    font-weight: bold;
                  }
                  .date {
                    font-size: 0.8em;
                    margin-bottom: 0;
                  }
                }
              }
            }
          }

          .slick-prev {
            background: url(../img/icon/icon-slick-arrow-l.png) center center no-repeat;
            background-color: #ffffff;
            background-size: contain;
            border-radius: 80px;
            width: 28px;
            height: 28px;
            left: -25px;
            @include media(sp) {
              top:inherit;
              bottom: -30px;
              left: 40%;
            }
          }

          .slick-next {
            background: url(../img/icon/icon-slick-arrow-r.png) center center no-repeat;
            background-color: #ffffff;
            background-size: contain;
            border-radius: 80px;
            width: 28px;
            height: 28px;
            right: -25px;
            @include media(sp) {
              top:inherit;
              bottom: -30px;
              right: 40%;
            }
          }
        }
        .btn-style {
          width: 100%;
          max-width: 410px;
          margin: 0 auto;
          @include media(sp) {
            width: 80%;
          }
        }
      }
    }

    .no3 {
      margin-bottom: 100px;
      .inner {
        @include media(tab) {
          padding: 0 2%;
        }
        h3 {
          text-align: center;
          font-size: 36px;
          font-weight: 700;
          line-height: 1;
          margin-bottom: 40px;
          @include media(sp) {
            font-size: 28px;
          }
          span {
            font-size: 18px;
          }
        }
        .calendar-area {
          width: 100%;
          margin: 0 auto;
          max-width: 800px;
          @include media(tab) {
            max-width: inherit;

          }
          .fc-left {
            float: none;
            h2 {
              float: none;
              font-size: 24px;
              font-weight: bold;
              margin-bottom: 10px;
            }
          }
          .fc-right {
            float: none;
            display: flex;
            justify-content: center;
            >div:nth-of-type(1) {
              order: 1;
            }
            >div:nth-of-type(2) {
              order: 3;
              margin: 0;
            }
            >div:nth-of-type(3) {
              order: 2;
              margin: 0 20px;
            }
          }

        }
      }
    }

    .no4 {
      margin-bottom: 100px;
      @include media(sp) {
        margin-bottom: 60px;
      }
      .inner {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        background: $bg_color;
        border-radius: 50px;
        display: flex;
        justify-content: space-around;
        padding: 50px 100px;
        position: relative;
        @include media(tab) {
          padding: 50px 2%;
        }
        
        @include media(sp) {
          width: 96%;
          padding-top: 200px;
          flex-direction: column;
        }

        .box1 {
          width: 48%;
          max-width: 435px;
          @include media(sp) {
            order: 2;
            width: 100%;
            max-width: inherit;
          }
          .sub-top {
            @include media(sp) {
              position: absolute;
              top:30px;
              text-align: center;
              width: 100%;
            }
            h2 {
              font-size: 28px;
              font-weight: 700;
              margin-bottom: 20px;
            }
            p {
              margin-bottom: 50px;
              font-weight: bold;
              @include media(sp) {
                font-size: 16px;
              }
            }
          }
          ul {
            li {
              display: flex;
              align-items: center;
              padding: 0 0 20px 0;
              border-bottom: 1px solid $line_gray;
              margin-bottom: 20px;
              @include media(sp) {
                display: block;
              }
              a {
                display: flex;
                justify-content: space-between;
                align-items: center;
                @include media (sp) {
                  flex-direction: column;
                }
              }
              figure {
                width: 88px;
                height: 67px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 20px;
                flex-direction: column;
                @include media(sp) {
                  flex-direction: row;
                  justify-content: inherit;
                  width: inherit;
                  margin-right: 0;
                }
                figcaption {
                  text-align: center;
                  font-size: 18px;
                  font-weight: bold;
                  @include media(sp) {margin-left: 10px;}
                }
              }
              p{
                width: calc(100% - 110px);
                font-size: 0.8em;
                padding-right: 1em;
                @include media(sp) {
                  width: inherit;
                  font-size: 1em;
                  padding-right: 0;
                }
              }
              &:last-child {
                padding: 20px 0 0 0;
                border-bottom: none;
              }
            }
          }
        }
        .box2 {
          width: 48%;
          max-width: 344px;
          @include media(sp){
            order:1;
            width: 100%;
            margin-bottom: 50px;
            max-width: inherit;
          }
          h3 {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 10px;
            figure {
              width: 20px;
              height: 20px;
              display: flex;
              align-items: center;
              margin-right: 10px;
              @include media(sp) {
                width: 40px;
                height: 40px;
                margin-bottom: 20px;
              }
              figcaption {
                margin-left: 10px;
              }
            }
            @include media(sp) {
              font-size: 18px;
              font-weight: bold;
            }
          }
          .photo-box {
            height: 360px;
            overflow-y: scroll;
            overflow-x: hidden;
            box-shadow: 0 0 10px rgb(0 0 0 / 10%);
            border-radius: 10px;
            &::-webkit-scrollbar{
              width: 10px;
            }
            &::-webkit-scrollbar-track {
              background: #cccccc;
              border-radius: 5px;
            }

            &::-webkit-scrollbar-thumb{
              background-color: $c_sns_twitter;
              border-radius: 5px;
            }

          }
        }
      }
    }

    .mail-magazine {
      padding-bottom: 100px;
      h2 {
        margin-bottom: 20px;
      }

      .inner {
        width: 100%;
        max-width: 760px;
        margin: 0 auto;
        border: 1px solid $key_color;
        border-radius: 500px;
        display: flex;
        padding: 40px 80px;
        align-items: center;
        @include media(sp) {
          width: 96%;
          border-radius: 30px;
          padding: 20px;
          flex-direction: column;
          justify-content: center;
        }
        ul {
          width: 100%;
          max-width: 430px;
          margin-right: 50px;
          @include media(sp) {
            margin: 0 auto 20px;
          }
          li {
            margin-bottom: 20px;
            text-align: center;
            color: $key_color;
            font-size: 18px;
            font-weight: bold;
            display: flex;
            align-items: center;
            a {
              display: block;
              background: $key_color_light2;
              border: 1px solid $key_color_light2;
              border-radius: 100px;
              padding: 1em;
              width: 100%;
              position: relative;
              @include media(sp) {
                border: 1px solid $key_color;
              }
              .text {
                position: relative;
                &::before {
                  content: "";
                  width: 26px;
                  height: 22px;
                  background: url(../img/icon/icon-mail.png) center center no-repeat;
                  display: block;
                  position: absolute;
                  left: -40px;
                  top: 50%;
                  transform: translateY(-50%);
                  -webkit-transform: translateY(-50%);
                  -ms-transform: translateY(-50%);
                }
              }
              .arrow-cirlue.green {
                position: absolute;
                right: 1em;
                top: 50%;
                transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
              }
            }
          }

          li:last-child {
            margin-bottom: 0;
            a {
              .text {
                &::before {
                  background: url(../img/icon/icon-article.png) center center no-repeat;
                  top: 50%;
                  transform: translateY(-50%);
                  -webkit-transform: translateY(-50%);
                  -ms-transform: translateY(-50%);
                }
              }
              .arrow-cirlue.green {}
            }
          }

          li:hover {
            a {
              background: #ffffff;
              border: 1px solid $key_color;
              .text {}
              .arrow-cirlue.green {
                background: $key_color_light;
                path {
                  fill:#ffffff;
                }
              }
            }
          }
        }
        .qr-code-box {
          width: 110px;
          height: 110px;
        }
      }
    }
  }
/**▼▼▼▼▼page-list▼▼▼▼▼**/
  #layout-list{
    .mv-area {
      margin-bottom: 60px;
      @include media(sp) {
        margin-bottom: 50px;
      }

      .inner {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        background: $bg_color;
        border-radius: 0 0 50px 50px;
        padding: 50px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        @include media(sp) {
          padding: 15px;
        }
        h2 {
          font-size: 32px;
          font-weight: bold;
          @include media(sp) {
            font-size: 21px;
          }
          span {
            color: $key_color;
            position: relative;
            &::after {
              content: "・";
              position: absolute;
              top:-1em;
              left: 50%;
              margin-left: -0.5em;
            }
          }
        }
        figure {
          width: 230px;
          height: 230px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 300px;
          @include media(sp) {
            width: 130px;
            height: 130px;
          }

        }
      }
    }

    .page-link {
      text-align: center;
      margin-bottom: 60px;
      font-size: 1.1em;
      font-weight: bold;
      a {
        color: $key-color;
        text-decoration: underline;
      }
    }

    .no1 {
      .inner {
        width: 100%;
        max-width: 1060px;
        margin: 0 auto 100px;
        @include media(tab) {
          width: 96%;
          margin-bottom: 50px;
        }
        h3 {
          margin-bottom: 30px;
        }
        > p {
          margin-bottom: 50px;
        }
        ul {
          li {
            border-bottom: 1px dashed $line_gray;
            padding-bottom: 20px;
            margin-bottom: 20px;
            @include media(sp) {
              position: relative;
            }
            a {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              justify-content: space-between;
            }
            .head-word {
              font-size: 18px;
              font-weight: bold;
              width: 100%;
              margin-bottom: 20px;
              line-height: 1.2;
              padding-left: 1.5em;
              position: relative;
              @include media(sp) {
                padding-right: 40px;
              }
              &::before {
                content: "●";
                color: $key_color;
                position: absolute;
                top: 0;
                left: 0;
              }
            }
            .text {
              width: calc(100% - 80px);
              @include media(sp) {
                width: inherit;
              }
            }
            .arrow-cirlue.green2 {
              @include media(sp) {
                position: absolute;
                top: 0;
                right: 0;
              }
              path {
              }
            }

          }
          li:hover {
            .arrow-cirlue.green2 {
              background: $key_color_light2;
            }
          }
        }
        ul.dl-style {
          li {
            display: flex;
            justify-content: center;
            align-items: center;
            @include media(sp) {
              flex-direction: column;
            }
          }
          .head-word {
            max-width: 300px;
            margin-bottom: 0;
            margin-right: 10px;
            @include media(sp) {
              max-width: inherit;
              margin: 0 0 10px;
            }
          }
          .text {
            width: 100%;
            max-width: 200px;
            margin-left: 10px;
            display: block;
            background: $key-color;
            color: #ffffff;
            display: block;
            text-align: center;
            padding: 0.5em 1em;
            text-align: center;
            border-radius: 10px;
            font-weight: bold;
            font-size: 18px;
            @include media(sp) {
              width: 80%;
              max-width: inherit;
              margin-left: 0;
            }
          }
        }
        .table2-area {
          margin-top: 60px;
        }

      }
    }
    .no2 {
      margin-bottom: 100px;
      h3 {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 50px;
        text-align: center;
        @include media(sp) {
          font-size: 24px;
        }
      }
      .inner {
        background: $bg_color;
        border-radius: 80px;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 50px 20px;
        &.stage-ver {
          max-width: 1000px;
        }
        @include media(tab) {
          border-radius: 0;
          padding: 50px 10px;
        }
        > ul {
          display: flex;
          justify-content: space-between;
          padding: 0 3%;
          @include media(tab) {
            flex-wrap: wrap;
            justify-content: space-around;
            padding: 0;
          }
          
          li {
            width: 49%;
            max-width: 270px;
            text-align: center;
            margin-right: 1%;
            @include media(tab) {
              margin-bottom: 40px;
            }

            @include media(sp) {
              width: 100%;
              max-width: inherit;
            }
            &:last-child {
              margin-right: 0;
            }
            a {
              display: block;
              background: #ffffff;
              border: 1px solid #ffffff;
              border-radius: 60px;
              min-height: 410px;
              box-sizing: border-box;
              @include media(tab) {
                border-radius: 30px;
                display: grid;
                grid-template-columns: 5px 130px 1fr 5px;
                grid-template-rows: 1fr 1fr;
                position: relative;
                padding-top: 2em;
                min-height: inherit;
                align-items: center;
              }
              h4 {
                margin: -10px 0 20px;
                @include media(sp) {
                  position: absolute;
                  left: 50%;
                  transform: translateX(-50%);
                  top: 0;
                  font-size: 18px;
                }
                span {
                  background: $key_color;
                  padding: 0.1em 1em;
                  font-weight: bold;
                  color: #ffffff;
                  border: 6px solid $bg_color;
                  border-radius: 100px;
                  background-clip: padding-box;
                  -webkit-background-clip: padding-box;
                  -ms-background-clip: paddng-box;
                }
              }
              p.ex1 {
                min-height: 70px;
                line-height: 1.5;
                font-weight: bold;
                @include media(sp) {
                  grid-column: 3/4;
                  grid-row: 1/2;
                  min-height: inherit;
                  font-size: 18px;
                  margin: 0;
                }
                span { color: $key_color;}
              }
              figure {
                min-height: 213px;
                margin-bottom: 20px;
                display: flex;
                justify-content: center;
                align-items: end;
                @include media(sp) {
                  grid-column: 2/3;
                  grid-row: 1/3;
                  min-height: inherit;
                  margin: 0 auto 20px;
                }
              }
              .btn-style {
                width: 90%;
                max-width: 200px;
                margin: 0 auto;
                @include media(sp) {
                  grid-column: 3/5;
                  grid-row: 2/3;
                  max-width: 360px;
                }
              }
            }
            a:hover {
              border: 1px solid $key_color;
              box-sizing: border-box;
              .btn-style {
                background: $key_color;
                opacity: 0.7;
                &::before,
                &::after {
                  content: none;
                }
                .text {
                  color: #ffffff;
                }
                .arrow {
                  path {
                    fill: #ffffff;
                  }
                }
              }
            }
            &.s1 {
              figure {
                @include media(tab) {
                  max-width: 70px;
                }
              }
            }

            &.s2 {
              figure {
                @include media(tab) {
                  max-width: 77px;
                }
              }
            }

            &.s3 {
              figure {
                @include media(tab) {
                  max-width: 99px;
                }
              }
            }

            &.s4 {
              figure {
                @include media(tab) {
                  max-width: 113px;
                }
              }
            }
          }
        }
      }
    }
    .other-menu-area {
      h3 {
        text-align: center;
        position: relative;
        font-size: 21px;
        font-weight: 500;
        margin: 50px 0;
      }
      .inner {
        width: 100%;
        max-width: 1200px;
        background: $bg_color;
        border-radius: 50px;
        margin: 0 auto 100px;
        padding: 50px 0;
        @include media(tab) {
          border-radius: 0;
        }
      }
    }
  }

/**▲▲▲▲▲page-list▲▲▲▲▲**/

/**▼▼▼▼▼page-shousai▼▼▼▼▼**/
  #layout-shousai {
    .contents-area {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto 50px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include media(tab) {
        width: 96%;
        padding-top: 10px;
      }
      .sub-top {
        width: 68%;
        max-width: 820px;
        position: relative;
        @include media(tab) {
          width: 100%;
          max-width: inherit;
        }
        h2 {
          font-weight: bold;
          font-size: 28px;
          color: $key_color;
          margin-bottom: 20px;
          width: 100%;
          &::before {
            content: "";
            font-size: 16px;
            color: $c_text;
            display: block;
            @include media(tab) {
              margin-right: 5.5em;
            }
          }
          &.cats-management {
            &::before {
              content: "経営の相談をしたい";
            }
          }
          &.cats-seminar {
            &::before {
              content: "経営に関するセミナーや交流会等に参加したい";
            }
          }
          &.cats-collaboration {
            &::before {
              content: "協業できる区内の事業所や事業用物件を探したい";
            }
          }
          &.cats-subsidy {
            &::before {
              content: "助成金を活用したい";
            }
          }
          &.cats-loan {
            &::before {
              content: "融資を受けたい";
            }
          }
          &.cats-upbringing {
            &::before {
              content: "人材育成をしたい";
            }
          }
          &.cats-welfare {
            &::before {
              content: "福利厚生のサービスを受けたい";
            }
          }
          &.cats-training {
            &::before {
              content: "産業研修センターを利用したい";
            }
          }
          &.cats-founding {
            &::before {
              content: "創業支援施設について";
            }
          }
          &.cats-example {
            &::before {
              content: "活用事例";
            }
          }
        }
        time {
          position: absolute;
          top:0;
          right: 0;
        }
      }
      .main-contents {
        width: 68%;
        max-width: 820px;
        @include media(tab) {
          width: 100%;
          max-width: inherit;
        }
        .write-area {
          .mv-area {
            figure {
              margin-bottom: 20px;
              text-align: center;
            }
          }
          >p,
          >div {
            margin-bottom: 20px;
            img {}
          }
          a {
            color: $text_link_color;
            font-weight: bold;
            text-decoration: underline;
            img {
              text-decoration: none;
            }
          }
          >ul,>ol,>div ul,>div ol {
            margin: 20px auto 30px;
            width: 100%;
            max-width: 630px;
            padding: 1em;
            background: $key_color_light2;
            border-radius: 20px;
            border: 1px dotted $key_color;
            li {
              position: relative;
              padding-left: 1.8em;
              margin-bottom: 10px;
              line-height: 1.5;
              &::before {
                content: "";
                display: block;
                width: 15px;
                height: 15px;
                background-color: $key-color;
                border-radius: 300px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
              }
              &:last-child {margin-bottom: 0;}
            }
          }
          >ol, >div ol {
            li {
              list-style: decimal;
              margin-left: 1.4em;
              padding-left: 0.5em;              
              &::marker {
                font-weight: bold;
                font-size: 0.9em;
                color: #008149;
              }
              &::before {
                content: none;
              }
            }
          }

          ul.free-style-list {
            margin: 20px auto 30px;
            width: 100%;
            max-width: 630px;
            padding: 1em;
            background: $key_color_light2;
            border-radius: 20px;
            border: 1px dotted $key_color;
            li {
              position: relative;
              padding-left: 2em;
              margin-bottom: 10px;
              line-height: 1.5;
              &::before {
                content: none;
              }
              &:last-child {margin-bottom: 0;}
              span {
                position: absolute;
                top: 0.2em;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20px;
                height: 20px;
                background: $key_color;
                border-radius: 100px;
                color: #ffffff;
                padding: 0.2em;
                font-size: 0.8em !important;
              }
            }
          }

          >h2 {
            background: $key_color_light2;
            color: $key_color;
            font-size: 24px;
            font-weight: bold;
            position: relative;
            margin-bottom: 20px;
            padding: 0.5em 0.5em 0.5em 1.5em;
            @include media(sp) {
              font-size: 21px;
            }
            &::before {
              content: "";
              display: block;
              background: $key_color;
              width: 8px;
              height: calc(100% - 1em);
              position: absolute;
              top:0.5em;
              left: 0.5em;
            }
          }
          > h3 {
            color: $key_color;
            font-size: 21px;
            font-weight: bold;
            position: relative;
            margin-bottom: 20px;
            padding: 0.5em 0 0.5em 1.8em;
            border-bottom:1px solid $key_color;
            @include media(sp) {
              font-size: 18px;
            }
            &::before {
              content: "";
              display: block;
              background: $key_color;
              width: 8px;
              height: calc(100% - 1em);
              position: absolute;
              top: 0.5em;
              left:0;
              @include media(sp) {
                width: 6px;
              }
            }
            &::after {
              content: "";
              display: block;
              background: $key_color;
              width: 8px;
              height: calc(100% - 1em);
              position: absolute;
              top: 0.5em;
              left: 0.8em;
            }

          }
          > h4 {
            color: $key_color;
            font-size: 18px;
            font-weight: bold;
            position: relative;
            padding: 0.05em 0 0.05em 1em;
            margin-bottom: 20px;            
            &::before {
              content: "";
              display: block;
              background: $key_color;
              width: 8px;
              height: 100%;
              position: absolute;
              top: 0;
              left:0;              
            }            
          }
          > h5 {
            font-weight: bold;
            position: relative;
            padding-left: 1.5em;
            margin-bottom: 20px;
            &::before {
              content:">>";
              color: $key_color;
              position: absolute;
              font-weight: bold;
              top: 0.2em;
              left: 0;
              font-size: 0.8em;
            }
          }
          > h6 {
            font-weight: bold;
            position: relative;
            padding-left: 1.5em;
            margin-bottom: 20px;
            &::before {
              content:"◇";
              position: absolute;
              font-weight: bold;
              top: 0.25em;
              left: 0.25em;
              font-size: 0.8em;
            }
          }

          >table , >div table{
            width: 100%;
            tr {
              border-top: 1px solid $line_gray ;
              th {
                text-align: center;
                padding: 0.5em;
                min-width: 3em;
              }
              td {
                padding: 0.5em;
              }
              >* {
                border-right: 1px solid $line_gray ;
                box-sizing: content-box;
                &:last-child {
                  border-right: none;
                }
              } 
            }
            tr:last-child {
              border-bottom: 1px solid $line_gray;
            }
            tr:nth-child(odd) {
              background: $bg_color;
            }
          }
          .button-area {
            >p {
              text-align: center;
              margin-bottom: 20px;
              color: $key_color;
              span {
                padding: 0 0.5em;
                position: relative;
                &::before {
                  content: "";
                  display: block;
                  width: 1em;
                  height: 1em;
                  border-right: 1px solid $key_color;
                  transform: rotate(-45deg);
                  position: absolute;
                  top:0.5em; 
                  left:-1.5em;
                }
                &::after {
                  content: "";
                  display: block;
                  width: 1em;
                  height: 1em;
                  border-left: 1px solid $key_color;
                  transform: rotate(45deg);
                  position: absolute;
                  top:0.5em; 
                  right:-1.5em;
                }
              }
              >a {
                display: block;
                width: 100%;
                text-decoration: none;
                max-width: 480px;
                margin: 0 auto 30px;
                color: $key_color;
                font-size: 21px;
                font-weight: bold;
                font-family: 'Zen Maru Gothic', sans-serif;
                text-align: center;
                border: 1px solid $key_color;
                padding: 0.5em;
                border-radius: 100px;
                background: url(../img/icon/icon-arrow-u.png) right 10px center no-repeat;
                background-color: #ffffff;
                box-shadow: 5px 5px $key_color_light;
                @include media(sp) {
                  width: 90%;
                }
                span {
                  position: relative;
                  &::before {
                    content: "";
                    background: url(../img/icon/icon-shorui.png) center center no-repeat;
                    width: 20px;
                    height: 22px;
                    background-size: contain;
                    position: absolute;
                    top: 50%;
                    margin-top: -11px;
                    left: -1.5em;
                    border-right: none;
                    transform: none;
                  }
                  &::after {
                    border-left: none;
                    transform: none;
                  }
                }
              }
              >a:hover {
                box-shadow: none;
                color: #ffffff;
                background: url(../img/icon/icon-arrow-u-on.png) right 10px center no-repeat;
                background-color: $key_color;
                span {
                  &::before {
                    content: "";
                    background: url(../img/icon/icon-shorui-on.png) center center no-repeat;
                  }
                }
              }
            }
          }
          .button-area-l-size {
            margin: 50px auto;
            p {
              margin-bottom: 0;
            }
            br {
              display: none;
            }
            a {
              font-family: "Noto Sans JP",YuGothicM,YuGothic,"Hiragino Kaku Gothic Pro","Meiryo","sans-serif";
              position: relative;
              text-decoration: none;
              background: none;
              max-width: 740px;
              padding: 40px 100px;
              display: flex;
              justify-content: space-around;
              align-items: center;
              box-shadow: none;
              border: none;
              margin-left: auto;
              margin-right: auto;
              @include media (tab) {
                width: 96%;
                margin:  0 auto;
                flex-direction: column;
              }
              &::before {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                z-index: 10;
                border: 1px solid $key-color;
                position: absolute;
                top:0;
                left: 0;
                border-radius: 600px;
              }
              &::after {
                content: "";
                display: block;
                width: 100%;
                max-width: 740px;
                height: 100%;
                border-radius: 600px;
                background: $key_color_light2;
                z-index: 5;
                position: absolute;
                top: 15px;
                left: 20px;
                @include media(tab) {
                  left: 10px;
                }
              }
              span.head {
                position: absolute;
                top:-1em;
                font-size: 18px;
                font-weight: bold;
                color: $key-color;
                background: #ffffff;
                padding: 0 1em;
                left: 50%;
                transform: translateX(-50%);
                z-index: 10;

                &::before,
                &::after {
                  content: none;
                }

              }
              span.text {
                text-align: left;
                color: $c_text;
                font-size: 16px;
                font-weight: normal;
                line-height: 1.5;
                display: block;
                z-index: 10;

                &::before,
                &::after {
                  content: none;
                }
                br {
                  display: block;
                  @include media (tab) {
                    display: none;
                  }
                }
              }
              span.tel {
                text-align: left;
                font-size: 36px;
                color: $key-color;
                font-weight: bold;
                z-index: 10;
                position: relative;
                @include media(tab) {
                  font-size: 28px;
                }
                &::before {
                  content: "";
                  display: block;
                  background: url(../img/icon/icon-tel.png) center center no-repeat;
                  background-size: contain;
                  width: 38px;
                  height: 38px;
                  left: -45px;
                  top: 10px;
                  position: absolute;
                  @include media(tab) {
                    width: 28px;
                    top: 5px;
                    left: -35px;
                  }
                }

                

                
                &::after {
                  content: none;
                }
              }

              &:hover {
                background-image: none;
                background-color: #ffffff;
                span.head {
                  color: $key-color;
                }
                span.tel {
                  color: $key-color;
                }
              }
            }
            >a {
              font-family: "Noto Sans JP",YuGothicM,YuGothic,"Hiragino Kaku Gothic Pro","Meiryo","sans-serif";
              position: relative;
              background: none;
              background-color: inherit;
              box-shadow: none;
              max-width: 600px;
              padding: 0;
              border: none;
              @include media(sp) {
                width: 100%;
                max-width: inherit;
              }
              &::after {
                content: "";
                display: block;
                width: 100%;
                max-width: 600px;
                height: 100%;
                border-radius: 600px;
                background: $key_color_light2;
                z-index: 5;
                position: absolute;
                top: 15px;
                left: 20px;
                @include media(sp){
                  left: 10px;
                } 
              }
              >div {
                display: flex;
                justify-content: space-around;
                align-items: center;
                position: relative;
                z-index: 10;
                border: 1px solid $key_color;
                border-radius: 600px;
                padding: 1.8em 50px;
                @include media(sp) {
                  flex-direction: column;
                }
                .head {
                  font-size: 16px;
                  padding: 0 1em;
                  display: inline-block;
                  margin: 0 auto;
                  background-color: #ffffff;
                  text-align: center;
                  position: absolute;
                  top:-1em;
                  left: 50%;
                  transform: translateX(-50%);
                  -webkit-transform: translateX(-50%);
                  -ms-transform: translateX(-50%);
                  &::before {
                    content: none;
                  }
                }

                .text {
                  text-align: left;
                  color: $c_text;
                  font-size: 16px;
                  font-weight: normal;
                  line-height: 1.5;
                  &::before {
                    content: none;
                  }
                  @include media(sp) {
                    br{
                      display: none;
                    }
                  }
                }
                .tel {
                  text-align: left;
                  font-size: 36px;
                  @include media(sp) {
                    font-size: 28px;
                  }
                  &::before {
                    background: url(../img/icon/icon-tel.png) center center no-repeat;
                    background-size: contain;
                    width: 38px;
                    height: 38px;
                    margin-top: -19px;
                    left: -1.2em;
                    @include media(sp) {
                      width: 28px;
                    }
                  }
                }
              }
            }
            >a:hover {
              box-shadow: none;
              color: $key_color;
              background: none;
              background-color: #ffffff;
              span {
                &::before {
                  content: none;
                }
              }
              .tel {
                &::before {
                  content: "";
                  background: url(../img/icon/icon-tel.png) center center no-repeat;
                  background-size: contain;
                }
              }
            }

          }
        }
        
      }


      .side-contents {
        width: 30%;
        max-width: 320px;
        @include media(tab) {
          width: 100%;
          max-width: inherit;
          border-radius: 30px;
          background: $bg_color;
          padding: 20px 0 20px;
        }

        .side-navi {
          .contents-shien {
            border: none;
            padding: 0;
            @include media(tab) {
              width: 100%;
              max-width: inherit;
              margin: 0 0 20px;
              background: $bg_color;
            }
    
            h4 {
              margin-bottom: 10px;
              display: flex;
              font-weight: bold;
              font-size: 18px;
              padding-left: 30px;
              @include media(tab) {
                font-size: 21px;
                padding-left: 65px;
                margin-left: 8px;
              }
              @include media(sp) {
                padding-left: 50px;
              }

              &::after {
                content: none !important;
              }
              .icon-green {
                width: 25px;
                height: 25px;
                position: absolute;
                left: 0;
                @include media(tab) {
                  width: 50px;
                  height: 50px;
                }
                @include media(sp) {
                  width: 40px;
                  height: 40px;
                }
                img {
                  width: 18px;
                  @include media(tab) {
                    width: 32px;
                  }
                  @include media(sp) {
                    width: 25px;
                  }
                }  
              }
              .text {
                @include media(sp) {
                    font-size: 21px;
                }
              }
            }
            >ul {
                width: 100%;
                margin-bottom: 50px;
                max-height: inherit;
                overflow: hidden;
                transition: inherit;
                @include media(tab) {
                  margin-bottom: 0;
                  background: none;
                }
                li {
                  border-bottom: 1px dotted $line_gray;
                  background: $bg_color;
                  margin-bottom: 0;
                  @include media(tab) {
                    background-color: none;
                  }
                  &:first-child {
                    border-radius: 10px 10px 0 0;
                    @include media(tab) {
                      border-radius: 0;
                    }
                  }
                  &:last-child {
                    border-radius: 0 0 10px 10px;
                    @include media(tab) {
                      border-radius: 0;
                    }
                  }
    
                  a {
                    display: block;
                    padding: 0.5em 0.5em 0.5em 1.5em;
                    position: relative;
                    &::before {
                      content: none;
                      @include media(tab) {
                        content:"＊";
                        position: absolute;
                        left:0.5em;
                        color: $key_color;
                      }
                    }
                    &:after {
                      content: none;
                    }

                    &.on,
                    &:hover {
                      padding: 0.5em 0.5em 0.5em 1.5em;
                      color: $key_color;
                      background: none;
                      border-radius: inherit;
                      &::before {
                        content:">";
                        position: absolute;
                        left:0.5em;
                        color: $key_color;
                        @include media(sp){
                          content:"＊";
                        }
                      }
                    }
                  }
                }
                :last-child {
                  border-bottom: none;
                }
              }
          }

        }

        /*.banner-area {
          ul{
            li{
              height: 160px;
              background: $bg_color;
              margin-bottom: 20px;
              a{
                figure {}
              }
            }
          }
        }*/
      }

      .side-contents {
        width: 30%;
        max-width: 320px;
        @include media(tab) {
          width: 100%;
          max-width: inherit;
        }
        .side-navi {
          @include media(sp) {
            border-radius: 20px;
            background: $bg_color;
            padding: 15px 8px;
            border-radius: 30px;
          }
          h4 {
            margin-bottom: 10px;
            display: flex;
            font-weight: bold;
            @include media(sp){
              display: grid;
              grid-template-columns: 45px auto;
              grid-template-rows: 1fr;
            }
            .icon-green {
              width: 30px;
              height: 30px;
              margin-right: 10px;
              @include media(sp) {
                width: 40px;
                height: 40px;
              }
              img {
                width: 20px;
                @include media(sp) {
                  width: 25px;
                }
              }  
            }
            .text {
              @include media(sp) {
                font-size: 21px;
              }
            }
          }
          >ul {
            width: 100%;
            margin-bottom: 50px;
            @include media {
              margin-bottom: 0;
            }

            li {
              border-bottom: 1px dotted $line_gray;
              background: $bg_color;
              @include media(sp) {
                background-color: none;
              }
              &:first-child {
                border-radius: 10px 10px 0 0;
                @include media(sp) {
                  border-radius: 0;
                }
              }
              &:last-child {
                border-radius: 0 0 10px 10px;
                @include media(sp) {
                  border-radius: 0;
                }
              }

              a {
                display: block;
                padding: 0.5em 0.5em 0.5em 1.5em;
                position: relative;
                @include media(sp) {
                  &::before {
                    content:"＊";
                    position: absolute;
                    top: 0.5em;
                    left:0.5em;
                    color: $key_color;
                  }
                }                    

                &.on,
                &:hover {
                  padding: 0.5em 0.5em 0.5em 1.5em;
                  color: $key_color;
                  &::before {
                    content:">";
                    position: absolute;
                    top: 0.5em;
                    left:0.5em;
                    @include media(sp){
                      content:"＊";
                    }
                  }
                }
              }
            }
            :last-child {
              border-bottom: none;
            }
          }
        }

        .side-navi.cats-management {
          .menu-seminar,
          .menu-collaboration,
          .menu-subsidy,
          .menu-loan,
          .menu-upbringing,
          .menu-welfare,
          .menu-training,
          .menu-founding {
            display: none;
          }
        }

        .side-navi.cats-seminar,
        .side-navi.seminar-1,
        .side-navi.seminar-2,
        .side-navi.seminar-3 {
          .menu-management,
          .menu-collaboration,
          .menu-subsidy,
          .menu-loan,
          .menu-upbringing,
          .menu-welfare,
          .menu-training,
          .menu-founding {
            display: none;
          }
        }

        .side-navi.cats-collaboration {
          .menu-seminar,
          .menu-management,
          .menu-subsidy,
          .menu-loan,
          .menu-upbringing,
          .menu-welfare,
          .menu-training,
          .menu-founding {
            display: none;
          }
        }

        .side-navi.cats-subsidy {
          .menu-seminar,
          .menu-collaboration,
          .menu-management,
          .menu-loan,
          .menu-upbringing,
          .menu-welfare,
          .menu-training,
          .menu-founding {
            display: none;
          }
        }

        .side-navi.cats-loan {
          .menu-seminar,
          .menu-collaboration,
          .menu-subsidy,
          .menu-management,
          .menu-upbringing,
          .menu-welfare,
          .menu-training,
          .menu-founding {
            display: none;
          }
        }

        .side-navi.cats-upbringing {
          .menu-seminar,
          .menu-collaboration,
          .menu-subsidy,
          .menu-loan,
          .menu-management,
          .menu-welfare,
          .menu-training,
          .menu-founding {
            display: none;
          }
        }

        .side-navi.cats-welfare {
          .menu-seminar,
          .menu-collaboration,
          .menu-subsidy,
          .menu-loan,
          .menu-upbringing,
          .menu-management,
          .menu-training,
          .menu-founding {
            display: none;
          }
        }

        .side-navi.cats-training {
          .menu-seminar,
          .menu-collaboration,
          .menu-subsidy,
          .menu-loan,
          .menu-upbringing,
          .menu-welfare,
          .menu-management,
          .menu-founding {
            display: none;
          }
        }

        .side-navi.cats-founding {
          .menu-seminar,
          .menu-collaboration,
          .menu-subsidy,
          .menu-loan,
          .menu-upbringing,
          .menu-welfare,
          .menu-management,
          .menu-training {
            display: none;
          }
        }

        .side-navi.cats-example {
          .menu-seminar,
          .menu-collaboration,
          .menu-loan,
          .menu-upbringing,
          .menu-training,
          .menu-welfare,
          .menu-founding {
            display: none;
          }
        }
        /*.banner-area {
          ul{
            li{
              height: 160px;
              background: $bg_color;
              margin-bottom: 20px;
              a{
                figure {}
              }
            }
          }
        }*/
      }
    }

  }

  #layout-shousai.uncategorized {
    .side-contents,
    .sub-top time {
      display: none;
    }
    .sub-top,
    .main-contents {
      width: 100%;
      max-width: inherit;
    }
  }

/**▲▲▲▲▲#page-shousai▲▲▲▲▲**/


/**▼▼▼▼▼#page-about▼▼▼▼▼**/
  #layout-about {
    .mv-area { 
      h2 {
        span {
        }
      }
      .mv-slider {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto 50px;
        @include media(tab) {
          width: 96%;
        }

        .slick-dots {
          bottom: 20px;
          li {
            button {
              &::before {
                content: "";
                display: block;
                width: 14px;
                height: 14px;
                background: $key-color;
                border: 1px solid $key-color;
                border-radius: 300px;
                opacity: 1;
              }
            }
            &.slick-active {
              button {
                &::before {
                  content: "";
                  display: block;
                  width: 16px;
                  height: 16px;
                  background: #ffffff;
                  border: 2px solid $key-color;
                  border-radius: 300px;
                }
              }
            }
          }
        }

        img {
          border-radius: 0 0 50px 50px;
          @include media(tab) {
            border-radius: 0 0 30px 30px;
          }
          @include media(sp) {
            border-radius: 0 0 20px 20px;
          }
        }
      }
    }
    .no1 {
      .inner {
        width: 100%;
        max-width: 1000px;
        margin: 0 auto 100px;
        position: relative;
        @include media(tab) {
          width: 96%;
        }
        .sub-top {
          display: flex;
          margin-bottom: 50px;
          @include media(tab) {
            flex-direction: column;
          }
          h3 {
            font-size: 18px;
            line-height: 1.2;
            @include media(tab) {
              margin-bottom: 20px;
            }
            @include media(sp) {
              margin-bottom: 40px;
            }
            span {
              color: $key_color;
              font-size: 36px;
              font-weight: bold;
              padding-right: 20px;
              display: flex;
              transform: translateY(-7px);
              -webkit-transform: translateY(-7px);
              -ms-transform: translateY(-7px);
              @include media (sp) {
                font-size: 28px;
              }
              &::after {
                content: "";
                display: block;
                width: 1em;
                height: 1em;
                border-right: 1px solid $line_gray;
                transform: translateY(7px);
                -webkit-transform: translateY(7px);
                -ms-transform: translateY(7px);
                @include media(tab) {
                  display: none;
                }
              }
            }
          }
          p {
            display: inline-block;
            padding-left: 20px;
            line-height: 1.2;
            @include media(tab) {
              padding-left: 0;
              font-weight: bold;
              br {
                display: none;
              }
            }
          }
        }
        .info-contents {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            width: 48%;
            padding-bottom: 10px;
            border-bottom: 1px dotted $line_gray;
            margin-bottom: 20px;
            @include media(sp) {
              width: 100%;
            }
            a {
              display: block;
              .box {
                .date {
                  margin-bottom: 10px;
                  display: block;
                }
                p {}
              }
            }
          }
        }

        .btn-all {
          position: absolute;
          top: 10px;
          right: 0;
          @include media(tab){
            border-left: 1px solid $line_gray;
            padding-left: 10px;
          }
        a {
            font-weight: bold;
            display: flex;
            align-items: center;
            @include media(tab) {
              flex-direction: column-reverse;
              justify-content: center;
            }
            span.arrow-cirlue.green {
              border: 1px solid $key_color_light2;
              margin-left: 20px;
              @include media(tab) {
                margin-left: 0;
                margin-bottom: 5px;
              }
            }
          }

        }
      }
    }
    .pdf-area {
      margin-bottom: 150px;
      .inner {
        padding: 50px 0;
        background: $bg_color;
        >ul {
          width: 100%;
          max-width: 1000px;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include media(tab) {
            width: 96%;
            flex-direction: column;
          }          
            >li {
              background: #ffffff;
              border-radius: 30px;
              box-shadow: 0 0 5px rgba(0,0,0,0.3);
              width: 48%;
              @include media(tab) {
                width: 100%;
                max-width: 540px;
                margin-bottom: 50px;
              }
              &:last-child {
                margin-bottom: 0;
              }
              .contents {
                display: flex;
                padding: 20px;
                justify-content: space-between;
                align-items: center;
                @include media(tab) {
                  justify-content: space-around;
                }
                @include media(sp) {
                  justify-content: center;
                  flex-direction: column;
                }
              .photo-box {
                  width: 25%;
                  max-width: 110px;
                  @include media(sp) {
                    width: 100%;
                    max-width: inherit;
                    margin-bottom: 20px;
                  }
                  a {
                    display: flex;
                    align-items:  center;
                    justify-content: center;
                    flex-direction: column;
                    @include media(sp) {
                      flex-direction: row;
                    }
                    figure {
                      img {
                        box-shadow: 0 0 5px rgba(0,0,0,0.3);
                      }
                    }
                    p {
                      padding: 0.2em 1em;
                      text-align: center;
                      border-radius: 100px;
                      font-size: 0.8em;
                      letter-spacing: -0.1em;
                      @include media(sp) {
                        font-size: 1em;
                        padding: 1em;
                        height: auto;
                      }
                    }
                  }


                }
                .text-box {
                  width: 73%;
                  max-width: 300px;
                  min-height: 272px;
                  border-radius:30px;
                  @include media(sp) {
                    width: 100%;
                    max-width: inherit;
                    min-height: inherit;
                  }
                  h4 {
                    text-align: center;
                    font-weight: bold;
                    margin-bottom: 20px;
                    font-size: 21px;
                  }
                  p {
                    margin-bottom: 20px;
                  }
                  .icon-word {
                    margin-bottom: 0;
                    a {
                      display: flex;
                      font-size: 0.9em;
                      align-items: center;
                      @include media(sp) {
                        font-size: 1.2em;
                      }
                      span.text {
                        font-weight: bold;
                        font-size: 0.8em;
                        br {
                          
                        }
                      }
                      span.arrow-cirlue {
                        margin-left: 1em;
                        width: 20px;
                        height: 20px;
                        path {}
                      }
                    }
                  }
                }
              }
            }

            li.news {
              .contents {
                .photo-box {
                  a {
                    figure {
                    }
                    p {
                      color: $key_color;
                      background: $key_color_light2;
                    }
                    &:hover {
                      p {
                        color: #ffffff;
                        background: $key_color_light;
                      }  
                    }
                  }
                }
                .text-box {
                  padding: 1.5em 1em;
                  background: $key_color_light2;
                  h4 {
                    color: $key_color;
                  }
                  p {
                  }
                  .icon-word {
                    a {
                      justify-content: end;
                      span.text {
                      }
                      span.arrow-cirlue {
                        border: 1px solid $key_color;
                        path {}
                      }
                      &:hover {
                        color: $key_color;
                        span.arrow-cirlue {
                          background-color: $key_color;
                          path {
                            fill:#ffffff
                          }
                        }
                      }
                            
                    }
                  }
                }
              }            
            }

            li.life {
              .contents {
                .photo-box {
                  a {
                    figure {
                    }
                    p {
                      background: $blue_color_light;
                      line-height: 1.5;
                      color: $blue_color;
                    }

                    &:hover {
                      p {
                        color: #ffffff;
                        background: $blue_color;
                      }  
                    }
                  }
                }
                .text-box {
                  padding: 1em;
                  background: $blue_color_light;
                  .log-in-switch {
                    display: flex;
                    justify-content: space-between;
                    font-weight: bold;
                    @include media(sp) {
                      flex-direction: column;
                    }
                    .icon-word {
                      letter-spacing: -0.1em;
                      @include media(sp) {
                        margin-bottom: 20px;
                      }
                      a {
                        @include media(sp){
                          justify-content: end;
                        }
                        span.text {
                          br {
                            @include media(sp){
                              display: none;
                            }
                          }
                        }
                        span.arrow-cirlue {
                          border: 1px solid $blue_color;
                          path {
                            fill: $blue_color;
                          }
                        }
                        &:hover {
                          color: $blue_color;
                          span.arrow-cirlue {
                            background: $blue_color;
                            path {
                              fill: #ffffff;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
        }
      }
    }

    .no3 {
      .inner {
        width: 100%;
        max-width: 1000px;
        margin: 0 auto 50px;
        border-radius: 50px;
        background: $bg_color;
        padding: 0 50px 50px;
        @include media(tab) {
          border-radius: 0;
          padding: 0 2% 50px;
        }
        .type-a {
          margin-bottom: 30px;
          width: 480px;
          @include media(tab) {
            width: 400px;
          }
          @include media(sp) {
            width: 300px;
          }

          br {
            display: none;
            @include media(tab) {
              display: block;
            }
          }
          &::before {
            width: 480px;
            border-radius: 300px/100px;
            @include media(tab) {
              width: 400px;
            }
            @include media(sp) {
              width: 300px;
            }
          }
        }
        p {
          margin-bottom: 20px;
          position: relative;
        }
        ul {
          display: flex;
          position: relative;
          width: 100%;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: 30px;
          @include media(tab) {
            flex-direction: column;
            margin-bottom: 0;
          }
          li {
            width: 48%;
            position: relative;
            margin-bottom: 20px;
            font-weight: bold;
            @include media(tab){
              width: 100%;
            }
            @include media(sp) {
              font-size: 18px;
            }
            &:last-child {
              @include media(tab) {
                margin-bottom: 50px;
              }
            }
            div {
              display: flex;
              padding: 10px;
              align-items: center;
              justify-content: center;
              background: #ffffff;
              border-radius: 100px;
              height: 70px;
              .ex1 {
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 28px;
                background: $line_gray;
                color: #ffffff;
                border-radius: 300px;
                position: absolute;
                top: 10px;
                left:10px
              }
              .text {
                @include media(sp){
                  line-height: 1.5;
                }
                .ex2 {
                  color: $key_color;
                }
                br {
                  display: none;
                  @include media(sp) {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }

    .banner-area {
      ul {
        width: 100%;
        max-width: 1000px;
        margin: 0 auto 100px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        @include media(tab) {
          width: 96%;
        }
        li {
          width: 32%;
          margin: 0 0.5% 20px;
          @include media(tab) {
            width: 49%;
          }
          @include media(sp) {
            width: 100%;
            max-width: 320px;
            margin: 0 auto 20px;
            text-align: center;
          }
          a {
            figure {
              img {}
            }
          }
        }
      }
    }
  //////////////////////*▼▼logoinform-style▼▼*/
    .login-form.on {
      display: block;
    }
    .login-form {
      display: none;
      background: rgba(0,0,0,0.8);
      width: 100%;
      height: 100%;
      padding: 30px 0;
      position: absolute;
      top:0;
      left: 0;
      z-index: 9910;
      .inner {
        position: fixed;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%);
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        width: 960px;
        margin: 0 auto;
        background: #ffffff;
        border-radius: 80px;
        padding-bottom: 20px;
        @include media(w-1000){
          width: 750px;
        }
        @include media(tab){
          width: 560px;
          scrollbar-width: none;  
          border-radius: 50px;        
        }

        @include media(sp){
          width: 340px;
        }

        .number-btn {
          display: none;
          @include media(tab){
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            color: #ffffff;
            width: 1.5em;
            height: 1.5em;
            font-size: 18px;
            font-weight: bold;
            background-color: $orange_color;
            border-radius: 300px;
            &.p-1 {
              position: absolute;
              top: 110px;
              right: 80px;
              @include media(sp) {
                top: 103px;
                right: 20px;
              }
              &::after {
                content: "";
                display: block;
                border: 10px solid transparent;
                border-top: 10px solid $orange_color;
                position: absolute;
                top:50%;
                transform: translateY(-25%);
                left: 1.5em;
              }
            }
            &.p-2 {
              position: absolute;
              top: 205px;
              left: 70px;
              @include media(sp) {
                top: 190px;
                left: 28px;
              }
              &::after {
                content: "";
                display: block;
                border: 10px solid transparent;
                border-top: 10px solid $orange_color;
                position: absolute;
                top:50%;
                transform: translateY(-25%);
                left: -1.5em;
              }
            }
            &.p-3 {
              position: absolute;
              bottom:102px;
              right: 83px;
              @include media(sp) {
                bottom:127px;
                right: 20px;
              }
              &::after {
                content: "";
                display: block;
                border: 10px solid transparent;
                border-top: 10px solid $orange_color;
                position: absolute;
                top:50%;
                transform: translateY(-25%);
                left: 1.5em;
              }
            }
          }
        }

        .sub-top {
          padding-top: 20px;
          margin-bottom: 20px;
          h3 {
            font-size: 21px;
            text-align: center;
            font-weight: bold;
            margin-bottom: 10px;
            @include media(tab) {
              font-size: 18px;
            }
          }
          p {
            margin: 0 auto;
            text-align: center;
            @include media(tab) {
              padding: 0 10px;
              font-size: 0.8em;
            }
            span {
              color: $orange_color;
            }
          }
        }

        .comment-position {
          @include media(tab) {
            height: 430px;
            overflow-y: scroll;
            overflow-x:hidden;
            width: 96%;
            margin: 0 auto;
          }
          position: relative;
          .form-area {
            width: 380px;
            margin: 0 auto;
            padding:20px 15px;
            background: #ffffff;
            border-radius: 30px;
            border: 10px solid $blue_color_light;
            @include media(tab) {
              margin-bottom: 50px;
              border: 5px solid $blue_color_light;
            }
            @include media(sp) {
              width: 96%;

            }
            h4 {
              color: $key_color;
              font-size: 32px;
              text-align: center;
              font-weight: bold;
              line-height: 0.8;
              margin-bottom: 10px;
              @include media(tab) {
                font-size: 28px;
              }
              span {
                font-size: 14px;
                @include media(tab) {
                  font-size: 12px;
                }
              }
            }
            .form-contents {
              margin-bottom: 10px;
              display: flex;
              flex-wrap: wrap;
              p {
                @include media(tab) {
                  font-size: 0.9em;
                }
                &.check-modoki {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  &::before {
                    content: "";
                    width: 15px;
                    height: 15px;
                    display: block;
                    background: #ffffff;
                    border: 1px solid $line_gray;
                    margin-right: 10px;
                    border-radius: 3px;
                  }
                }
              }
              .sample-area {
                width: 100%;
                background: #ffffff;
                padding: 0.2em 0.5em;
                border: 1px solid $line_gray;
              }
              a {
                width: 100%;
                display: block;
                text-align: right;
              }
              .box {
                display: flex;
                input {
                  width: 1em;
                  margin-right: 10px;
                }
                label {
                }
              }
            }
            .ex3 {
              margin-bottom: 10px;
              .btn-modoki {
                width: 100%;
                text-align: center;
                font-weight: bold;
                color: #ffffff;
                padding: 1em;
                border-radius: 300px;
                background: url(../img/icon/icon-arrow-r.png) right 10px center no-repeat;
                background-color: $key_color;
                border: 1px solid $key-color;
                @include media(tab) {
                  padding: 0.3em;
                  background: url(../img/icon/icon-arrow-r.png) right 20px center no-repeat;
                  background-color: $key_color;
                  background-size: 18px;
                  }
              }
            }
            ul {
              li {
                display: flex;
                align-items: center;
                .arrow-cirlue {
                  width: 20px;
                  height: 20px;
                  border: 1px solid $key_color;
                  margin-right: 0.5em;
                }
              }
            }
          }
          .comment-box {
            position: absolute;
            padding: 1em;
            width: 250px;
            border: 2px solid $orange_color;
            border-radius: 20px;
            background: #ffffff;
            font-size: 0.8em;
            @include media(tab) {
              position: static;
              width: 300px !important;
              margin:  0 auto 20px;
            }
            p {
              @include media(tab) {
                position: relative;
                padding-top: 1.5em ;
              }
              .ex1 {
                color: $orange_color;
                @include media (tab) {
                  font-size: 1.5em;
                  position: absolute;
                  top: -10px;
                  left: 0;
                }
              }
              .ex2 {
                font-weight: bold;
                span {
                  color: $orange_color;
                }
              }
            }
            &.part1 {
              top:75px;
              right: 20px;
              @include media(w-1000) {
                width: 180px;
                right: 10px;
                top: 80px;
              }
              
              &::before {
                content: "";
                display: block;
                border: 15px solid transparent;
                border-right: 50px solid $orange_color;
                position: absolute;
                left: -65px;
                top: 40px;
                @include media(w-1000) {
                  border: 15px solid transparent;
                  border-right: 25px solid $orange_color;
                  position: absolute;
                  left: -40px;
                  top: 40px;  
                }
                @include media(tab) {
                  content: none;
                }
              }
              &::after {
                content: "";
                display: block;
                border: 15px solid transparent;
                border-right: 50px solid #ffffff;
                position: absolute;
                left: -58px;
                top: 40px;
                @include media(w-1000) {
                  border: 15px solid transparent;
                  border-right: 25px solid #ffffff;
                  position: absolute;
                  left: -36px;
                  top: 40px;  
                }
                @include media(tab) {
                  content: none;
                }
              }

            }
            &.part2 {
              top:190px;
              left: 20px;
              @include media(w-1000) {
                width: 180px;
                left: 10px;
              }
              &::before {
                content: "";
                display: block;
                border: 15px solid transparent;
                border-left: 50px solid $orange_color;
                position: absolute;
                right: -65px;
                top: 30px;
                @include media(w-1000) {
                  border: 15px solid transparent;
                  border-left: 25px solid $orange_color;
                  position: absolute;
                  right: -40px;
                  top: 30px;
                }
                @include media(tab) {
                  content: none;
                }
              }
              &::after {
                content: "";
                display: block;
                border: 15px solid transparent;
                border-left: 50px solid #ffffff;
                position: absolute;
                right: -57px;
                top: 30px;
                @include media(w-1000) {
                  border: 15px solid transparent;
                  border-left: 25px solid #ffffff;
                  position: absolute;
                  right: -37px;
                  top: 30px;
                }
                @include media(tab) {
                  content: none;
                }
              }
            }
            &.part3 {
              top:315px;
              right: 20px;
              @include media(w-1000) {
                top:410px;
                right: 110px;
                width: 200px;
              }
              &::before {
                content: "";
                display: block;
                border: 15px solid transparent;
                border-right: 50px solid $orange_color;
                position: absolute;
                left: -65px;
                top: 20px;
                @include media(w-1000) {
                  border: 15px solid transparent;
                  border-bottom: 25px solid $orange_color;
                  top: -40px;
                  left: 30px;
                }
                @include media(tab) {
                  content: none;
                }
              }
              &::after {
                content: "";
                display: block;
                border: 15px solid transparent;
                border-right: 50px solid #ffffff;
                position: absolute;
                top: 20px;
                left: -58px;
                @include media(w-1000) {
                  border: 15px solid transparent;
                  border-bottom: 25px solid #ffffff;
                  position: absolute;
                  top: -37px;
                  left: 30px;
                }
                @include media(tab) {
                  content: none;
                }
              }
            }
          }
        }
      }
    }


  //////////////////////*▲▲logoinform-style▲▲*/

  }

/**▲▲▲▲▲#page-about▲▲▲▲▲**/

/**▼▼▼▼▼#page-menu▼▼▼▼▼**/
  #layout-menu {
    .mv-area { 
      margin-bottom: 60px;
      @include media(sp) {
        margin-bottom: 40px;
      }
      h2 {
        span {
        }
      }
    }
    .no1 {
      margin-bottom: 50px;
      .inner {
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;
        @include media(tab) {
          width: 96%;
        }
        >ul {
          >li {
            display: flex;
            justify-content: space-between;
            padding: 50px 0;
            border-bottom: 1px solid $key-color ;
            @include media(sp) {
              flex-direction: column;
              padding: 0  0 50px;
              margin-bottom: 50px;
            }
            &:nth-child(even) {
              flex-direction: row-reverse;
              @include media(sp) {
                flex-direction: column;
              }  
            }
            .photo-box {
              width: 48%;
              @include media(sp) {
                width: 100%;
                text-align: center;
                margin-bottom: 20px;
              }
            }
            .text-box {
              width: 48%;
              @include media(sp) {
                width: 100%;
              }
              h3 {
                color: $key-color;
                font-size: 21px;
                font-weight: bold;
                margin-bottom: 20px;
              }
              ul {
                padding-left: 1em;
                margin-bottom: 20px;
                li {
                  text-indent: -1em;
                  padding-left: 1em;
                  margin-bottom: 10px;
                  a {
                    &::before {
                      content: "＞";
                    }
                    &:hover {
                      color: $key-color;
                    }
                  }
                }
              }
              p {
                text-indent: -1em;
                padding-left: 1em;
              }
            }
          }
        }
      }
    }
    .no2 {
      margin-bottom: 100px;
      h3 {
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 50px;
        text-align: center;
      }

    }
  }

/**▲▲▲▲▲#layout-menu▲▲▲▲▲**/


/**▼▼▼▼▼#page-ticket▼▼▼▼▼**/
  #layout-ticket {
    .mv-area { 
      margin-bottom: 60px;
      @include media(sp) {
        margin-bottom: 50px;
      }
      h2 {
        span {
        }
      }
    }
    .no1 {
      margin-bottom: 100px;
      @include media(sp) {
        margin-bottom: 20px;
      }
      .inner {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        >p {
          font-size: 21px;
          text-align: center;
          font-weight: bold;
          margin-bottom: 50px;
          .ex1 {
            display: inline;
            @include media(tab) {
              display: none;
            }
          }
          .ex2 {
            display: none;
            @include media(tab) {
              display: inline;
            }
          }
        }
        >p.ex3 {
          font-weight: normal;
          font-size: 14px;
          width: 100%;
          max-width: 800px;
          margin: 0 auto 10px;
          padding: 0 1em;
        }
        ul {
          display: flex;
          justify-content: space-between;
          @include media(sp) {
            flex-wrap: wrap;
            justify-content: space-around !important;
          }
          li {
            margin-right: 2%;
            &:last-child {
              margin-right: 0;
            }
            @include media(sp) {
              width: 30%;
              margin-bottom: 35px;
            }
            a {
              figure {
                width: 100%;
                max-width: 190px;
                border: 5px solid $key_color_light;
                border-radius: 300px;
                margin-bottom: 20px;
                img {}
              }
              p {
                font-size: 18px;
                text-align: center;
                font-weight: bold;
                position: relative;
                line-height: 1.2;
                br {
                  display: none;
                  @include media(sp) {
                    display: block;
                  }
                }
              }
            }
            &.on,
            &:hover {
              a {
                figure {
                  border: 5px solid $key_color;
                }
                p {
                  color: $key-color;
                  &::after {
                    content: "";
                    display: block;
                    width: 1em;
                    border: solid 10px transparent;
                    border-top: solid 18px $key-color ;
                    text-align: center;
                    margin: 0 auto;
                    position: absolute;
                    bottom: -1.5em;
                    left: 50%;
                    margin-left: -0.5em;

                  }
                }
              }
            }
          }
        }
      }

    }
    .no2 {
      margin-bottom: 50px;
      .inner {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        table {
          width: 100%;
          tbody {
            tr {
              td {
                width: 25%;
                border-top: 1px solid $line_gray;
                border-right: 1px solid $line_gray;
                border-bottom: 1px solid $line_gray;
                color: $key-color;
                background: $key_color_light2;
                padding: 1em;
                font-weight: bold;
                text-align: center;
                vertical-align: middle;
                &:last-child {
                  border-right: none;
                }
              }
            }
          }
        }
        dl {
          .ticket-area {
            width: 100%;
            display: flex;
            @include media(sp) {
              flex-direction: column;
              margin-bottom: 50px;
            }
            .show-off {
              display: none !important;
            }
            .red {
              color: $c_accent;
            }
            .bold {
              font-weight: bold;
            }
            dt,dd {
              width: 25%;
              padding: 1em;
              border-bottom: 1px solid $line_gray ;
              border-right: 1px solid $line_gray ;
              box-sizing: content-box;
              box-sizing: border-box;
              word-break: break-all;
              @include media(sp) {
                width: 100%;
                box-sizing: border-box;
                padding-top: 0;
                padding-bottom: 50px;
                border-bottom: none ;
                border-right: none;

              }
            }
            .title-box {
              @include media(sp) {
                padding-right: 0;
                padding-left: 0;
              }
              &::before {
                @include media(sp) {
                  content:"－チケット名－";
                  width: 100%;
                  display: block;
                  background-color: $key-color;
                  color: #ffffff;
                  padding: 0.2em 0;
                  text-align: center;
                }
              }
              >.name {
                margin-bottom: 20px;
                font-weight: bold;
                @include media(sp) {
                  text-align: center;
                  background: $key_color_light2;
                  font-size: 1.2em;
                  padding: 0.2em 1em;
                  margin-bottom: 30px;
                }
                &::before {
                }
              }
              >.place {
                margin-bottom: 20px;
                @include media(sp) {
                  width: calc(100% - 2em);
                  margin-left: auto;
                  margin-right: auto;
                  position: relative;
                  padding-left: 4em;
                }
                &::before {
                  content: "■会場:";
                  width: 100%;
                  display: block;
                  font-weight: bold;
                  @include media(sp) {
                    position: absolute;
                    top: 0;
                    left: 0;
                  }
                }
              }

              >.photo {
                position: relative;
                width: 100%;
                @include media(sp) {
                  padding: 0 1em;
                }
                &::before {
                  content: "";
                  display: block;
                  padding-top: 75%;
                }
                figure {
                  position: absolute;
                  top:0;
                  left: 0;
                  bottom:0;
                  right: 0;
                  @include media(sp) {
                    width: calc(100% - 2em);
                    left: 1em;
                  }

                  img {
                    width: auto;
                    height: 100%;
                    object-fit: contain;
                    position: absolute;
                    left: 50%;
                    top: 0;
                    transform: translateX(-50%);
                    -webkit-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                  }
                }
              }
            }
            .term-box  {
              &::before {
                @include media(sp) {
                  content:"－イベント情報－";
                  background: $bg_color;
                  font-weight: bold;
                  width: 100%;
                  display: block;
                  padding: 0.2em 0;
                  text-align: center;
                  margin-bottom: 20px;
                }
              }
              >p {
                line-height: 1.5;
                margin-bottom: 20px;
                &.term {
                  position: relative;
                  @include media(sp) {
                    padding-left: 4em;
                  }
                  &::before {
                    content: "■期日:";
                    width: 100%;
                    display: block;
                    font-weight: bold;
                    @include media(sp) {
                      position: absolute;
                      top:0;
                      left: 0;
                    }
                  }
                }

                &.time {
                  position: relative;
                  @include media(sp) {
                    padding-left: 6em;
                  }
                  &::before {
                    content: "■営業時間:";
                    width: 100%;
                    display: block;
                    font-weight: bold;
                    @include media(sp) {
                      position: absolute;
                      top:0;
                      left: 0;
                    }
                  }
                }

                &.holiday {
                  position: relative;
                  @include media(sp) {
                    padding-left: 4em;
                  }
                  &::before {
                    content: "■休日:";
                    width: 100%;
                    display: block;
                    font-weight: bold;
                    @include media(sp) {
                      position: absolute;
                      top:0;
                      left: 0;
                    }
                  }
                }

                &.bikou {
                  position: relative;
                  @include media(sp) {
                    padding-left: 4em;
                  }
                  &::before {
                    content: "■備考:";
                    width: 100%;
                    font-weight: bold;
                    @include media(sp) {
                      position: absolute;
                      top:0;
                      left: 0;
                    }
                  }
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
              .button-event {
                a {
                  width: 90%;
                  margin: 30px auto 10px;
                  text-align: center;
                  display: block;
                  font-weight: bold;
                  background: $key_color_light2;
                  color: $key-color;
                  font-weight: bold;
                  padding: 0.4em 0;
                  border-radius: 300px;
                  border: 1px solid $key-color;
                }

              }
            }
            .pay-box  {
              &::before {
                @include media(sp) {
                  content:"－料金－";
                  background: $bg_color;
                  font-weight: bold;
                  width: 100%;
                  display: block;
                  padding: 0.2em 0;
                  text-align: center;
                  margin-bottom: 20px;
                }
              }
              >p {
                margin-bottom: 20px;
                &.price {
                  position: relative;
                  @include media(sp) {
                    padding-left: 4em;
                  }
                  &::before {
                    content:"■料金:";
                    width: 100%;
                    display: block;
                    font-weight: bold;
                    @include media(sp) {
                      position: absolute;
                      top:0;
                      left: 0;
                    }
                  }
                }
                &.max {
                  position: relative;
                  @include media(sp) {
                    padding-left: 7em;
                  }
                  &::before {
                    content: "■購入可能数:";
                    width: 100%;
                    display: block;
                    font-weight: bold;
                    @include media(sp) {
                      position: absolute;
                      top:0;
                      left:0;
                    }
                  }
                }

                &.bikou {
                  font-weight: normal;
                  position: relative;
                  @include media(sp) {
                    padding-left: 4em;;
                  }
                  &::before {
                    content:"■備考:";
                    width: 100%;
                    display: block;
                    font-weight: bold;
                    @include media(sp) {
                      position: absolute;
                      top:0;
                      left:0;
                    }
                  }
                }
              }

            }
            .contact-box {
              border-right: none;
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              @include media(sp) {
                border-bottom: 1px solid $line_gray ;
                padding-bottom: 20px;
              }
              &::before {
                @include media(sp) {
                  content:"－お申込み方法－";
                  background: $bg_color;
                  font-weight: bold;
                  width: 100%;
                  display: block;
                  padding: 0.2em 0;
                  text-align: center;
                  margin-bottom: 20px;
                }
              }
              .button-tel,
              .button-mail {
                width: 100%;
                margin-top: 20px;
                @include media(sp) {
                  width: 95%;
                  margin: 0 auto 40px;
                }
              }
              a {
                display: block;
                text-align: center;
                width: 100%;
                padding: 1em 0;
                font-size: 18px;
                font-weight: bold;
                border-radius: 300px;
                background-color: $key_color_light2;
                color: $key-color;
              }
              p {
                font-size: 0.8em;
                text-align: center;
                &.contact-term {
                  font-size: 1em;
                  margin-bottom: 20px;
                  position: relative;
                  text-align: left;
                  width: 100%;
                  @include media(sp) {
                    padding-left: 6em;
                  }
                  &::before {
                    content:"■受付期間:";
                    display: block;
                    font-weight: bold;
                    @include media(sp) {
                      position: absolute;
                      top:0;
                      left: 0;  
                    }
                  }
                }
                &.delivery-date {
                  font-size: 1em;
                  margin-bottom: 20px;
                  position: relative;
                  text-align: left;
                  width: 100%;
                  @include media(sp) {
                    padding-left: 6em;
                  }
                  &::before {
                    content:"■引換期間:";
                    font-weight: bold;
                    display: block;
                    @include media(sp) {
                      position: absolute;
                      top:0;
                      left: 0;
                    }
                  }
                } 
  
              }
              .button-tel a {
                background-image: url(../img/icon/icon-tel.png);
                background-position: left 15px center;
                background-repeat: no-repeat;
                background-size: 26px;
                @include media(w-1000) {
                  background-size: 15px;
                  background-position: center top 5px;
                }
                @include media(sp) {
                  background-size: 26px;
                  background-position: left 15px center;
                }
              }
              .button-tel p {
                &::before {
                  content: "受付時間:";
                }
              }

              .button-mail a {
                background-image: url(../img/icon/icon-mail.png);
                background-position: left 15px center;
                background-repeat: no-repeat;
                @include media(w-1000) {
                  background-size: 15px;
                  background-position: center top 5px;
                }
                @include media(sp) {
                  background-size: 26px;
                  background-position: left 15px center;
                }
              }
              .contact-message-box {
                margin-top: 20px;
                p {
                  text-align: left;
                  font-size: 1em;
                  b {}
                }
                .button-area {
                  width: 100%;
                }

              }
            }

          }
        }
      }
    }
    .no3 {
      margin-bottom: 100px;
      h3 {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 50px;
        text-align: center;
      }
      .inner {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px;
      }
    }
  }

/**▲▲▲▲▲page-06▲▲▲▲▲**/

/**▼▼▼▼▼#page-gaiyou▼▼▼▼▼**/
  #layout-gaiyou {
    .mv-area {
      margin-bottom: 60px;
      @include media(tab) {
        margin-bottom: 50px;
      }
    }
    .no1 {
      margin-bottom: 100px;
      @include media(tab) {
        margin-bottom: 80px;
      }
      .inner {
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;
        @include media(tab){
          width: 96%;
        }
        .sub-top {
          display: flex;
          justify-content: space-between;
          @include media(sp) {
            flex-direction: column-reverse;
          }
          .text-box {
            width: 48%;
            @include media(sp) {
              width: 100%;
            }
            h3 {
              margin-bottom: 20px;
              font-weight: bold;
              font-size: 18px;
            }
            p {
              margin-bottom: 40px;
            }
            a {
              display: flex;
              .arrow-cirlue {
                border: 1px solid $key_color_light2;
                margin-left: 10px;
              }
              &:hover {
                color: $key-color;
                .arrow-cirlue {
                  border: 1px solid $key_color_light;
                  background-color: $key-color;
                  path {
                    fill: #ffffff;
                  }
                } 
              }
            }
          }
          .photo-box {
            width: 48%;
            @include media(sp) {
              width: 100%;
            }

            figure {
              border-radius: 50px;
              @include media(sp) {
                margin-bottom: 20px;
              }
              /*暫定*/
              text-align: center;
              background: $c_gray;
              height: 300px;

            }
          }
        }
      }
    }

    .no2 {
      .inner {
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;

        h3 {
          margin-bottom: 50px;
        }
        ul {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          @include media(tab){
            width: 96%;
            margin: 0 auto;
          }
          li {
            width: 48%;
            margin-bottom: 50px;
            border-radius: 30px;
            background: $bg_color;
            border: 1px solid $bg_color;
            @include media(sp) {
              width: 100%;
            }
            a {
              display: block;
              padding: 20px 20px 10px;
              height: 100%;
              @include media(sp) {
                padding: 10px;
              }
              h4 {
                text-align: center;
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 10px;
                color: $key-color;
                span {
                  &::before {
                    content: "◇";
                    margin-right: 0.5em;
                  }
                  &::after {
                    content: "◇";
                    margin-left: 0.5em;
                  }
                }
              }
              .text-box {
                background: #ffffff;
                border-radius: 20px;
                min-height: 145px;
                width: 100%;
                margin: 0 auto 20px;
                >p {
                  padding: 1em;
                }
              }
              >p {
                display: flex;
                text-align: center;
                justify-content: center;
                font-weight: bold;
                .arrow-cirlue {
                  margin-left: 10px;
                }
              }
            }

            &:hover {
              background: $key_color_light2;
              border: 1px solid $key-color;
              a {
                >p {
                  color:$key-color;
                  .arrow-cirlue {
                    background: $key-color;
                    path {
                      fill:#ffffff;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
/**▲▲▲▲▲#page-gaiyou▲▲▲▲▲**/


/**▼▼▼▼▼#page-news-list▼▼▼▼▼**/

  #layout-news-list {
    .news-area {
      .arrow-cirlue {
        border: 1px solid $c_text_gray;
      }
    }

    .old-news-area {
      background-color: $bg_color;
      margin: 0 0 50px;
      padding: 50px 0;
      .inner {
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;
        background: #ffffff;
        padding: 30px 50px 50px;
        border-radius: 50px;
        h3 {
          text-align: center;
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 30px;
        }
        ul {
          display: flex;
          font-size: 1.2em;
          justify-content: center;
          li {
            margin-right: 20px;

            a {
              &::after {
                content: ">";
                margin-left: 0.5em;
              }

            }
          }
        }
      }
    }
  }


/**▲▲▲▲▲#page-news-list▲▲▲▲▲**/

/**▼▼▼▼▼page-contact▼▼▼▼▼**/
#layout-contact-page {
  .mv-area {
    margin-bottom: 50px;
  }
  .must {
    font-weight: bold;
    color: $key-color;
  }

  .inner {
    width: 100%;
    max-width: 860px;
    margin: 0 auto 100px;
    position: relative;
    @include media(tab) {
      width: 96%;
    }
  }
  .introduction-word {
    text-align: center;
    margin-bottom: 20px;
  }
  .contact-form-box {
    border-radius: 50px;
    padding: 30px;
    background: $bg_color;
    @include media(sp) {
      padding: 30px 10px;
    }
    .form-item {
      margin-bottom: 20px;
      br { display: none;}

      .title-word {
        font-weight: bold;
      }
      input {
        width: 100%;
        border-radius: 300px;
      }
      .sub-top {
        margin-bottom: 10px;
        p {
          display: flex;
          @include media(sp) {
            flex-direction: column;
          }
          br {
            display: none;
          }
        }
        input {
          width: inherit;
        }
      }
    }
    .type-checkbox {
      br {
        display: block;
      }
      label {
        display: flex;
        margin-right: 20px;
        input {
          width: 1em;
          margin-right: 0.5em;
        }
      }
    }
    .form-btn {
      p {
        display: flex;
        flex-direction: column;
      }
      input {
        width: 100%;
        max-width: 300px;
        padding: 0.5em;
        text-align: center;
        border-radius: 300px;
        background: #ffffff;
        border: 2px solid $key-color;
        color: $key-color;
        font-weight: bold;
        font-size: 1.1em;
        margin: 0 auto 20px;
      }
      .wpcf7-spinner {
        margin: 0 auto 10px;
      }
    }
  }
}

/**▲▲▲▲▲#page-contact▲▲▲▲▲**/

/**▼▼▼▼▼#search-list▼▼▼▼▼**/

#layout-search-list {
  .mv-area {
    margin-bottom: 60px;
  }

  .inner {
    width: 96%;
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 50px;
  }

  .search-result {
    li {
      padding: 10px 0;
      border-bottom: 1px solid $line_gray;
      a {
        display: flex;
        position: relative;
        padding: 0 40px 0 170px ;
        @include media(sp) {
          padding: 2em 40px 0 0; 
        }

        .date {
          display: flex;
          position:  absolute;
          top: 0;
          left: 0;
          font-size: 0.8em;
          @include media(sp) {
            font-size: 1em;
          }
          &::after {
            display: block;
            padding: 0.05em 1em;
            text-align: center;
            width: 6em;
            color: #ffffff;
            border-radius: 30px;
            margin-left: 10px;
          }
        }
        .arrow-cirlue {
          position: absolute;
          top: 0;
          right: 0;
          @include media(sp) {
            width: 20px;
            height: 20px;
            top: 50%;
            margin-top: -10px;
          }
        }
      }
      .arrow-cirlue {
        border: 1px solid $c_text_gray;
      }
    }

    li.news-service {
      a {
        .date {
          &::after {
            content: "サービス";
            background: #FCBD5F;
          }
        }
      }
    }

    li.news-event {
      a {
        .date {
          &::after {
            content: "イベント";
            background: #F8764B;
          }
        }
      }
    }

    li.news-info {
      a {
        .date {
          &::after {
            content: "お知らせ";
            background: #F28BB0;
          }
        }
      }
    }


  }



}



/**▲▲▲▲▲#search-list▲▲▲▲▲**/

}





////////////////////
/*dl-list　ページ専用*/

.dl-list .l-main #layout-list .no1 .inner ul li .head-word {
  font-size: 21px;
  max-width: 360px;
}










